import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import { TransferToContainerProps } from './TransferToContainer.types';
import { AddPayoutAccountButton, ACHMaintenanceNotice } from '../../components';
import {
  additionalPayoutProviderSupportsCurrency,
  alternatePayoutAccountSupportsCurrency,
  payoutAccountSupportsCurrency,
  achProviderMaintenance,
} from '../../utils/payoutAccountHelpers';
import { ConfigContext } from '../../contexts';
import { useAppSelector } from '../../store/hooks';
import { selectors } from '../../store/store';
import styles from './transfer-to-container.module.scss';

const TransferToContainer: React.FunctionComponent<
  TransferToContainerProps
> = ({
  currency,
  goToChangeAccountStep,
  approveClaims,
  approveClaimsError,
  processingRequest,
}) => {
  const { t } = useTranslation();
  const [renderDefault, setRenderDefault] = useState(false);
  const [renderChoose, setRenderChoose] = useState(false);
  const [renderAdd, setRenderAdd] = useState(false);

  const configEnvelope = useContext(ConfigContext);

  const appState = useAppSelector((state) => state);
  const {
    payoutAccount: { payoutAccounts },
  } = appState;

  const defaultAccount = selectors.defaultPayoutAccount(appState);
  const achMaintenance = configEnvelope.config?.dwollaDisabled;

  useEffect(() => {
    if (defaultAccount) {
      const render = payoutAccountSupportsCurrency(defaultAccount, currency);

      setRenderDefault(render);
    }
  }, [currency, payoutAccounts, defaultAccount]);

  useEffect(() => {
    const render = alternatePayoutAccountSupportsCurrency(
      payoutAccounts,
      currency,
    );

    setRenderChoose(render);
  }, [currency, payoutAccounts]);

  useEffect(() => {
    const render = additionalPayoutProviderSupportsCurrency(
      payoutAccounts,
      currency,
    );

    setRenderAdd(render);
  }, [currency, payoutAccounts]);

  const useDefaultAccountButton = (
    <AddPayoutAccountButton
      providerLabel
      provider={defaultAccount?.providerName}
      displayName={defaultAccount?.displayName}
      clickHandler={approveClaims}
      colorVariant="ocean"
      error={approveClaimsError}
      disabled={processingRequest}
    />
  );

  const chooseAnotherAccountButton = (
    <AddPayoutAccountButton
      chooseAnotherAccountLabel
      clickHandler={goToChangeAccountStep}
      colorVariant="ocean"
      disabled={processingRequest}
    />
  );

  const addPayoutAccountButton = (
    <AddPayoutAccountButton
      addNewAccountLabel
      clickHandler={goToChangeAccountStep}
      colorVariant="ocean"
      disabled={processingRequest}
    />
  );

  const defaultAccountDoesNotSupportCurrency = defaultAccount && !renderDefault;
  //TODO: refactor to determine the maintenance status of any default account
  const defaultACHAccountMaintenance = achProviderMaintenance(
    defaultAccount,
    achMaintenance,
  );

  return (
    <div>
      <Typography variant="h5">
        {t('sunshine.content.reimbursement_balance.transfer_to')}
      </Typography>
      {achMaintenance ? (
        <ACHMaintenanceNotice />
      ) : (
        defaultAccountDoesNotSupportCurrency && (
          <Typography
            variant="body-2"
            className={styles.defaultNotSupportedText}
          >
            {t('sunshine.label.default_account_currency_warning', {
              currency,
            })}
          </Typography>
        )
      )}

      {renderDefault &&
        !defaultACHAccountMaintenance &&
        useDefaultAccountButton}

      {renderChoose && chooseAnotherAccountButton}

      {!renderChoose && renderAdd && addPayoutAccountButton}
      <div></div>
    </div>
  );
};

export default TransferToContainer;
