import { FC } from 'react';
import { TextButtonProps } from './TextButton.types';
import styles from './text-button.module.scss';

const TextButton: FC<TextButtonProps> = ({
  clickHandling,
  disabled = false,
  label,
  inline = false,
}) => {
  const isURL = typeof clickHandling === 'object' && clickHandling.url;
  const isClickHandler = typeof clickHandling === 'function';
  const formattedLabel = inline ? label : `${label} >`;
  return isURL ? (
    <a
      className={`${styles.button} ${inline ? styles.inline : styles.caret}`}
      href={clickHandling.url}
      target={clickHandling.newTab ? '_blank' : undefined}
      rel="noopener noreferrer"
    >
      {formattedLabel}
    </a>
  ) : (
    <button
      className={`${styles.button} ${inline ? styles.inline : styles.caret}`}
      disabled={disabled}
      onClick={isClickHandler ? clickHandling : undefined}
      type="button"
    >
      {formattedLabel}
    </button>
  );
};

export default TextButton;
