import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPlusSign = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.9799 7.98352H0.0615234V10.0652H17.9799V7.98352Z" />
    <path d="M7.97949 0.0663757L7.97949 17.9847L10.0611 17.9847L10.0611 0.0663756L7.97949 0.0663757Z" />
  </svg>
);

export default SvgPlusSign;
