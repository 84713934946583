import { onCLS, onFID, onFCP, onLCP, onTTFB } from 'web-vitals';

const reportWebVitals = () => {
  onCLS(console.info);
  onFID(console.info);
  onLCP(console.info);
  onFCP(console.info);
  onTTFB(console.info);
};

export default reportWebVitals;
