// ~~~ from utils ~~~
import {
  CaseTransformer,
  ObjectKeyTransformer,
  TransformableObject,
} from './format.types';

const toLowerCase = (str: string): string => {
  return str.trim().toLowerCase();
};

const toUpperCase = (str: string): string => {
  return str.trim().toUpperCase();
};

const toSentenceCase = (str: string): string => {
  const lc = str.trim().toLowerCase();

  const sentences = lc.split('. ');

  const formatted = sentences.map((s) => {
    return s
      .split('')
      .map((c, i) => {
        return i === 0 ? c.toUpperCase() : c;
      })
      .join('');
  });
  return formatted.join('. ');
};

// file deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
const toSentenceCaseAndPunctuation = (str: string): string => {
  const finalFormat = toSentenceCase(str);
  switch (finalFormat.slice(-1)) {
    case '!':
      return finalFormat;
    case '?':
      return finalFormat;
    case '.':
      return finalFormat;
    case `"`:
      return finalFormat;
    case `'`:
      return finalFormat;
    default:
      return finalFormat + '.';
  }
};

const toTitleCase = (str: string): string => {
  const lc = str.trim().toLowerCase();

  const tc = lc.split(' ').map((w) => {
    const formattedWord = w.split('').map((c, i) => {
      return i === 0 ? c.toUpperCase() : c;
    });

    return formattedWord.join('');
  });

  return tc.join(' ');
};

const fromKebabCase = (str: string): string => {
  return str.trim().split('-').join(' ');
};

const fromSnakeCase = (str: string): string => {
  return str.trim().split('_').join(' ');
};

const camelToSnakeCase = (key: string): string => {
  const a = key.split('');

  return a.reduce((acc, cur, index) => {
    if (cur.match(/[A-Z]/)) {
      const prev = key.charAt(index - 1);
      if (prev.match(/[A-Z]/)) {
        acc += cur.toLowerCase();
      } else {
        acc += '_' + cur.toLowerCase();
      }
    } else if (cur.match(/\d/)) {
      const prev = key.charAt(index - 1);
      if (prev.match(/\d/)) {
        acc += cur;
      } else {
        acc += '_' + cur;
      }
    } else {
      acc += cur;
    }
    return acc;
  }, '');
};

const snakeToCamelCase = (key: string): string => {
  const a = key.split('_');

  const t = a.map((w, i) => {
    if (i === 0) return w;

    if (w === 'id') {
      return w.toUpperCase();
    }

    return w.charAt(0).toUpperCase() + w.slice(1);
  });

  return t.join('');
};

const makeTransformer = (
  caseTransformer: CaseTransformer,
): ObjectKeyTransformer => {
  const transform = (data?: unknown): unknown => {
    if (!data) return data;

    // if data is of type array
    // iterate over array and transform each value
    if (Array.isArray(data)) {
      return data.map(transform);
    }

    // if data is of type object
    // apply format to key and transform value
    if (typeof data === 'object') {
      return Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          caseTransformer(key),
          transform(value),
        ]),
      );
    }

    return data;
  };

  return transform;
};

const snakeToCamelKey = <V>(
  obj: TransformableObject<V>,
): TransformableObject<V> =>
  makeTransformer(snakeToCamelCase)(obj) as TransformableObject<V>;

const camelToSnakeKey = <V>(
  obj: TransformableObject<V>,
): TransformableObject<V> =>
  makeTransformer(camelToSnakeCase)(obj) as TransformableObject<V>;

export default {
  toLowerCase,
  toUpperCase,
  toSentenceCase,
  toSentenceCaseAndPunctuation,
  toTitleCase,
  fromKebabCase,
  fromSnakeCase,
  camelToSnakeCase,
  snakeToCamelCase,
  snakeToCamelKey,
  camelToSnakeKey,
};
