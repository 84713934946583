import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import { PayoutProvider, type PayoutAccount } from '../../utils/types/Entity';
import { useEffect, useState } from 'react';
import {
  AddPayoutAccountButton,
  AddPayoutProviderAccountComponent,
} from '../../components';
import { useActions, useAppSelector } from '../../store/hooks';
import { AddPayoutAccountContainerProps } from './AddPayoutAccountContainer.types';
import styles from './add-payout-account-container.module.scss';
import { payoutProviderSupportsCurrency } from '../../utils/payoutAccountHelpers';

const AddPayoutAccountContainer: React.FC<AddPayoutAccountContainerProps> = ({
  currency,
  onAccountCreationSuccess,
  processingRequest,
}): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [providerModule, setProviderModule] = useState<PayoutProvider | null>(
    null,
  );
  const [availableProviders, setAvailableProviders] = useState<
    PayoutProvider[]
  >([]);

  const dispatch = useActions();
  const { payoutAccounts } = useAppSelector((state) => state.payoutAccount);

  useEffect(() => {
    if (loading) {
      const providers = Object.values(PayoutProvider);

      const filteredProviders = providers.filter((provider) => {
        const hasPayoutAccountForProvider = payoutAccounts.some((pa) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          return pa.providerName === provider;
        });

        const providerDoesNotSupportCurrency =
          currency && !payoutProviderSupportsCurrency(provider, currency);

        if (hasPayoutAccountForProvider || providerDoesNotSupportCurrency) {
          return false;
        }

        return true;
      });

      setAvailableProviders(filteredProviders);
      setLoading(false);
    }
  }, [loading, currency, payoutAccounts]);

  if (loading) {
    return <></>;
  }

  const onAccountCreationSuccessWrapper = (
    payoutAccount: PayoutAccount,
  ): void => {
    dispatch.addPayoutAccount(payoutAccount);

    setProviderModule(null);

    if (onAccountCreationSuccess) {
      onAccountCreationSuccess(payoutAccount);
    }
  };

  return (
    <div className={styles.container}>
      <Typography variant="h5">{t('label.add_new_account')}</Typography>

      {!availableProviders.length && (
        <Typography
          variant="body-2"
          className={styles.noProvidersAvailableText}
        >
          {t('sunshine.label.no_providers_available')}.
        </Typography>
      )}

      <div className={styles.contentWrapper}>
        <div
          className={`${styles.providerButtonList} ${
            !providerModule ? styles.visibleLeft : styles.hiddenLeft
          }`}
        >
          {availableProviders.map((av, i) => {
            return (
              <AddPayoutAccountButton
                key={`${i}_${av}`}
                providerLabel
                provider={av}
                clickHandler={(): void => setProviderModule(av)}
                colorVariant="snow"
                disabled={processingRequest}
              />
            );
          })}
        </div>

        <div
          className={`${styles.providerModule} ${
            providerModule ? styles.visibleRight : styles.hiddenRight
          }`}
        >
          {providerModule ? (
            <AddPayoutProviderAccountComponent
              provider={providerModule}
              onBackClick={(): void => setProviderModule(null)}
              onAccountCreationSuccess={onAccountCreationSuccessWrapper}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddPayoutAccountContainer;
