import { GraphQLClient, gql } from 'graphql-request';

type AppString = {
  key: string;
  value: string;
};

type AppStrings = {
  appStrings: AppString[];
};

enum SupportedLanguages {
  en = 'en',
  fr = 'fr',
  sv = 'sv',
}

type Authorization = {
  username: string;
  password: string;
};
export class HygraphClient {
  private graphqlClient: GraphQLClient;

  constructor(
    // @ts-expect-error ts constructor variable
    private url: string,
    private stage: string,
    // @ts-expect-error ts constructor variable
    private authorization?: Authorization,
  ) {
    const headers: Record<string, string> = {};
    if (authorization?.username) {
      const hash = btoa(`${authorization.username}:${authorization.password}`);
      headers.authorization = `Basic ${hash}`;
    }
    this.graphqlClient = new GraphQLClient(url, { headers });
  }

  async queryRecords(queryFilter: string, offset = 0): Promise<AppString[]> {
    const start = offset;
    const limit = 100;

    const query = gql`
    {
      appStrings(stage: ${this.stage}, ${queryFilter}, first: ${limit}, skip: ${start}) {
        key
        value
      }
    }
  `;

    const queryResult: AppStrings = await this.graphqlClient.request(query);

    const records = queryResult.appStrings;

    // check length of results - if we have max records,
    // recurse, and call function again with offsets
    if (queryResult.appStrings.length === limit) {
      const nextStart = start + limit;
      records.push(...(await this.queryRecords(queryFilter, nextStart)));
    }

    return records;
  }

  async fetchStringsForLocale(locale: string): Promise<Record<string, string>> {
    if (!(locale in SupportedLanguages)) return {};
    try {
      const queryResults = await this.queryRecords(`locales: ${locale}`);
      const translationMapForLocale = queryResults.reduce(
        (translations, { key, value }) => {
          translations[key] = value;
          return translations;
        },
        {} as Record<string, string>,
      );
      return translationMapForLocale;
    } catch (e) {
      // If error occurs, gracefully fail with empty language map
      // eslint-disable-next-line no-console
      console.error('Hygraph i18next error: ', e);
      return {};
    }
  }
}
