//used in client
import { numberValidator } from '../../validate/Number';
import { objectValidator } from '../../validate/Object';
import { stringValidator } from '../../validate/String';
import { CurrencyUnit, isCurrencyUnit } from '../Currency/Currency.types';
import { PayoutProvider, isPayoutProvider } from './PayoutProvider.types';

export interface Payout {
  id: string;
  payoutAccountID?: string;
  provider?: PayoutProvider;
  transferID: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  canceledAt?: string;
  completedAt?: string;
  failedAt?: string;
  currency: CurrencyUnit;
}

export enum PayoutStatus {
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
  Canceled = 'canceled',
}

export const getPayoutStatus = (payout: Payout): PayoutStatus => {
  if (payout.completedAt) {
    return PayoutStatus.Completed;
  }

  if (payout.failedAt) {
    return PayoutStatus.Failed;
  }

  if (payout.canceledAt) {
    return PayoutStatus.Canceled;
  }

  return PayoutStatus.Pending;
};

export function isPayout(value: unknown): asserts value is Payout {
  objectValidator(value, 'payout');

  const {
    id,
    payoutAccountID,
    transferID,
    amount,
    createdAt,
    updatedAt,
    canceledAt,
    completedAt,
    failedAt,
    provider,
    currency,
  } = value;

  const requiredStrings = [
    { name: 'payout.id', value: id },
    { name: 'payout.transferID', value: transferID },
    { name: 'payout.createdAt', value: createdAt },
    { name: 'payout.updatedAt', value: updatedAt },
  ];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const optionalStrings = [
    { name: 'payout.payoutAccountID', value: payoutAccountID },
    { name: 'payout.completedAt', value: completedAt },
    { name: 'payout.canceledAt', value: canceledAt },
    { name: 'payout.failedAt', value: failedAt },
  ];

  optionalStrings.forEach((str) => {
    if (str.value !== undefined) {
      stringValidator(str.value, str.name);
    }
  });

  const requiredNumbers = [{ name: 'payout.amount', value: amount }];

  requiredNumbers.forEach((num) => {
    numberValidator(num.value, num.name);
  });

  isCurrencyUnit(currency);

  if (provider !== undefined) {
    isPayoutProvider(provider);
  }
}
