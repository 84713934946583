import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PayoutProviderLogo from '../../sharedComponents/PayoutProviderLogo/PayoutProviderLogo';
import Typography from '../../sharedComponents/Typography/Typography';
import { AddPayoutAccountButtonProps } from './AddPayoutAccountButton.types';
import styles from './add-payout-account-button.module.scss';

const AddPayoutAccountButton: FC<AddPayoutAccountButtonProps> = ({
  chooseAnotherAccountLabel,
  addNewAccountLabel,
  providerLabel,
  provider,
  colorVariant,
  displayName,
  clickHandler,
  error = '',
  disabled,
}) => {
  const { t } = useTranslation();
  const TEXT_COLOR = colorVariant === 'ocean' ? 'snow' : 'ocean';
  const CHOOSE_ANOTHER_ACCOUNT_LABEL = chooseAnotherAccountLabel && (
    <Typography variant="h5" color={TEXT_COLOR}>
      {t('sunshine.action.choose_another_account')}
    </Typography>
  );
  const ADD_NEW_ACCOUNT_LABEL = addNewAccountLabel && (
    <Typography variant="h5" color={TEXT_COLOR}>
      {t('sunshine.action.add_payout_account')}
    </Typography>
  );
  const PROVIDER_LABEL = providerLabel && provider && (
    <PayoutProviderLogo
      payoutAccountProvider={provider}
      darkBackground={colorVariant === 'ocean'}
      achTitleText={t('label.bank_account')}
    />
  );

  if (
    !CHOOSE_ANOTHER_ACCOUNT_LABEL &&
    !ADD_NEW_ACCOUNT_LABEL &&
    !PROVIDER_LABEL
  ) {
    return <></>;
  }

  return (
    <div>
      <button
        className={`${styles.btn} ${styles[colorVariant]}`}
        onClick={clickHandler}
        disabled={disabled}
      >
        <div className={styles.buttonLabel}>
          {CHOOSE_ANOTHER_ACCOUNT_LABEL ||
            ADD_NEW_ACCOUNT_LABEL ||
            PROVIDER_LABEL}
          {PROVIDER_LABEL && (
            <Typography
              className={styles.displayName}
              variant="label-3"
              color={TEXT_COLOR}
            >
              {displayName}
            </Typography>
          )}
        </div>
      </button>
      <Typography variant="error">{error}</Typography>
    </div>
  );
};

export default AddPayoutAccountButton;
