import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCheckMark = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={14.5} cy={14.5} r={14.5} fill="white" />
    <path
      d="M23.5 9.97222L21.5556 8L12 17.5833L7.94444 13.5278L6 15.5L10.0278 19.5278L12 21.5L13.9444 19.5278L23.5 9.97222Z"
      fill="#019E8B"
    />
  </svg>
);

export default SvgCheckMark;
