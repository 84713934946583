import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { type User } from '../../utils/types/Entity';

interface UserState {
  user: User;
}

const userInitialState: UserState = {
  user: {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: '',
    authID: '',
    email: '',
    phone: '',
    locale: '',
    langLocale: '',
    addresses: [],
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    clearUser: () => userInitialState,
  },
});

export const userActions = userSlice.actions;

export const userReducer = userSlice.reducer;
