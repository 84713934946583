import {
  StatusCode,
  statusTextFromCode,
} from '../types/StatusCode/StatusCode.types';

export class ValidationError extends Error {
  errors: string;
  value: unknown;
  location: string | undefined;
  isValidationError: boolean | undefined;

  constructor(
    value: unknown,
    valueName: string,
    errors: string[],
    location?: string,
  ) {
    const message = `${valueName} failed validation`;
    super(message);

    this.name = 'Validation Error';

    this.value = this.prunePII(value);
    this.errors = errors.join('\n');
    this.location = location;
  }

  PII_FIELDS = ['email', 'name', 'phone', 'addresses'];
  prunePII<T>(input: T): T {
    if (typeof input !== 'object' || input === null) {
      return input;
    }
    const inputObject = input as Record<string, unknown>;
    return Object.keys(inputObject).reduce<Record<string, unknown>>(
      (acc, cur) => {
        acc[cur] = this.PII_FIELDS.includes(cur)
          ? '--REDACTED--'
          : this.prunePII(inputObject[cur]);
        return acc;
      },
      {},
    ) as T;
  }

  statusCode = (): StatusCode => {
    return StatusCode.UnprocessableEntity;
  };

  statusText = (): string => {
    return statusTextFromCode(this.statusCode());
  };
}

export function isValidationError(value: unknown): value is ValidationError {
  if (!(value instanceof Error)) return false;
  if (value.name !== 'Validation Error') return false;

  return true;
}
