import { useTranslation } from 'react-i18next';
import TextButton from '../../sharedComponents/TextButton/TextButton';
import { BlankCanvasBlock } from '../../sharedComponents/BlankCanvasBlock/BlankCanvasBlock';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import Typography from '../../sharedComponents/Typography/Typography';
import {
  ReimbursementBalanceComponent,
  ClaimStatusWrapperComponent,
  AddPayoutAccountButton,
} from '../../components';
import TransferToContainer from '../TransferToContainer/TransferToContainer';
import { getBalanceDueFromClaims } from '../../utils';
import { OpenClaimsProps } from './OpenClaims.types';
import AddPayoutAccountContainer from '../AddPayoutAccountContainer/AddPayoutAccountContainer';
import { useActions, useAppSelector } from '../../store/hooks';
import { ClaimAPI, PayoutAccountAPI, PolicyBundleAPI } from '../../api';
import { payoutAccountSupportsCurrency } from '../../utils/payoutAccountHelpers';
import styles from './open-claims.module.scss';

const OpenClaims: React.FC<OpenClaimsProps> = ({
  policyBundle,
  openClaims,
  currency,
}): JSX.Element => {
  const { t } = useTranslation();
  const TRANSFER_STEP = 'transfer' as const;
  const CHANGE_ACCOUNT_STEP = 'change_account' as const;
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [fetchPayoutAccountsError, setFetchPayoutAccountsError] = useState('');
  const [changDefaultError, setChangDefaultError] = useState('');
  const [processingRequest, setProcessingRequest] = useState(false);
  const [approveClaimsError, setApproveClaimsError] = useState('');
  const [step, setStep] = useState<
    typeof TRANSFER_STEP | typeof CHANGE_ACCOUNT_STEP
  >(TRANSFER_STEP);

  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useActions();

  const { payoutAccounts, loadedPayoutAccounts } = useAppSelector(
    (state) => state.payoutAccount,
  );

  useEffect(() => {
    setFetchPayoutAccountsError('');

    const fetchPayoutAccounts = async (): Promise<void> => {
      const accessToken = await getAccessTokenSilently();
      try {
        // eslint-disable-next-line prettier/prettier
        const fetchedAccounts =
          await PayoutAccountAPI.getPayoutAccounts(accessToken);
        dispatch.setPayoutAccounts(fetchedAccounts);
      } catch (e) {
        setFetchPayoutAccountsError('error.request_accounts');
      } finally {
        dispatch.setLoadedPayoutAccounts();
      }
    };
    if (!loadedPayoutAccounts) {
      void fetchPayoutAccounts();
    }
  }, [payoutAccounts, loadedPayoutAccounts, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    const _balance = getBalanceDueFromClaims(openClaims);

    setBalance(_balance);

    setLoading(false);
  }, [openClaims]);

  const selectDefault = async (payoutAccountID: string): Promise<void> => {
    setProcessingRequest(true);
    setChangDefaultError('');

    try {
      const token = await getAccessTokenSilently();

      await PayoutAccountAPI.changeDefaultStatus(token, payoutAccountID, true);

      const payoutAccount = payoutAccounts.find(
        (account) => account.id === payoutAccountID,
      );

      if (!payoutAccount) {
        throw new Error('');
      }

      dispatch.updateDefaultAccountStatus({
        payoutAccount,
        isDefault: true,
      });
    } catch (e) {
      setChangDefaultError(t('error.select_account'));
    } finally {
      setProcessingRequest(false);
    }
  };

  const approveClaims = async (): Promise<void> => {
    setApproveClaimsError('');
    setProcessingRequest(true);

    try {
      const accessToken = await getAccessTokenSilently();

      await ClaimAPI.approveClaims(accessToken, openClaims);

      // TODO: #1460
      // eslint-disable-next-line prettier/prettier
      const updatedPolicyBundles =
        await PolicyBundleAPI.getPolicyBundles(accessToken);
      dispatch.setPolicyBundles(updatedPolicyBundles);
    } catch (e) {
      setApproveClaimsError(t('error.process_payout'));
    } finally {
      setProcessingRequest(false);
    }
  };

  const BalanceTransferContainer = (
    <ClaimStatusWrapperComponent
      policyBundle={policyBundle}
      childrenReady={!loading && loadedPayoutAccounts}
    >
      <ReimbursementBalanceComponent balance={balance} currency={currency} />
      <TransferToContainer
        currency={currency}
        approveClaims={(): void => void approveClaims()}
        approveClaimsError={approveClaimsError}
        processingRequest={processingRequest}
        goToChangeAccountStep={(): void => setStep(CHANGE_ACCOUNT_STEP)}
      />
    </ClaimStatusWrapperComponent>
  );

  const filteredPayoutAccounts = payoutAccounts.filter((payoutAccount) => {
    const supportsCurrency = payoutAccountSupportsCurrency(
      payoutAccount,
      currency,
    );

    return supportsCurrency && !payoutAccount.isDefault;
  });

  const ChangeAccountContainer = (
    <BlankCanvasBlock colorVariant="snow" first last>
      <TextButton
        label={`< ${t('sunshine.action.back_to_reimbursement')}`}
        inline
        clickHandling={(): void => setStep(TRANSFER_STEP)}
      />

      <Typography variant="h4" className={styles.changeAccountContainerHeader}>
        {filteredPayoutAccounts.length === 0
          ? t('sunshine.content.select_account.header')
          : t('sunshine.content.choose_account.header')}
      </Typography>
      <Typography
        variant="body-1"
        className={styles.changeAccountContainerSubHeader}
      >
        {filteredPayoutAccounts.length === 0
          ? t('sunshine.content.select_account.explainer')
          : t('sunshine.content.choose_account.explainer')}
      </Typography>

      {filteredPayoutAccounts.map((payoutAccount, i) => {
        return (
          <AddPayoutAccountButton
            key={`${payoutAccount.providerName}_${i}`}
            providerLabel
            provider={payoutAccount.providerName}
            clickHandler={(): void => void selectDefault(payoutAccount.id)}
            colorVariant="snow"
            error={changDefaultError}
            disabled={processingRequest}
          />
        );
      })}

      <AddPayoutAccountContainer
        currency={currency}
        onAccountCreationSuccess={(): void => setStep(TRANSFER_STEP)}
        processingRequest={processingRequest}
      />
    </BlankCanvasBlock>
  );

  if (fetchPayoutAccountsError) {
    return (
      <Typography variant="body-1">{t(fetchPayoutAccountsError)}</Typography>
    );
  }

  return (
    <div>
      {step === TRANSFER_STEP && BalanceTransferContainer}
      {step === CHANGE_ACCOUNT_STEP && ChangeAccountContainer}
    </div>
  );
};

export default OpenClaims;
