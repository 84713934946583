import { User, isUser } from './User.types';
import { Exposure, isExposure } from './Exposure.types';
import {
  objectValidator,
  stringValidator,
  numberValidator,
  arrayValidator,
  booleanValidator,
} from '../../validate';
import sensibleDate from '../SensibleDate';
import { CurrencyUnit, isCurrencyUnit } from '../Currency/Currency.types';

export interface Policy {
  id: string;
  policyHolder: User;
  protectedParty: User;
  billTo: User;
  start: string;
  end: string;
  createdAt: string;
  updatedAt: string;
  exposures: Exposure[];
  coverageTotal?: number;
  isActive: boolean;
  slug: string;
  currency: CurrencyUnit;
}

export enum PolicyStatus {
  Upcoming = 'upcoming',
  Active = 'active',
  Completed = 'completed',
}

export const getPolicyStatus = (policy: Policy): PolicyStatus => {
  if (policy.isActive) {
    return PolicyStatus.Active;
  }

  const startDate = sensibleDate.parseRFC(policy.start);

  if (sensibleDate.isFuture(startDate)) {
    return PolicyStatus.Upcoming;
  }

  return PolicyStatus.Completed;
};

export function isPolicy(value: unknown): asserts value is Policy {
  objectValidator(value, 'policy');

  const {
    id,
    policyHolder,
    protectedParty,
    billTo,
    start,
    end,
    createdAt,
    updatedAt,
    exposures,
    coverageTotal,
    isActive,
    slug,
    currency,
  } = value;

  const requiredStrings = [
    { name: 'policy.id', value: id },
    { name: 'policy.start', value: start },
    { name: 'policy.end', value: end },
    { name: 'policy.createdAt', value: createdAt },
    { name: 'policy.updatedAt', value: updatedAt },
    { name: 'policy.slug', value: slug },
  ];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const optionalNumbers = [
    { name: 'policy.coverageTotal', value: coverageTotal },
  ];

  optionalNumbers.forEach((num) => {
    if (num.value !== undefined) {
      numberValidator(num.value, num.name);
    }
  });

  booleanValidator(isActive, 'policy.isActive');

  arrayValidator(exposures, 'policy.exposures', {
    typeguard: isExposure,
    nonEmpty: true,
  });

  isCurrencyUnit(currency);

  isUser(policyHolder);
  isUser(protectedParty);
  isUser(billTo);
}
