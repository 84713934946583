import { ValidationError } from '../error';

/**
 * @function numberValidator
 * @description Validates that a variable is present, is a number, and satisfies any optional constraints.
 * Does not check that constraints are strictly compatible - throws an error at the first failed validation encountered.
 */
export function numberValidator(
  /** The variable value to validate */
  value: unknown,
  /** The variable name for error message purposes */
  name: string,
  /** Optional parameters to validate against */
  opts?: {
    /** Pass min to require number being >= min value */
    min?: number;
    /** Error message to display if min triggers */
    minErrorMsg?: string;
    /** Pass max to require number being <= max value */
    max?: number;
    /** Error message to display if max triggers */
    maxErrorMsg?: string;
    /** Pass equalTo to require number being === value */
    equalTo?: number;
    /** Error message to display if equalTo triggers */
    equalToErrorMsg?: string;
    /** Pass nonZero to require number being !== 0 */
    nonZero?: boolean;
    /** Error message to display if nonZero triggers */
    nonZeroErrorMsg?: string;
    /** Pass true if NaN should be considered valid */
    allowNaN?: boolean;
    /** Pass errorMsg to supply a catch-all error message on failed validation */
    errorMsg?: string;
  },
): asserts value is number {
  // Variable must exist
  if (typeof value === 'undefined') {
    throw new ValidationError(value, name, ['value is undefined']);
  }

  // Variable must be a number
  if (typeof value !== 'number') {
    throw new ValidationError(value, name, [
      `value is not of type number, received type ${typeof value}`,
    ]);
  }

  if (!opts?.allowNaN && Number.isNaN(value)) {
    throw new ValidationError(value, name, [`value is equal to NaN`]);
  }

  // Handle nonZero
  if (opts?.nonZero && value === 0) {
    const nonZeroError = opts.nonZeroErrorMsg || 'value equals zero';
    throw new ValidationError(value, name, [nonZeroError]);
  }

  // Handle min
  if (opts?.min && value < opts.min) {
    const minError =
      opts.minErrorMsg || `value is less than minimum of ${opts.min}`;
    throw new ValidationError(value, name, [minError]);
  }

  // Handle max
  if (opts?.max && value > opts.max) {
    const maxError =
      opts.maxErrorMsg || `value is greater than maxium of ${opts.max}`;
    throw new ValidationError(value, name, [maxError]);
  }

  // Handle equalTo
  if (opts?.equalTo && value !== opts.equalTo) {
    const equalToError =
      opts.equalToErrorMsg || `value is not equal to ${opts.equalTo}`;
    throw new ValidationError(value, name, [equalToError]);
  }
}
