import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSpending = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 121 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M114.984 44.5356C115.168 45.199 115.772 45.6337 116.428 45.6337C116.563 45.6337 116.697 45.6153 116.834 45.5772C117.631 45.3544 118.098 44.5261 117.875 43.7279C117.458 42.2355 116.977 40.7446 116.445 39.2962C116.161 38.5177 115.305 38.1212 114.519 38.4034C113.741 38.6892 113.342 39.5512 113.628 40.3297C114.132 41.7041 114.588 43.1195 114.984 44.5356Z"
      fill="black"
    />
    <path
      d="M2.71957 47.9191C2.83538 47.947 2.9512 47.9594 3.06555 47.9594C3.74577 47.9594 4.36295 47.494 4.52568 46.8028C4.86286 45.3712 5.26015 43.9382 5.70581 42.5433C5.95943 41.7539 5.52403 40.9087 4.73385 40.6558C3.93782 40.4029 3.09926 40.8391 2.84711 41.6285C2.37653 43.0974 1.95872 44.6067 1.60248 46.1137C1.41337 46.9208 1.91327 47.7293 2.71957 47.9191Z"
      fill="black"
    />
    <path
      d="M3.71562 69.0066C3.58515 68.1885 2.82869 67.6263 1.99601 67.7612C1.17798 67.8917 0.620899 68.6621 0.751373 69.4801C0.996195 71.0165 1.30405 72.5514 1.66176 74.0437C1.82742 74.732 2.44314 75.1938 3.12043 75.1938C3.23624 75.1938 3.35499 75.1806 3.47373 75.152C4.27857 74.9585 4.77554 74.1478 4.58203 73.3415C4.24045 71.9246 3.94872 70.466 3.71562 69.0066Z"
      fill="black"
    />
    <path
      d="M4.53622 82.8926C5.12995 84.3256 5.78379 85.7469 6.48014 87.1176C6.74548 87.6388 7.27324 87.9386 7.82006 87.9386C8.04875 87.9386 8.28038 87.8865 8.49882 87.7758C9.23768 87.3998 9.53234 86.4968 9.15705 85.7572C8.49588 84.4561 7.8743 83.1052 7.30989 81.7433C6.99324 80.9773 6.1107 80.6174 5.34839 80.9311C4.58313 81.2485 4.21957 82.1266 4.53622 82.8926Z"
      fill="black"
    />
    <path
      d="M1.50265 61.7054C2.3324 61.7054 3.00383 61.0332 3.00383 60.2042L3.00237 59.9528C3.00237 58.4846 3.05954 56.9973 3.17095 55.5335C3.23399 54.7067 2.61534 53.9854 1.78852 53.9224C0.941171 53.8594 0.240424 54.478 0.177386 55.3048C0.0601059 56.8441 0 58.4083 0 59.9792L0.001466 60.2042C0.001466 61.0332 0.672893 61.7054 1.50265 61.7054Z"
      fill="black"
    />
    <path
      d="M109.954 32.3004C110.228 32.7945 110.74 33.073 111.267 33.073C111.514 33.073 111.764 33.0122 111.995 32.8839C112.72 32.4815 112.981 31.5682 112.578 30.8432C111.83 29.4967 111.018 28.1583 110.164 26.866C109.705 26.1748 108.774 25.9834 108.083 26.4423C107.391 26.8997 107.202 27.8306 107.66 28.5226C108.472 29.7503 109.243 31.0214 109.954 32.3004Z"
      fill="black"
    />
    <path
      d="M102.23 21.5509C102.527 21.8764 102.933 22.0413 103.34 22.0413C103.701 22.0413 104.063 21.9123 104.352 21.6499C104.965 21.0914 105.009 20.1421 104.45 19.5293C103.408 18.3859 102.31 17.2695 101.187 16.2118C100.584 15.6452 99.6342 15.6716 99.0639 16.277C98.4966 16.8803 98.5244 17.831 99.1284 18.399C100.197 19.404 101.241 20.4639 102.23 21.5509Z"
      fill="black"
    />
    <path
      d="M116.698 53.2033C116.869 54.6612 116.987 56.1441 117.047 57.6108C117.079 58.4186 117.745 59.0512 118.545 59.0512C118.566 59.0512 118.586 59.0504 118.607 59.0497C119.435 59.0167 120.08 58.3174 120.046 57.4892C119.985 55.9462 119.86 54.3856 119.68 52.8515C119.582 52.0276 118.835 51.4287 118.013 51.5365C117.189 51.634 116.601 52.3802 116.698 53.2033Z"
      fill="black"
    />
    <path
      d="M76.3408 5.26736C77.7511 5.68663 79.1585 6.16675 80.5233 6.69231C80.7007 6.76048 80.8825 6.79346 81.0628 6.79346C81.6653 6.79346 82.2341 6.4277 82.4643 5.83103C82.7619 5.05772 82.3763 4.18912 81.6023 3.89079C80.1641 3.33664 78.682 2.8316 77.197 2.3896C76.4009 2.15358 75.5668 2.6073 75.3307 3.40041C75.0933 4.19498 75.5462 5.0306 76.3408 5.26736Z"
      fill="black"
    />
    <path
      d="M7.03424 35.0277C7.2512 35.1362 7.48137 35.1875 7.70713 35.1875C8.25688 35.1875 8.7861 34.8848 9.04998 34.3607C9.70675 33.0537 10.4266 31.7505 11.1903 30.4875C11.6184 29.778 11.3897 28.8551 10.6802 28.4263C9.97063 27.9968 9.04852 28.2255 8.61898 28.935C7.81708 30.2647 7.05916 31.6361 6.36721 33.0119C5.99484 33.753 6.29391 34.6553 7.03424 35.0277Z"
      fill="black"
    />
    <path
      d="M88.5004 10.4996C89.7729 11.2333 91.0322 12.0265 92.2416 12.8562C92.5011 13.0343 92.7958 13.1194 93.089 13.1194C93.5669 13.1194 94.0375 12.8914 94.3278 12.467C94.7969 11.7838 94.6239 10.8493 93.9393 10.3801C92.6653 9.5064 91.3401 8.67152 89.9987 7.89894C89.2789 7.48259 88.3626 7.73254 87.9492 8.44942C87.5343 9.16776 87.7821 10.0855 88.5004 10.4996Z"
      fill="black"
    />
    <path
      d="M115.637 78.4304C114.855 78.1467 113.996 78.5521 113.716 79.3327C113.217 80.7129 112.657 82.0917 112.052 83.4316C111.71 84.1874 112.046 85.0765 112.801 85.4181C113.002 85.509 113.211 85.5522 113.418 85.5522C113.99 85.5522 114.537 85.2231 114.787 84.6689C115.425 83.2579 116.014 81.8051 116.539 80.3516C116.821 79.5724 116.417 78.7119 115.637 78.4304Z"
      fill="black"
    />
    <path
      d="M117.536 72.5993C118.241 72.5993 118.87 72.1002 119.008 71.3825C119.299 69.8711 119.535 68.323 119.71 66.7822C119.804 65.9584 119.211 65.2144 118.388 65.1213C117.552 65.0186 116.82 65.6204 116.728 66.4436C116.561 67.9081 116.337 69.3785 116.061 70.8137C115.903 71.6281 116.436 72.4153 117.25 72.5722C117.347 72.5905 117.442 72.5993 117.536 72.5993Z"
      fill="black"
    />
    <path
      d="M106.416 97.2002C106.866 97.2002 107.31 96.9994 107.606 96.6168C108.546 95.3978 109.455 94.1224 110.305 92.825C110.759 92.1316 110.566 91.2014 109.874 90.7462C109.179 90.2932 108.25 90.4852 107.795 91.1787C106.986 92.4116 106.122 93.6239 105.228 94.7813C104.722 95.4381 104.844 96.3808 105.499 96.8873C105.774 97.0984 106.096 97.2002 106.416 97.2002Z"
      fill="black"
    />
    <path
      d="M36.8135 7.70017C37.0115 7.70017 37.2138 7.65985 37.4087 7.57629C38.756 6.99282 40.1414 6.45627 41.5267 5.98055C42.311 5.71154 42.7288 4.8576 42.4606 4.07329C42.1908 3.29044 41.3361 2.87117 40.5533 3.14091C39.0932 3.64155 37.6345 4.20669 36.2154 4.82021C35.4546 5.15006 35.1057 6.03333 35.434 6.79418C35.6789 7.36079 36.2315 7.70017 36.8135 7.70017Z"
      fill="black"
    />
    <path
      d="M20.5767 103.219C20.0328 103.845 20.1002 104.793 20.7262 105.337C21.8917 106.348 23.1143 107.328 24.359 108.249C24.6272 108.447 24.941 108.543 25.2503 108.543C25.7106 108.543 26.1651 108.332 26.4583 107.934C26.9523 107.267 26.8116 106.328 26.1445 105.834C24.9615 104.961 23.8019 104.03 22.6936 103.069C22.0676 102.526 21.1191 102.592 20.5767 103.219Z"
      fill="black"
    />
    <path
      d="M19.2854 17.9025C18.6946 17.322 17.7417 17.3301 17.1626 17.9231C16.0822 19.0248 15.0296 20.1844 14.0356 21.3696C13.502 22.0051 13.5841 22.9514 14.2203 23.4851C14.5018 23.7211 14.8434 23.8362 15.1835 23.8362C15.613 23.8362 16.0382 23.6536 16.3343 23.3003C17.2799 22.1737 18.2797 21.072 19.3059 20.0253C19.8864 19.433 19.8776 18.4823 19.2854 17.9025Z"
      fill="black"
    />
    <path
      d="M65.1419 118.299C65.092 117.471 64.3884 116.847 63.5527 116.892C62.0853 116.98 60.5914 117.014 59.1298 116.99C59.121 116.99 59.1122 116.99 59.1049 116.99C58.2869 116.99 57.6169 117.646 57.6037 118.467C57.5905 119.295 58.2517 119.979 59.08 119.993C59.4025 119.998 59.7235 120 60.0475 120C61.2731 120 62.5133 119.963 63.7345 119.888C64.5613 119.839 65.1917 119.128 65.1419 118.299Z"
      fill="black"
    />
    <path
      d="M33.6349 110.528C32.8975 110.144 31.9915 110.427 31.6089 111.162C31.2248 111.897 31.5078 112.804 32.2422 113.188C33.6173 113.907 35.032 114.579 36.4496 115.186C36.6417 115.268 36.8425 115.308 37.0404 115.308C37.6224 115.308 38.1766 114.966 38.4199 114.396C38.7469 113.634 38.3936 112.752 37.6312 112.425C36.2855 111.849 34.9397 111.211 33.6349 110.528Z"
      fill="black"
    />
    <path
      d="M49.82 3.79751C49.9065 3.79751 49.9944 3.79018 50.0824 3.77479C51.5279 3.51971 53.0012 3.31886 54.4613 3.1774C55.2867 3.0975 55.8907 2.36377 55.81 1.53914C55.7309 0.713785 55.0052 0.100998 54.171 0.189691C52.6347 0.33849 51.0837 0.549593 49.5605 0.817871C48.7439 0.961539 48.1986 1.74072 48.3437 2.55655C48.4712 3.28515 49.1046 3.79751 49.82 3.79751Z"
      fill="black"
    />
    <path
      d="M76.5809 114.566C75.1735 114.991 73.7354 115.365 72.3031 115.679C71.4924 115.857 70.9808 116.658 71.1582 117.467C71.3121 118.169 71.9322 118.647 72.6227 118.647C72.7297 118.647 72.8367 118.636 72.9452 118.612C74.4537 118.282 75.9681 117.887 77.4488 117.44C78.2433 117.2 78.6919 116.362 78.4515 115.569C78.2126 114.775 77.3769 114.326 76.5809 114.566Z"
      fill="black"
    />
    <path
      d="M63.2985 2.99945C64.7689 3.08228 66.2495 3.22301 67.7009 3.41726C67.7683 3.42605 67.8357 3.43045 67.9017 3.43045C68.642 3.43045 69.2871 2.8829 69.3882 2.12864C69.4982 1.30695 68.9221 0.55123 68.0996 0.44128C66.5721 0.236774 65.0137 0.0887078 63.4685 0.00148082C62.6359 -0.0344362 61.9307 0.587147 61.8853 1.41617C61.8383 2.24373 62.4717 2.95254 63.2985 2.99945Z"
      fill="black"
    />
    <path
      d="M88.7136 109.282C87.4499 110.019 86.1378 110.717 84.8126 111.357C84.0664 111.718 83.7541 112.616 84.1148 113.362C84.3742 113.898 84.9093 114.21 85.4679 114.21C85.6863 114.21 85.9091 114.163 86.1203 114.061C87.513 113.387 88.8954 112.652 90.2265 111.877C90.9419 111.459 91.1838 110.54 90.7675 109.824C90.3497 109.108 89.4334 108.865 88.7136 109.282Z"
      fill="black"
    />
    <path
      d="M46.0083 115.257C45.202 115.052 44.3883 115.537 44.1831 116.343C43.9793 117.146 44.466 117.963 45.2694 118.167C46.7647 118.546 48.2952 118.87 49.8228 119.133C49.9078 119.148 49.9943 119.154 50.0779 119.154C50.7962 119.154 51.431 118.638 51.5556 117.907C51.6963 117.089 51.1481 116.314 50.33 116.173C48.8802 115.925 47.4259 115.616 46.0083 115.257Z"
      fill="black"
    />
    <path
      d="M11.6642 92.9588C10.9913 93.4433 10.8403 94.3816 11.3241 95.0537C12.2257 96.3035 13.1889 97.5371 14.1902 98.7202C14.4878 99.0713 14.9114 99.2516 15.338 99.2516C15.6796 99.2516 16.0241 99.1351 16.3056 98.8961C16.9389 98.361 17.0181 97.4133 16.483 96.7807C15.5315 95.657 14.6153 94.4857 13.7606 93.2989C13.2768 92.6268 12.3371 92.4736 11.6642 92.9588Z"
      fill="black"
    />
    <path
      d="M94.8899 92.478C94.0601 92.478 93.3887 93.1502 93.3887 93.9792V107.06C93.3887 107.889 94.0601 108.561 94.8899 108.561H107.969C108.799 108.561 109.471 107.889 109.471 107.06C109.471 106.231 108.799 105.559 107.969 105.559H99.0962C99.8721 104.894 100.638 104.216 101.373 103.518C101.974 102.948 101.998 101.997 101.428 101.396C100.856 100.796 99.908 100.771 99.3054 101.341C98.372 102.228 97.3901 103.087 96.391 103.915V93.9792C96.391 93.1502 95.7196 92.478 94.8899 92.478Z"
      fill="black"
    />
    <path
      d="M13.0734 13.6742H23.7492C23.7877 13.7501 23.8047 13.8324 23.8573 13.9029C24.0612 14.1762 24.349 14.3243 24.6534 14.4116V25.2541C24.6534 26.0832 25.3248 26.7553 26.1546 26.7553C26.9843 26.7553 27.6557 26.0832 27.6557 25.2541V12.9921C28.2973 12.5487 28.9465 12.1165 29.6011 11.7024C30.3004 11.259 30.51 10.3317 30.0658 9.63098C29.6231 8.93024 28.6951 8.7228 27.9944 9.1648C27.2337 9.64638 26.4828 10.1534 25.74 10.6718H13.0734C12.2437 10.6718 11.5723 11.344 11.5723 12.173C11.5723 13.002 12.2437 13.6742 13.0734 13.6742Z"
      fill="black"
    />
    <path
      d="M59.8224 60.8346H60.2739C65.5574 60.8346 69.8557 65.1153 69.8557 70.3775C69.8557 75.6397 65.5574 79.9204 60.2739 79.9204C54.989 79.9204 50.6907 75.6397 50.6907 70.3775C50.6907 69.5485 50.0193 68.8763 49.1895 68.8763C48.3598 68.8763 47.6883 69.5485 47.6883 70.3775C47.6883 76.6313 52.3076 81.816 58.3212 82.756V86.2257C58.3212 87.0547 58.9927 87.7269 59.8224 87.7269C60.6522 87.7269 61.3236 87.0547 61.3236 86.2257V82.8698C67.7718 82.3352 72.8581 76.9416 72.8581 70.3775C72.8581 63.4602 67.2125 57.8322 60.2739 57.8322H59.8224C54.539 57.8322 50.2406 53.5515 50.2406 48.2893C50.2406 43.0271 54.539 38.7457 59.8224 38.7457C65.1073 38.7457 69.4056 43.0271 69.4056 48.2893C69.4056 49.1183 70.0771 49.7905 70.9068 49.7905C71.7366 49.7905 72.408 49.1183 72.408 48.2893C72.408 41.8785 67.5572 36.5843 61.3236 35.8411V32.4411C61.3236 31.6121 60.6522 30.9399 59.8224 30.9399C58.9927 30.9399 58.3212 31.6121 58.3212 32.4411V35.8411C52.089 36.5845 47.2383 41.8786 47.2383 48.2893C47.2383 55.2066 52.8838 60.8346 59.8224 60.8346Z"
      fill="black"
    />
    <path
      d="M60.0461 101.849C83.074 101.849 101.809 83.114 101.809 60.0854C101.809 37.0567 83.074 18.3213 60.0461 18.3213C37.0167 18.3213 18.2812 37.0567 18.2812 60.0854C18.2812 83.114 37.0167 101.849 60.0461 101.849ZM60.0461 21.3237C81.4189 21.3237 98.8071 38.7119 98.8071 60.0854C98.8071 81.4589 81.4189 98.8471 60.0461 98.8471C38.6718 98.8471 21.2836 81.4589 21.2836 60.0854C21.2836 38.7119 38.6718 21.3237 60.0461 21.3237Z"
      fill="black"
    />
  </svg>
);

export default SvgSpending;
