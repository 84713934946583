import { ValidationError } from '../../error';
import { CurrencyUnit } from '../Currency/Currency.types';

export enum CountryCode {
  US = 'US',
  UK = 'UK',
}

export const CurrencyCountryCodeMap: Record<CurrencyUnit, CountryCode> = {
  [CurrencyUnit.USD]: CountryCode.US,
  [CurrencyUnit.EUR]: CountryCode.UK,
  [CurrencyUnit.GBP]: CountryCode.UK,
  [CurrencyUnit.CHF]: CountryCode.UK,
  [CurrencyUnit.SEK]: CountryCode.UK,
};

export function isValidCountryCode(
  value: unknown,
): asserts value is CountryCode {
  switch (value) {
    case CountryCode.US:
    case CountryCode.UK:
      return;
    default:
      throw new ValidationError(value, 'countryCode', [
        `error validating CountryCode: received ${JSON.stringify(value)}`,
      ]);
  }
}

export const stringToCountryCode = (str: string): CountryCode | null => {
  if (typeof str !== 'string') return null;

  switch (str.toLowerCase()) {
    case 'us':
    case 'en-us':
      return CountryCode.US;
    case 'uk':
    case 'en-gb':
      return CountryCode.UK;
    default:
      return null;
  }
};
