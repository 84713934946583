import React, { isValidElement } from 'react';
import { BaseLayoutProps } from './BaseLayout.types';
import styles from './base-layout.module.scss';
import BaseLayoutDisplay from '../BaseLayoutDisplay/BaseLayoutDisplay';

const BaseLayout: React.FC<BaseLayoutProps> = ({
  navigation,
  layoutContent,
  footer,
}) => {
  const filteredLayoutContent = layoutContent.filter(({ displayContent }) => {
    /* if an element is invalid, we should "skip" rendering it */
    return isValidElement(displayContent);
  });

  const renderLayoutContent = filteredLayoutContent.map((lc, i) => (
    <BaseLayoutDisplay
      key={i}
      first={i === 0}
      last={i === filteredLayoutContent.length - 1}
      {...lc}
    />
  ));

  return (
    <div className={styles.container}>
      {navigation && <div className={styles.navigation}>{navigation}</div>}
      <div className={styles.content}>{renderLayoutContent}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};

export default BaseLayout;
