import Typography from '../../sharedComponents/Typography/Typography';
import { AddPayoutAccountConfirmationProps } from './AddPayoutAccountConfirmation.types';
import styles from './add-payout-account-confirmation.module.scss';

const AddPayoutAccountConfirmation: React.FC<
  AddPayoutAccountConfirmationProps
> = ({ inputValues }): JSX.Element => {
  return (
    <div className={styles.fields}>
      {inputValues.map(({ label, value }, i) => {
        return (
          <div key={label} className={styles.field}>
            <Typography
              variant="body-3"
              key={`${i}_label`}
              className={styles.label}
            >
              {label}:{' '}
            </Typography>
            <Typography variant="label-3" key={`${i}_value`} color="ocean">
              {value}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default AddPayoutAccountConfirmation;
