import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgX = ({
  title, // eslint-disable-line @typescript-eslint/no-unused-vars
  titleId, // eslint-disable-line @typescript-eslint/no-unused-vars
  /* eslint-disable-line @typescript-eslint/ban-ts-comment */ /* @ts-ignore */
  ...props // eslint-disable-line @typescript-eslint/no-unused-vars
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    version="1.1"
    x="0px"
    y="0px"
  >
    <title>Icons/B/x</title>
    <desc>Created with Sketch.</desc>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M4.70710678,4 L11.9994466,11.2924466 L19.2928932,4 C19.4881554,3.80473785 19.8047379,3.80473785 20,4 C20.1952621,4.19526215 20.1952621,4.51184464 20,4.70710678 L12.7064466,11.9994466 L20,19.2928932 C20.1952621,19.4881554 20.1952621,19.8047379 20,20 C19.8047379,20.1952621 19.4881554,20.1952621 19.2928932,20 L11.9994466,12.7064466 L4.70710678,20 C4.51184464,20.1952621 4.19526215,20.1952621 4,20 C3.80473785,19.8047379 3.80473785,19.4881554 4,19.2928932 L11.2924466,11.9994466 L4,4.70710678 C3.80473785,4.51184464 3.80473785,4.19526215 4,4 C4.19526215,3.80473785 4.51184464,3.80473785 4.70710678,4 Z"
        fill="#000000"
      ></path>
    </g>
  </svg>
);

export default SvgX;
