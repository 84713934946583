import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPageFlowArrow = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 43 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <line
      x1={21.8564}
      y1={87.2358}
      x2={21.8564}
      y2={0.292358}
      stroke="#0B1419"
      strokeWidth={2}
    />
    <line
      x1={21.8094}
      y1={86.6342}
      x2={42.2145}
      y2={63.4197}
      stroke="#0B1419"
      strokeWidth={2}
    />
    <line
      y1={-1}
      x2={30.9063}
      y2={-1}
      transform="matrix(0.660133 0.751149 0.751149 -0.660133 2.45123 63.4878)"
      stroke="#0B1419"
      strokeWidth={2}
    />
  </svg>
);

export default SvgPageFlowArrow;
