import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDarkSensibleLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 598 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M229.127 77.1798C229.127 112.564 197.725 141.249 158.988 141.249C120.251 141.249 88.8486 112.564 88.8486 77.1798C88.8486 41.7952 120.251 13.1104 158.988 13.1104C197.725 13.1104 229.127 41.7952 229.127 77.1798Z"
      fill="#6D8EA0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.98456 10.416L9.98455 80.8893C9.98455 119.811 41.6697 151.363 80.7553 151.363C119.841 151.363 151.526 119.811 151.526 80.8894C151.526 41.968 119.841 10.416 80.7553 10.416L9.98456 10.416ZM80.7553 160.808C125.079 160.808 161.011 125.027 161.011 80.8894C161.011 36.7517 125.079 0.971112 80.7553 0.971108L0.499831 0.971101L0.499824 80.8893C0.49982 125.027 36.4314 160.808 80.7553 160.808Z"
      fill="#3D6881"
    />
    <path
      d="M4.54688 81.5517V0.970703L91.6428 6.34277L124.304 22.459L145.088 38.5752L156.965 81.5517L145.088 124.528L113.417 151.389L75.3123 156.761L31.7644 144.392L9.99037 102.415L4.54688 81.5517Z"
      fill="#3D6881"
    />
    <path
      d="M105.71 121.503C119.655 121.503 131.751 114.299 131.893 100.315C132.035 89.7209 125.063 83.7883 116.24 80.2569L106.422 76.443C102.58 75.0305 99.7338 73.0529 99.7338 69.6628C99.7338 66.6965 101.868 64.1539 106.422 64.1539C110.548 64.1539 114.533 66.1315 119.086 69.9453C120.367 70.9341 121.363 71.0754 122.359 69.9453L128.62 62.4588C129.331 61.6113 129.616 60.4813 128.762 59.4925C122.928 52.571 114.533 48.8984 105.426 48.8984C92.4767 48.8984 81.0929 56.5261 81.0929 69.8041C81.0929 79.4094 87.354 85.2008 96.3187 88.7322L105.141 92.2635C110.264 94.3824 112.398 96.0774 112.398 99.4675C112.398 103.423 109.268 105.259 104.572 105.259C99.1646 105.259 94.3265 102.858 88.4924 98.4787C87.354 97.6312 86.0733 97.3487 84.935 99.0437L79.9546 105.965C78.9585 107.519 78.8162 109.073 79.67 110.062C85.2195 116.559 94.0419 121.503 105.71 121.503Z"
      fill="white"
    />
    <path
      d="M210.915 81.952C210.915 64.4364 198.678 48.8984 177.476 48.8984C155.989 48.8984 141.617 64.5776 141.617 84.9183C141.617 105.824 156.985 121.503 179.895 121.503C189.713 121.503 198.678 118.678 204.654 112.745C205.935 111.615 206.077 110.627 205.366 109.638L200.101 102.434C199.389 101.445 198.678 101.304 197.682 101.869C191.99 105.118 186.867 106.106 181.46 106.106C170.076 106.106 163.104 101.304 160.969 92.546H203.231C209.35 92.546 210.915 88.7322 210.915 81.952ZM177.618 65.0014C186.013 65.0014 192.274 69.5216 193.413 78.8444H160.685C162.25 69.6628 168.938 65.0014 177.618 65.0014Z"
      fill="white"
    />
    <path
      d="M264.801 48.8984C254.413 48.8984 247.726 53.9836 243.741 61.0463L243.172 52.8535C243.172 51.1585 242.318 50.5935 241.038 50.5935H226.239C224.958 50.5935 224.104 51.441 224.104 52.7123V117.689C224.104 118.961 224.958 119.808 226.239 119.808H241.607C242.887 119.808 243.741 118.961 243.741 117.689V81.8107C243.741 72.0641 249.148 66.8377 257.402 66.8377C265.797 66.8377 270.351 72.0641 270.351 81.8107V117.689C270.351 118.961 271.204 119.808 272.485 119.808H287.711C288.992 119.808 289.988 118.961 289.988 117.689V77.4318C289.988 59.3512 279.458 48.8984 264.801 48.8984Z"
      fill="white"
    />
    <path
      d="M330.55 121.503C344.495 121.503 356.59 114.299 356.732 100.315C356.875 89.7209 349.902 83.7883 341.08 80.2569L331.261 76.443C327.419 75.0305 324.573 73.0529 324.573 69.6628C324.573 66.6965 326.708 64.1539 331.261 64.1539C335.388 64.1539 339.372 66.1315 343.926 69.9453C345.206 70.9341 346.203 71.0754 347.199 69.9453L353.46 62.4588C354.171 61.6113 354.456 60.4813 353.602 59.4925C347.768 52.571 339.372 48.8984 330.265 48.8984C317.316 48.8984 305.933 56.5261 305.933 69.8041C305.933 79.4094 312.194 85.2008 321.158 88.7322L329.981 92.2635C335.103 94.3824 337.238 96.0774 337.238 99.4675C337.238 103.423 334.107 105.259 329.412 105.259C324.004 105.259 319.166 102.858 313.332 98.4787C312.194 97.6312 310.913 97.3487 309.775 99.0437L304.794 105.965C303.798 107.519 303.656 109.073 304.51 110.062C310.059 116.559 318.882 121.503 330.55 121.503Z"
      fill="white"
    />
    <path
      d="M380.812 38.3043C387.073 38.3043 391.626 33.5017 391.626 27.4277C391.626 21.495 387.073 16.6924 380.812 16.6924C374.551 16.6924 369.855 21.495 369.855 27.4277C369.855 33.5017 374.551 38.3043 380.812 38.3043ZM372.985 119.808H388.496C389.776 119.808 390.63 118.961 390.63 117.689V52.7123C390.63 51.441 389.776 50.5935 388.496 50.5935H372.985C371.705 50.5935 370.851 51.441 370.851 52.7123V117.689C370.851 118.961 371.705 119.808 372.985 119.808Z"
      fill="white"
    />
    <path
      d="M449.081 48.8984C439.262 48.8984 432.147 52.571 427.452 58.7862V20.93C427.452 19.6587 426.598 18.8112 425.317 18.8112H409.807C408.526 18.8112 407.672 19.6587 407.672 20.93V117.689C407.672 118.961 408.668 119.808 409.949 119.808H423.894C425.175 119.808 426.029 118.961 426.029 117.689L426.313 110.062C431.151 117.124 438.551 121.503 449.081 121.503C468.575 121.503 482.093 105.824 482.093 85.2008C482.093 64.7189 468.575 48.8984 449.081 48.8984ZM444.954 103.705C434.424 103.705 427.167 96.3599 427.167 85.2008C427.167 74.0417 434.424 66.6965 444.954 66.6965C455.199 66.6965 462.883 74.1829 462.883 85.2008C462.883 96.3599 455.199 103.705 444.954 103.705Z"
      fill="white"
    />
    <path
      d="M497.63 119.808H513.141C514.422 119.808 515.275 118.961 515.275 117.689V20.93C515.275 19.6587 514.422 18.8112 513.141 18.8112H497.63C496.35 18.8112 495.496 19.6587 495.496 20.93V117.689C495.496 118.961 496.35 119.808 497.63 119.808Z"
      fill="white"
    />
    <path
      d="M597.923 81.952C597.923 64.4364 585.685 48.8984 564.483 48.8984C542.996 48.8984 528.624 64.5776 528.624 84.9183C528.624 105.824 543.992 121.503 566.902 121.503C576.721 121.503 585.685 118.678 591.662 112.745C592.943 111.615 593.085 110.627 592.373 109.638L587.108 102.434C586.397 101.445 585.685 101.304 584.689 101.869C578.997 105.118 573.875 106.106 568.468 106.106C557.084 106.106 550.111 101.304 547.977 92.546H590.239C596.358 92.546 597.923 88.7322 597.923 81.952ZM564.625 65.0014C573.021 65.0014 579.282 69.5216 580.42 78.8444H547.692C549.257 69.6628 555.945 65.0014 564.625 65.0014Z"
      fill="white"
    />
  </svg>
);

export default SvgDarkSensibleLogo;
