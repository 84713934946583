import React from 'react';
import BlankCanvasLayout from '../sharedComponents/BlankCanvasLayout/BlankCanvasLayout';
import SandSwitchLayout from '../sharedComponents/SandSwitchLayout/SandSwitchLayout';
import SnowLayout from '../sharedComponents/SnowLayout/SnowLayout';
import SandSplitLayout from '../sharedComponents/SandSplitLayout/SandSplitLayout';
import {
  MyTrips,
  Notification,
  Profile,
  TripDetails,
  PageProps,
  PayoutManagement,
} from '../pages';
import { LayoutProps } from '../sharedComponents/types';

export interface IRouteConfig {
  path: string;
  name: string;
  element: React.FC<PageProps>;
  layout: React.FC<LayoutProps>;
  private: boolean;
  displayInNavigation?: boolean;
}

const routes: IRouteConfig[] = [
  {
    path: '/trips',
    name: 'My Trips',
    element: MyTrips,
    layout: SandSwitchLayout,
    private: true,
    displayInNavigation: true,
  },
  {
    path: '/profile',
    name: 'My Profile',
    element: Profile,
    layout: SandSplitLayout,
    private: true,
    displayInNavigation: true,
  },
  {
    path: '/payout-management',
    name: 'Payout Accounts',
    element: PayoutManagement,
    layout: SnowLayout,
    private: true,
    displayInNavigation: true,
  },
  {
    path: '/trips/:name',
    name: 'Trip Details',
    element: TripDetails,
    layout: BlankCanvasLayout,
    private: true,
  },
  {
    path: '/notification',
    name: 'Notification',
    element: Notification,
    layout: SnowLayout,
    private: true,
  },
];

export default routes;
