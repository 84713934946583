import { FC, useState } from 'react';
import { ManagePayoutAccountListProps } from './ManagePayoutAccountList.types';
import ManagePayoutAccountDrawer from '../../components/ManagePayoutAccountDrawer/ManagePayoutAccountDrawer';
import styles from './manage-payout-account-list.module.scss';

const ManagePayoutAccountList: FC<ManagePayoutAccountListProps> = ({
  accounts,
}) => {
  const [expandedPayoutAccount, setExpandedPayoutAccount] =
    useState<string>('');
  const accountClickHandler = (id: string): void => {
    if (expandedPayoutAccount === id) {
      setExpandedPayoutAccount('');
    } else {
      setExpandedPayoutAccount(id);
    }
  };

  const renderedPayoutAccounts = accounts.map((account) => {
    return (
      <div className={styles.accountWrapper} key={account.id}>
        <ManagePayoutAccountDrawer
          isExpanded={expandedPayoutAccount === account.id}
          account={account}
          clickHandler={accountClickHandler}
        />
      </div>
    );
  });
  return <>{renderedPayoutAccounts}</>;
};

export default ManagePayoutAccountList;
