import * as generalPNG from './png/general';
import * as sensiblePNG from './png/sensible';

import * as alertsSVG from './svg-tsx/alerts';
import * as buttonIconsSVG from './svg-tsx/buttonIcons';
import * as iconsSVG from './svg-tsx/icons';
import * as payoutProviderLogosPNG from './png/payoutProviderLogos';
import * as sensibleSVG from './svg-tsx/sensible';

export const assets = {
  png: {
    general: generalPNG,
    sensible: sensiblePNG,
    payoutProviderLogosPNG,
  },
  svg: {
    alerts: alertsSVG,
    buttonIcons: buttonIconsSVG,
    icons: iconsSVG,
    sensible: sensibleSVG,
  },
};
