import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { entityHelpers } from '../../utils';
import { default as helpers } from '../../utils/helpers/helpers';
import Modal from '../../sharedComponents/Modal/Modal';
import IconTextButton from '../../sharedComponents/IconTextButton/IconTextButton';
import { assets } from '../../sharedComponents/assets';
import Typography from '../../sharedComponents/Typography/Typography';
import { ViewYourGuaranteeDetailsProps } from './ViewYourGuaranteeDetails.types';
import styles from './view-your-guarantee-details.module.scss';
import PayoutTiersTable from '../PayoutTiersTable/PayoutTiersTable';
import CoverageDetails from '../CoverageDetails/CoverageDetails';
import PolicyDetails from '../PolicyDetails/PolicyDetails';
import PerilCoverageDetails from '../PerilCoverageDetails/PerilCoverageDetails';

const ViewYourGuaranteeDetails: React.FC<ViewYourGuaranteeDetailsProps> = ({
  bundle,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const openModal = (): void => setOpen(true);
  const closeModal = (): void => setOpen(false);

  const {
    coverageStartDate,
    coverageEndDate,
    policies: [policy],
  } = bundle;

  const perilCoverage = entityHelpers.perilCoverageForPolicyBundle(bundle);

  const locationName = entityHelpers.getLocationNameFromPolicyBundle(bundle);

  const timezone = entityHelpers.getTimeZoneFromPolicyBundle(bundle);

  const payoutTiers = entityHelpers.getPayoutTiersPolicyBundle(bundle);

  const { numberOfHours } = helpers.sortByKey(payoutTiers, 'numberOfHours')[0];

  return (
    <div>
      <IconTextButton
        onClickHandler={openModal}
        label={t('sunshine.action.view_guarantee_details')}
        icon={assets.svg.buttonIcons.Warranty}
        format={'none'}
      />

      <Modal
        isActive={open}
        onCloseHandler={closeModal}
        title={
          <Typography variant="h5">
            {t('sunshine.content.guarantee_details.header')}
          </Typography>
        }
      >
        <div className={styles.modalWrapper}>
          <PolicyDetails
            coverageStartHour={policy.start}
            coverageEndHour={policy.end}
            minHoursCovered={numberOfHours}
            tz={timezone}
          />

          <Typography variant="h5">
            {t('sunshine.content.guarantee_coverage.header')}
          </Typography>

          <CoverageDetails
            location={locationName}
            start={coverageStartDate}
            end={coverageEndDate}
            coverageStartHour={policy.start}
            coverageEndHour={policy.end}
            tz={timezone}
          />

          <Typography variant="h5">
            {t('sunshine.content.peril_coverage.header')}
          </Typography>
          <PerilCoverageDetails perilCoverage={perilCoverage} />

          <Typography variant="h5">
            {t('sunshine.content.daily_breakdown.header')}
          </Typography>
          <PayoutTiersTable tiers={payoutTiers} currency={bundle.currency} />
        </div>
      </Modal>
    </div>
  );
};

export default ViewYourGuaranteeDetails;
