import { ChangeEvent } from 'react';
import format from '../../utils/format';
import BaseInput from '../base/BaseInput/BaseInput';
import { TextInputProps } from './TextInput.types';

const randomNumber = (length: number): number => {
  const max = Math.pow(10, length) - 1;
  const min = Math.pow(10, length - 1);
  const range = max - min;
  return Math.round(Math.random() * range) + min;
};

const TextInput: React.FC<TextInputProps> = ({
  label,
  handleChange,
  value,
  disabled = false,
  errorMsg = '',
  onBlur,
  onFocus,
  labelFormat = 'title',
  inputRef,
}) => {
  const id = `text-input-${randomNumber(6)}`;
  const formatLabel = (): string => {
    switch (labelFormat) {
      case 'title':
        return format.toTitleCase(label);
      default:
      case 'none':
        return label;
    }
  };

  const handleChangeWrapper = (event: ChangeEvent<HTMLInputElement>): void => {
    handleChange(event.target.value);
  };

  const onBlurWrapper = (): void => {
    const trimmed = value.trim();

    handleChange(trimmed);

    if (onBlur) {
      onBlur(trimmed);
    }
  };

  return (
    <BaseInput
      element={
        <input
          placeholder={formatLabel()}
          value={value}
          onChange={handleChangeWrapper}
          disabled={disabled}
          id={id}
          onBlur={onBlurWrapper}
          onFocus={onFocus}
          ref={inputRef}
        />
      }
      label={formatLabel()}
      labelFormat={labelFormat}
      id={id}
      errorMsg={errorMsg}
      value={value}
    />
  );
};

export default TextInput;
