import { Glyph } from '@visx/glyph';

type GlyphProps = {
  left: number;
  top: number;
};
const SensibleGlyph = ({ left, top }: GlyphProps) => {
  return (
    <Glyph left={left} top={top}>
      <svg role="img" viewBox="0 0 165 29" height="20" width="100" fill="none">
        <title>Sensible Weather Logo</title>

        <g>
          <path
            d="M41.62 13.8221C41.62 20.2391 35.9052 25.449 28.8549 25.449C21.8046 25.449 16.0898 20.2494 16.0898 13.8221C16.0898 7.39488 21.8046 2.19531 28.8549 2.19531C35.9052 2.19531 41.62 7.39488 41.62 13.8221Z"
            fill="#FFE600"
          />
          <path
            d="M14.6079 0H0V14.4948C0 19.1476 2.21551 23.2949 5.65267 25.9463L5.68373 26.0185L5.76655 26.0391C8.22018 27.8961 11.2846 28.9897 14.6079 28.9897C22.6728 28.9897 29.2158 22.5005 29.2158 14.4948C29.2158 6.48915 22.6831 0 14.6079 0Z"
            fill="#00FFE0"
          />
          <path
            d="M81.6429 8.67934C79.8519 8.67934 78.5578 9.33961 77.7089 10.4538V3.65515C77.7089 3.42819 77.5536 3.27344 77.3154 3.27344H74.4891C74.251 3.27344 74.0957 3.42819 74.0957 3.65515V21.049C74.0957 21.2759 74.2717 21.4307 74.5098 21.4307H77.0463C77.2844 21.4307 77.4397 21.2759 77.4397 21.049L77.4914 19.6769C78.3714 20.9458 79.7173 21.7299 81.6326 21.7299C85.1836 21.7299 87.6373 18.9134 87.6373 15.1994C87.6373 11.4855 85.1733 8.66903 81.6326 8.66903L81.6429 8.67934ZM80.8975 18.5317C78.9823 18.5317 77.6571 17.2112 77.6571 15.1994C77.6571 13.1877 78.9823 11.8672 80.8975 11.8672C82.8128 11.8672 84.1587 13.2187 84.1587 15.1994C84.1587 17.1802 82.7611 18.5317 80.8975 18.5317Z"
            fill="#0B1419"
          />
          <path
            d="M18.8941 21.8538C21.4306 21.8538 23.6357 20.5539 23.6564 18.047C23.6875 16.1384 22.4141 15.0758 20.8094 14.4362L19.0184 13.7553C18.3144 13.4973 17.7967 13.1466 17.7967 12.5379C17.7967 12.0014 18.1901 11.5475 19.0184 11.5475C19.7741 11.5475 20.4988 11.8983 21.327 12.5895C21.5652 12.7649 21.7412 12.7958 21.9275 12.5895L23.0663 11.238C23.2009 11.0833 23.2527 10.8872 23.0974 10.7015C22.031 9.45323 20.5092 8.79297 18.8527 8.79297C16.4923 8.79297 14.4217 10.1651 14.4217 12.5482C14.4217 14.2711 15.5605 15.3131 17.1962 15.9527L18.8009 16.5923C19.7327 16.974 20.1261 17.2732 20.1261 17.8922C20.1261 18.6041 19.5567 18.9342 18.6974 18.9342C17.7139 18.9342 16.8339 18.5009 15.7675 17.7168C15.5605 17.5621 15.3224 17.5105 15.1153 17.82L14.2043 19.0683C14.0179 19.3469 13.9972 19.6254 14.1525 19.8008C15.1671 20.9666 16.7718 21.8538 18.8941 21.8538Z"
            fill="#0B1419"
          />
          <path
            d="M38.0356 14.7432C38.0356 11.5966 35.8097 8.80078 31.9481 8.80078C28.0865 8.80078 25.4258 11.6172 25.4258 15.2796C25.4258 18.942 28.2211 21.8616 32.3933 21.8616C34.1843 21.8616 35.8097 21.3561 36.8968 20.2832C37.1349 20.0768 37.1556 19.9015 37.0314 19.7261L36.0685 18.4262C35.9339 18.2508 35.8097 18.2198 35.6234 18.323C34.5881 18.9111 33.6563 19.0864 32.6728 19.0864C30.6022 19.0864 29.3288 18.2198 28.9458 16.6517H36.6379C37.7561 16.6517 38.0356 15.9708 38.0356 14.7432ZM31.9792 11.6997C33.5114 11.6997 34.6502 12.5148 34.8572 14.1861H28.9043C29.1942 12.5354 30.4055 11.6997 31.9895 11.6997H31.9792Z"
            fill="#0B1419"
          />
          <path
            d="M47.8502 8.80078C45.9556 8.80078 44.7443 9.71896 44.0196 10.9879L43.9161 9.51263C43.9161 9.20313 43.7608 9.11028 43.5227 9.11028H40.8309C40.5928 9.11028 40.4375 9.26503 40.4375 9.49199V21.1807C40.4375 21.4077 40.5928 21.5624 40.8309 21.5624H43.6262C43.8643 21.5624 44.0196 21.4077 44.0196 21.1807V14.7328C44.0196 12.979 45.0031 12.0402 46.5043 12.0402C48.0054 12.0402 48.8647 12.979 48.8647 14.7328V21.1807C48.8647 21.4077 49.02 21.5624 49.2581 21.5624H52.0327C52.2708 21.5624 52.4468 21.4077 52.4468 21.1807V13.9385C52.4468 10.6887 50.5316 8.8111 47.8605 8.8111L47.8502 8.80078Z"
            fill="#0B1419"
          />
          <path
            d="M59.8082 21.8538C62.3446 21.8538 64.5498 20.5539 64.5705 18.047C64.6016 16.1384 63.3282 15.0758 61.7235 14.4362L59.9324 13.7553C59.2284 13.4973 58.7108 13.1466 58.7108 12.5379C58.7108 12.0014 59.1042 11.5475 59.9324 11.5475C60.6882 11.5475 61.4129 11.8983 62.2411 12.5895C62.4792 12.7649 62.6552 12.7958 62.8416 12.5895L63.9804 11.238C64.115 11.0833 64.1667 10.8872 64.0114 10.7015C62.9451 9.45323 61.4232 8.79297 59.7668 8.79297C57.4063 8.79297 55.3357 10.1651 55.3357 12.5482C55.3357 14.2711 56.4746 15.3131 58.1103 15.9527L59.715 16.5923C60.6468 16.974 61.0402 17.2732 61.0402 17.8922C61.0402 18.6041 60.4708 18.9342 59.6115 18.9342C58.628 18.9342 57.748 18.5009 56.6816 17.7168C56.4746 17.5621 56.2364 17.5105 56.0294 17.82L55.1183 19.0683C54.932 19.3469 54.9113 19.6254 55.0666 19.8008C56.0811 20.9666 57.6858 21.8538 59.8082 21.8538Z"
            fill="#0B1419"
          />
          <path
            d="M68.959 6.89327C70.0979 6.89327 70.9261 6.02667 70.9261 4.93311C70.9261 3.83955 70.0979 3.00391 68.959 3.00391C67.8202 3.00391 66.9609 3.8705 66.9609 4.93311C66.9609 5.99572 67.8202 6.89327 68.959 6.89327ZM67.5407 21.5532H70.367C70.6051 21.5532 70.7604 21.3984 70.7604 21.1715V9.48274C70.7604 9.25577 70.6051 9.10103 70.367 9.10103H67.5407C67.3026 9.10103 67.1473 9.25577 67.1473 9.48274V21.1715C67.1473 21.3984 67.3026 21.5532 67.5407 21.5532Z"
            fill="#0B1419"
          />
          <path
            d="M90.2137 21.5504H93.0401C93.2782 21.5504 93.4335 21.3956 93.4335 21.1687V3.76453C93.4335 3.53756 93.2782 3.38281 93.0401 3.38281H90.2137C89.9756 3.38281 89.8203 3.53756 89.8203 3.76453V21.1687C89.8203 21.3956 89.9756 21.5504 90.2137 21.5504Z"
            fill="#0B1419"
          />
          <path
            d="M108.467 14.7432C108.467 11.5966 106.241 8.80078 102.38 8.80078C98.5181 8.80078 95.8574 11.6172 95.8574 15.2796C95.8574 18.942 98.6527 21.8616 102.825 21.8616C104.616 21.8616 106.241 21.3561 107.328 20.2832C107.567 20.0768 107.587 19.9015 107.463 19.7261L106.5 18.4262C106.366 18.2508 106.241 18.2198 106.065 18.323C105.03 18.9111 104.098 19.0864 103.115 19.0864C101.044 19.0864 99.7708 18.2198 99.3878 16.6517H107.08C108.198 16.6517 108.478 15.9708 108.478 14.7432H108.467ZM102.411 11.6997C103.943 11.6997 105.082 12.5148 105.289 14.1861H99.336C99.6155 12.5354 100.837 11.6997 102.421 11.6997H102.411Z"
            fill="#0B1419"
          />
          <path
            d="M113.438 21.6356H114.162C114.276 21.6356 114.369 21.5737 114.421 21.4602L116.399 15.7861L118.366 21.4602C118.407 21.5737 118.511 21.6356 118.624 21.6356H119.349C119.463 21.6356 119.556 21.5737 119.608 21.4602L121.886 14.9092C121.937 14.7544 121.886 14.6719 121.73 14.6719H121.078C120.964 14.6719 120.912 14.7338 120.871 14.8473L118.976 20.5214L117.03 14.8473C116.989 14.7338 116.885 14.6719 116.771 14.6719H116.067C115.953 14.6719 115.86 14.7338 115.809 14.8473L113.862 20.5214L111.968 14.8473C111.926 14.7338 111.854 14.6719 111.74 14.6719H111.108C110.953 14.6719 110.901 14.7544 110.953 14.9092L113.21 21.4602C113.251 21.5737 113.355 21.6356 113.469 21.6356H113.438Z"
            fill="#0B1419"
          />
          <path
            d="M129.473 17.7935C129.473 16.0913 128.282 14.4922 126.232 14.4922C124.183 14.4922 122.754 16.0706 122.754 18.1133C122.754 20.156 124.152 21.7963 126.419 21.7963C127.382 21.7963 128.272 21.4972 128.893 20.8988C128.997 20.7956 129.048 20.7234 128.945 20.5996L128.707 20.2695C128.655 20.1973 128.562 20.1973 128.5 20.2592C127.961 20.6718 127.351 20.9298 126.502 20.9298C124.824 20.9298 123.872 19.8878 123.748 18.5157H128.966C129.38 18.5157 129.473 18.299 129.473 17.7935ZM126.222 15.4C127.62 15.4 128.531 16.3492 128.572 17.7213H123.748C123.872 16.3182 124.845 15.4 126.222 15.4Z"
            fill="#0B1419"
          />
          <path
            d="M137.799 14.6676H137.188C137.064 14.6676 137.002 14.7398 137.002 14.8636V16.0397C136.484 15.0906 135.563 14.4922 134.32 14.4922C132.301 14.4922 130.914 16.0706 130.914 18.1443C130.914 20.2179 132.301 21.7963 134.32 21.7963C135.563 21.7963 136.484 21.2083 136.991 20.2695V21.425C137.002 21.5488 137.064 21.621 137.188 21.621H137.799C137.923 21.621 137.985 21.5488 137.985 21.425V14.8533C137.985 14.7295 137.933 14.6573 137.799 14.6573V14.6676ZM134.465 20.8575C132.985 20.8575 131.908 19.7743 131.908 18.1546C131.908 16.5349 132.985 15.4516 134.465 15.4516C135.946 15.4516 136.981 16.5658 136.981 18.1855C136.981 19.8052 135.946 20.8575 134.465 20.8575Z"
            fill="#0B1419"
          />
          <path
            d="M142.984 15.5247C143.108 15.5247 143.18 15.4525 143.18 15.3287V14.8747C143.18 14.7509 143.108 14.6787 142.984 14.6787H141.814V13.028C141.814 12.9042 141.762 12.832 141.627 12.832H140.975C140.851 12.832 140.789 12.9042 140.789 13.028V14.6787H139.888C139.764 14.6787 139.691 14.7509 139.691 14.8747V15.3287C139.691 15.4525 139.764 15.5247 139.888 15.5247H140.789V20.3013C140.789 21.3226 141.296 21.8075 142.259 21.8075C142.621 21.8075 143.149 21.7353 143.149 21.498V21.075C143.149 20.9306 143.098 20.8584 142.88 20.8893C142.756 20.8996 142.559 20.9203 142.445 20.9203C142.021 20.9203 141.814 20.7242 141.814 20.1775V15.5247H142.984Z"
            fill="#0B1419"
          />
          <path
            d="M148.182 14.4954C147.033 14.4954 146.319 15.0628 145.925 15.7747V11.6687C145.925 11.5449 145.874 11.4727 145.739 11.4727H145.087C144.963 11.4727 144.9 11.5449 144.9 11.6687V21.4385C144.9 21.5623 144.952 21.6345 145.087 21.6345H145.739C145.863 21.6345 145.925 21.5623 145.925 21.4385V17.446C145.925 16.208 146.785 15.4549 147.934 15.4549C149.083 15.4549 149.725 16.208 149.725 17.446V21.4385C149.725 21.5623 149.766 21.6345 149.89 21.6345H150.553C150.677 21.6345 150.739 21.5623 150.739 21.4385V17.3428C150.739 15.4961 149.694 14.4954 148.182 14.4954Z"
            fill="#0B1419"
          />
          <path
            d="M159.25 17.7935C159.25 16.0913 158.06 14.4922 156.01 14.4922C153.96 14.4922 152.531 16.0706 152.531 18.1133C152.531 20.156 153.929 21.7963 156.196 21.7963C157.159 21.7963 158.049 21.4972 158.67 20.8988C158.774 20.7956 158.826 20.7234 158.722 20.5996L158.484 20.2695C158.432 20.1973 158.339 20.1973 158.277 20.2592C157.739 20.6718 157.128 20.9298 156.279 20.9298C154.602 20.9298 153.649 19.8878 153.525 18.5157H158.743C159.157 18.5157 159.25 18.299 159.25 17.7935ZM155.999 15.4C157.397 15.4 158.308 16.3492 158.35 17.7213H153.525C153.649 16.3182 154.623 15.4 155.999 15.4Z"
            fill="#0B1419"
          />
          <path
            d="M164.281 14.6366C164.157 14.5541 163.929 14.4922 163.649 14.4922C162.79 14.4922 162.324 15.0183 162.055 15.6683L162.024 14.8739C162.024 14.7192 161.972 14.6573 161.827 14.6573H161.237C161.113 14.6573 161.051 14.7295 161.051 14.8533V21.425C161.051 21.5488 161.103 21.621 161.237 21.621H161.889C162.014 21.621 162.076 21.5488 162.076 21.425V17.3293C162.076 16.5452 162.417 15.4207 163.66 15.4207C163.805 15.4207 163.97 15.431 164.157 15.4929C164.312 15.5342 164.415 15.5032 164.415 15.3485V14.8636C164.415 14.7398 164.384 14.6779 164.302 14.6263L164.281 14.6366Z"
            fill="#0B1419"
          />
        </g>
      </svg>
    </Glyph>
  );
};

export default SensibleGlyph;
