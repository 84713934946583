import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavElementProps } from './NavElement.types';
import { CaretDown } from '../assets/svg-tsx/sensible';
import Typography from '../Typography/Typography';
import styles from './nav-element.module.scss';

const NavElement: FC<NavElementProps> = ({
  title,
  linkPath,
  navigateTo,
  caretDirection = 'back',
  colorVariant,
  disabled = false,
}) => {
  const navigate = useNavigate();

  const caret = (
    <div className={styles[caretDirection]}>
      <CaretDown title="Caret" />
    </div>
  );

  const renderTitle = (): JSX.Element => {
    const header = (
      <Typography variant="label-3" color={colorVariant}>
        {title}
      </Typography>
    );

    if (disabled) {
      return header;
    }
    if (linkPath) {
      return <button onClick={(): void => navigate(linkPath)}>{header}</button>;
    }
    if (navigateTo) {
      return (
        <button onClick={(): void => navigate(navigateTo)}>{header}</button>
      );
    }
    return <></>;
  };

  return (
    <div
      className={`${styles.container} ${
        disabled ? styles.disabled : styles[colorVariant]
      }`}
    >
      {caretDirection === 'back' && caret}
      {renderTitle()}
      {caretDirection === 'forward' && caret}
    </div>
  );
};

export default NavElement;
