import { useTranslation } from 'react-i18next';
import { default as helpers } from '../../utils/helpers/helpers';
import sensibleDate from '../../utils/types/SensibleDate';
import { type PolicyBundle } from '../../utils/types/Entity';
import Typography from '../../sharedComponents/Typography/Typography';
import styles from './trip-list-container.module.scss';
import { TripListContainerProps } from './TripListContainer.types';
import TripListComponent from '../../components/TripListComponent/TripListComponent';
import TripListSection from '../../components/TripListSection/TripListSection';

const TripListContainer: React.FunctionComponent<TripListContainerProps> = ({
  policyBundles,
}) => {
  const { t } = useTranslation();
  const today = new Date();

  const currentTrips: PolicyBundle[] = [];
  const pastTrips: PolicyBundle[] = [];
  const upcomingTrips: PolicyBundle[] = [];

  const filterTripsByDates = (policyBundles: PolicyBundle[]): void => {
    const activePolicyBundles = policyBundles.filter(
      (policyBundle) => policyBundle.canceledAt === undefined,
    );
    const sortedPolicies = helpers.sortByKey(
      activePolicyBundles,
      'coverageStartDate',
    );
    for (let i = 0; i < sortedPolicies.length; i++) {
      const spI = sortedPolicies[i];
      const start = sensibleDate.parseRFC(spI['coverageStartDate']);
      const end = sensibleDate.parseRFC(spI['coverageEndDate']);
      if (sensibleDate.isAfter(start, today)) {
        upcomingTrips.push(spI);
      } else if (
        (sensibleDate.isBefore(start, today) ||
          sensibleDate.isEqual(start, today)) &&
        (sensibleDate.isAfter(end, today) || sensibleDate.isEqual(end, today))
      ) {
        currentTrips.push(spI);
      } else {
        pastTrips.push(spI);
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  filterTripsByDates(policyBundles);

  //TODO: #1083 replace location name with a function that extracts the name
  //from the policyBindle object
  const renderTrips = (trips: PolicyBundle[]): JSX.Element[] => {
    return trips.map((trip: PolicyBundle) => (
      <TripListComponent
        key={`${trip.policies[0].exposures[0].name}_${trip.id}`}
        //TODO: #1184 add proper render of partner logo from the policyBundle object properties
        //logo={trip logo code here}
        partnerName={trip.manager.name}
        location={trip.policies[0].exposures[0].name}
        start={trip.coverageStartDate}
        end={trip.coverageEndDate}
        id={trip.id}
        slug={trip.slug}
      />
    ));
  };

  if (policyBundles.length === 0) {
    return (
      <div className={styles.container}>
        <div className={styles.sectionWrapper}>
          <Typography variant="body-2">
            {t('sunshine.content.trips.explainer')}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {!!currentTrips.length && (
        <TripListSection header={t('sunshine.label.current_trips')}>
          {renderTrips(currentTrips)}
        </TripListSection>
      )}
      {!!upcomingTrips.length && (
        <TripListSection header={t('sunshine.label.upcoming_trips')}>
          {renderTrips(upcomingTrips)}
        </TripListSection>
      )}
      {!!pastTrips.length && (
        <TripListSection header={t('sunshine.label.past_trips')}>
          {renderTrips(pastTrips)}
        </TripListSection>
      )}
    </div>
  );
};

export default TripListContainer;
