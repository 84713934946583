import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SandSplitLayout from '../sharedComponents/SandSplitLayout/SandSplitLayout';
import { Welcome } from '../pages';
import { IRouteConfig } from './RouteConfig';
import { Navigation } from '../components';
import PrivateRoute from './PrivateRoute';
import { useStore } from '../store/hooks';
import { hasAcceptedTermsAndPolicies } from '../utils/auth';
import { analytics } from '../analytics';

const RouteElement: React.FC<{ route: IRouteConfig }> = ({ route }) => {
  let userExists = false;
  const { user } = useStore().user;
  const pageLogged = window.sessionStorage.getItem('route');

  if (user.id) {
    userExists = true;
  }

  // log page in analytics
  // check & store route in sessionStorage to prevent refires on the same route
  if (route.path && pageLogged !== route.path) {
    window.sessionStorage.setItem('route', route.path);
    analytics.page();
  }

  const { user: authUser, isAuthenticated } = useAuth0();
  const acceptedTermsAndPolicies = hasAcceptedTermsAndPolicies(authUser);

  if (isAuthenticated) {
    if (!userExists || !acceptedTermsAndPolicies) {
      return <Welcome layout={SandSplitLayout} navigation={Navigation} />;
    }
  }

  return route.private ? (
    <PrivateRoute component={route.element} layout={route.layout} />
  ) : (
    <route.element layout={route.layout} navigation={Navigation} />
  );
};

export default RouteElement;
