//used in client
import { ValidationError } from '../../error/ValidationError';

export enum CurrencyUnit {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  CHF = 'chf',
  SEK = 'sek',
}

export function isCurrencyUnit(value: unknown): asserts value is CurrencyUnit {
  switch (value) {
    case CurrencyUnit.USD:
    case CurrencyUnit.EUR:
    case CurrencyUnit.GBP:
    case CurrencyUnit.CHF:
    case CurrencyUnit.SEK:
      return;
    default:
      throw new ValidationError(value, 'currencyUnit', [
        `error validating CurrencyUnit: received ${JSON.stringify(value)}`,
      ]);
  }
}

export const stringToCurrencyUnit = (str: string): CurrencyUnit | null => {
  if (typeof str !== 'string') return null;
  switch (str.toLowerCase()) {
    case 'usd':
      return CurrencyUnit.USD;
    case 'eur':
      return CurrencyUnit.EUR;
    case 'gbp':
      return CurrencyUnit.GBP;
    case 'chf':
      return CurrencyUnit.CHF;
    case 'sek':
      return CurrencyUnit.SEK;
    default:
      return null;
  }
};

export const currencyUnitToSymbol = (currency: CurrencyUnit): string => {
  switch (currency) {
    case CurrencyUnit.USD:
      return '$';
    case CurrencyUnit.EUR:
      return '€';
    case CurrencyUnit.GBP:
      return '£';
    case CurrencyUnit.CHF:
      return 'CHF';
    case CurrencyUnit.SEK:
      return 'kr';
    default:
      return '';
  }
};
