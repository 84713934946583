import React from 'react';
import styles from './blank-canvas-layout.module.scss';
import { LayoutProps } from '../types/layout.types';

/**
 * @description
 * The `BlankCanvasLayout` should be used with the `BlankCanvasBlock` component. It accepts children and the
 * navigation component the same way that other layouts do, but does not enforce any of the width or
 * media query styling.
 *
 * This layout should be used for situations where one of the standard layouts does not work. For example, if we
 * want to switch color blocks based on deeply nested children, we can wrap those children in `BlankCanvasBlocks`
 * with the appropriate color variant prop, creating a layout on the fly.
 */
const BlankCanvasLayout: React.FC<LayoutProps> = ({
  children,
  navigation,
  footer,
}) => {
  return (
    <div className={styles.container}>
      {navigation && <div className={styles.navigation}>{navigation}</div>}
      <div className={styles.content}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};

export default BlankCanvasLayout;
