import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LayoutProps } from '../sharedComponents/types';
import { PageProps } from '../pages';
import { Navigation } from '../components';

const PrivateRoute = ({
  component,
  layout,
}: {
  component: React.FC<PageProps>;
  layout: React.FC<LayoutProps>;
}): JSX.Element => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <></>,
  });

  return <Component layout={layout} navigation={Navigation} />;
};

export default React.memo(PrivateRoute);
