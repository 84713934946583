import { configureStore, createSelector } from '@reduxjs/toolkit';
import {
  payoutAccountActions,
  payoutAccountReducer,
} from './slices/payoutAccountSlice';
import {
  policyBundlesActions,
  policyBundlesReducer,
} from './slices/policyBundleSlice';
import { userActions, userReducer } from './slices/userSlice';

export const storeConfig = {
  reducer: {
    policyBundles: policyBundlesReducer,
    payoutAccount: payoutAccountReducer,
    user: userReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
};

export const store = configureStore(storeConfig);

export type RootState = ReturnType<typeof store.getState>;

export const actions = {
  ...policyBundlesActions,
  ...payoutAccountActions,
  ...userActions,
};

const defaultPayoutAccount = createSelector(
  (state: RootState): RootState => state,
  (state) => {
    return (
      state.payoutAccount.payoutAccounts.find(
        (payoutAccount) => payoutAccount.isDefault,
      ) || null
    );
  },
);

const selectedPolicyBundle = createSelector(
  (state: RootState): RootState => state,
  (state) => {
    return state.policyBundles.policyBundles.find(
      (bundle) => bundle.id === state.policyBundles.selectedPolicyBundleID,
    );
  },
);

export const selectors = {
  defaultPayoutAccount,
  selectedPolicyBundle: selectedPolicyBundle,
};

export type AppDispatch = typeof store.dispatch;
