/**
 * @function booleanValidator
 * @description Validates that a variable is present, is a boolean, and satisfies any optional constraints.
 * Does not check that constraints are strictly compatible - throws an error at the first failed validation encountered.
 */

import { ValidationError } from '../error';

export function booleanValidator(
  /** The variable value to validate */
  value: unknown,
  /** The variable name for error message purposes */
  name: string,
  /** Optional parameters to validate against */
  opts?: {
    /** Pass equalTo to require value being either true or false */
    equalTo?: boolean;
    /** Error message to display if equalTo triggers */
    equalToErrorMsg?: string;
    /** Pass errorMsg to supply a catch-all error message on failed validation */
    errorMsg?: string;
  },
): asserts value is boolean {
  // Variable must exist
  if (typeof value === 'undefined') {
    throw new ValidationError(value, name, ['value is undefined']);
  }

  // Variable must be a boolean
  if (typeof value !== 'boolean') {
    throw new ValidationError(value, name, [
      `value is not of type boolean, received type ${typeof value}`,
    ]);
  }

  // Handle equalTo
  if (typeof opts?.equalTo === 'boolean' && value !== opts.equalTo) {
    const equalToError =
      opts.equalToErrorMsg ||
      `value is not equal to ${opts.equalTo.toString()}`;
    throw new ValidationError(value, name, [equalToError]);
  }
}
