import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageProps } from '../Page.types';
import { useActions, useStore } from '../../store/hooks';
import { selectors } from '../../store/store';
import { Claims } from '../../containers';
import Footer from '../../components/Footer/Footer';

const TripDetails: React.FunctionComponent<PageProps> = ({
  layout: Layout,
  navigation: Navigation,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const dispatch = useActions();

  const store = useStore();
  const {
    policyBundles: { policyBundles, ready: policyBundlesReady },
  } = store;
  const selectedPolicyBundle = selectors.selectedPolicyBundle(store);

  useEffect(() => {
    if (!policyBundlesReady) {
      return;
    }
    const pathElements = location.pathname.split('/');
    const pathSlug = pathElements[pathElements.length - 1];

    if (selectedPolicyBundle?.slug === pathSlug) {
      setLoading(false);

      return;
    }

    const currentPolicyBundle = policyBundles.find(
      (ue) => ue.slug === pathSlug,
    );

    // TODO: Redirect the user if there isn't a valid experience (since we probably have a bad slug)
    if (currentPolicyBundle) {
      dispatch.setSelectedPolicyBundle(currentPolicyBundle.id);
    }

    setLoading(false);
  }, [
    location.pathname,
    policyBundlesReady,
    policyBundles,
    selectedPolicyBundle,
    dispatch,
  ]);

  if (loading || !selectedPolicyBundle) return <></>;

  return (
    <Layout navigation={<Navigation />} footer={<Footer />}>
      <Claims policyBundle={selectedPolicyBundle} />;
    </Layout>
  );
};

export default TripDetails;
