import { PayoutProvider } from '../../utils/types/Entity';
import AddACHAccountContainer from '../../containers/AddACHAccountContainer/AddACHAccountContainer';
import AddPayPalAccountContainer from '../../containers/AddPayPalAccountContainer/AddPayPalAccountContainer';
import { AddPayoutProviderAccountComponentProps } from './AddPayoutProviderAccountComponent.types';

const AddPayoutProviderAccountComponent: React.FC<
  AddPayoutProviderAccountComponentProps
> = ({ provider, onBackClick, onAccountCreationSuccess }): JSX.Element => {
  return (
    <>
      {
        {
          [PayoutProvider.ACH]: (
            <AddACHAccountContainer
              onBackClick={onBackClick}
              onAccountCreationSuccess={onAccountCreationSuccess}
            />
          ),
          [PayoutProvider.PayPal]: (
            <AddPayPalAccountContainer
              onBackClick={onBackClick}
              onAccountCreationSuccess={onAccountCreationSuccess}
            />
          ),
        }[provider]
      }
    </>
  );
};

export default AddPayoutProviderAccountComponent;
