import { BlankCanvasBlockProps } from './BlankCanvasBlock.types';
import BaseLayoutDisplay from '../base/BaseLayoutDisplay/BaseLayoutDisplay';

/**
 * @description
 * The `BlankCanvasBlock` should be rendered as an immediate child to the `BlankCanvasLayout`. This component
 * wraps the base layout component used to create our switch color block layouts, and accepts a `sand` or `snow`
 * color variant prop.
 *
 * When used with the the `BlankCanvasLayout`, layouts can be created that dynamically render the desired color
 * block pattern based on specific page details, such as deeply nested children or the conditional rendering of children.
 */
export const BlankCanvasBlock: React.FC<BlankCanvasBlockProps> = ({
  children,
  colorVariant,
  first = false,
  last = false,
}) => {
  return (
    <BaseLayoutDisplay
      colorTheme={colorVariant}
      displayContent={<>{children}</>}
      first={first}
      last={last}
    />
  );
};

export default BlankCanvasBlock;
