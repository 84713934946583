import axios, { AxiosResponse } from 'axios';
import { err, auth } from '../utils';
import { type PayoutAccount, PayoutProvider } from '../utils/types/Entity';

const URL = '/api/payout-account';

const getPayoutAccounts = async (token: string): Promise<PayoutAccount[]> => {
  try {
    const res: AxiosResponse<{ payoutAccounts: PayoutAccount[] }> =
      await axios.get(URL, {
        headers: auth.bearerAuthHeader(token),
      });
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }

    return res.data.payoutAccounts;
  } catch (e) {
    throw err.handleError(e, 'error getting payout accounts');
  }
};

const changeDefaultStatus = async (
  token: string,
  payoutAccountID: string,
  isDefault: boolean,
): Promise<void> => {
  try {
    const res: AxiosResponse<void> = await axios.patch(
      `${URL}/${payoutAccountID}/default?is_default=${isDefault.toString()}`,
      {},
      {
        headers: auth.bearerAuthHeader(token),
      },
    );
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
  } catch (e) {
    throw err.handleError(e, 'error updating account');
  }
};

const createPayoutAccount = async (
  token: string,
  provider: PayoutProvider,
  options: {
    providerCustomerID?: string;
    providerCustomerAccountID?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
  },
): Promise<PayoutAccount> => {
  try {
    const res: AxiosResponse<{ payoutAccount: PayoutAccount }> =
      await axios.post(
        URL,
        {
          provider,
          options,
        },
        { headers: auth.bearerAuthHeader(token) },
      );

    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }

    return res.data.payoutAccount;
  } catch (e) {
    throw err.handleError(e, 'error creating account');
  }
};

const deletePayoutAccount = async (
  token: string,
  payoutAccountID: string,
  provider: string,
  providerCustomerID: string,
): Promise<void> => {
  try {
    const res: AxiosResponse<void> = await axios.patch(
      `${URL}/${payoutAccountID}/delete`,
      { provider, providerCustomerID },
      { headers: auth.bearerAuthHeader(token) },
    );
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
  } catch (e) {
    throw err.handleError(e, 'error deleting account');
  }
};

const generateProviderToken = async (
  token: string,
  provider: string,
  providerCustomerID: string,
): Promise<{ token: string }> => {
  try {
    const res: AxiosResponse<{ token: string }> = await axios.post(
      `${URL}/provider/token`,
      {
        provider,
        options: {
          providerCustomerID,
        },
      },
      { headers: auth.bearerAuthHeader(token) },
    );
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
    return res.data;
  } catch (e) {
    throw err.handleError(e, 'error deleting account');
  }
};

const getPayoutAccountByProvider = async (
  token: string,
  provider: PayoutProvider,
  requireValid: boolean,
): Promise<PayoutAccount> => {
  try {
    const res: AxiosResponse<PayoutAccount> = await axios.get(
      `${URL}/provider/${provider.toLowerCase()}?require_valid=${requireValid.toString()}`,
      {
        headers: auth.bearerAuthHeader(token),
      },
    );
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
    return res.data;
  } catch (e) {
    throw err.handleError(e, 'error getting account by provider');
  }
};

export default {
  getPayoutAccounts,
  changeDefaultStatus,
  createPayoutAccount,
  deletePayoutAccount,
  generateProviderToken,
  getPayoutAccountByProvider,
};
