import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgHappyUser = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 70 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M34.9263 94.9263C54.1846 94.9263 69.8525 79.2583 69.8525 60C69.8525 40.7417 54.1846 25.0737 34.9263 25.0737C15.668 25.0737 0 40.7417 0 60C0 79.2583 15.668 94.9263 34.9263 94.9263ZM34.9263 28.0737C52.5308 28.0737 66.8525 42.3955 66.8525 60C66.8525 77.6045 52.5308 91.9263 34.9263 91.9263C17.3218 91.9263 3 77.6045 3 60C3 42.3955 17.3218 28.0737 34.9263 28.0737Z"
      fill="black"
    />
    <path
      d="M15.6938 54.4487C16.5061 54.5667 17.2729 53.9939 17.3879 53.1721C17.667 51.1887 19.3882 49.6924 21.3913 49.6924C23.3938 49.6924 25.115 51.1887 25.394 53.1721C25.4995 53.9221 26.1418 54.4634 26.8772 54.4634C26.9468 54.4634 27.0178 54.459 27.0881 54.4487C27.9092 54.333 28.4805 53.575 28.3647 52.7546C27.8784 49.2983 24.8806 46.6924 21.3913 46.6924C17.9021 46.6924 14.9035 49.2983 14.4172 52.7546C14.3015 53.575 14.8735 54.333 15.6938 54.4487Z"
      fill="black"
    />
    <path
      d="M42.7642 54.4487C43.572 54.5667 44.3425 53.9939 44.4583 53.1721C44.7373 51.1887 46.4585 49.6924 48.4609 49.6924C50.4641 49.6924 52.1853 51.1887 52.4644 53.1721C52.5698 53.9221 53.2122 54.4634 53.9475 54.4634C54.0171 54.4634 54.0881 54.459 54.1584 54.4487C54.9788 54.333 55.5508 53.575 55.4351 52.7546C54.9487 49.2983 51.9502 46.6924 48.4609 46.6924C44.9717 46.6924 41.9739 49.2983 41.4875 52.7546C41.3718 53.575 41.9431 54.333 42.7642 54.4487Z"
      fill="black"
    />
    <path
      d="M17.9062 64.1396C17.9062 73.5242 25.541 81.1589 34.9255 81.1589C44.3101 81.1589 51.9448 73.5242 51.9448 64.1396C51.9448 63.3113 51.2732 62.6396 50.4448 62.6396H19.4062C18.5779 62.6396 17.9062 63.3113 17.9062 64.1396ZM48.865 65.6396C48.1143 72.6672 42.1494 78.1589 34.9255 78.1589C27.7017 78.1589 21.7368 72.6672 20.9861 65.6396H48.865Z"
      fill="black"
    />
    <path
      d="M36.4258 12.8401V1.5C36.4258 0.671631 35.7542 0 34.9258 0C34.0974 0 33.4258 0.671631 33.4258 1.5V12.8401C33.4258 13.6685 34.0974 14.3401 34.9258 14.3401C35.7542 14.3401 36.4258 13.6685 36.4258 12.8401Z"
      fill="black"
    />
    <path
      d="M58.2857 17.8123C58.8307 17.8123 59.3558 17.5149 59.6217 16.9971L64.7984 6.90727C65.1764 6.17046 64.8856 5.26592 64.1488 4.88799C63.4112 4.50786 62.5074 4.80229 62.1295 5.53765L56.9527 15.6275C56.5748 16.3643 56.8656 17.2688 57.6024 17.6468C57.8214 17.7596 58.0557 17.8123 58.2857 17.8123Z"
      fill="black"
    />
    <path
      d="M11.567 17.8126C11.797 17.8126 12.0313 17.7598 12.2503 17.647C12.9871 17.2691 13.2779 16.3646 12.9 15.6278L7.72323 5.53791C7.34457 4.80109 6.44076 4.50593 5.70394 4.88825C4.96713 5.26618 4.67635 6.17072 5.05428 6.90754L10.231 16.9974C10.4969 17.5152 11.0221 17.8126 11.567 17.8126Z"
      fill="black"
    />
    <path
      d="M33.4258 107.16V118.5C33.4258 119.329 34.0974 120 34.9258 120C35.7542 120 36.4258 119.329 36.4258 118.5V107.16C36.4258 106.332 35.7542 105.66 34.9258 105.66C34.0974 105.66 33.4258 106.332 33.4258 107.16Z"
      fill="black"
    />
    <path
      d="M12.2503 102.353C11.515 101.974 10.6097 102.267 10.231 103.003L5.05428 113.093C4.67635 113.829 4.96713 114.734 5.70394 115.112C5.92294 115.225 6.15731 115.277 6.38729 115.277C6.93221 115.277 7.45736 114.98 7.72323 114.462L12.9 104.372C13.2779 103.636 12.9871 102.731 12.2503 102.353Z"
      fill="black"
    />
    <path
      d="M57.6024 102.353C56.8656 102.731 56.5748 103.636 56.9527 104.372L62.1295 114.462C62.3953 114.98 62.9205 115.277 63.4654 115.277C63.6954 115.277 63.9298 115.225 64.1488 115.112C64.8856 114.734 65.1764 113.829 64.7984 113.093L59.6217 103.003C59.2423 102.266 58.3399 101.971 57.6024 102.353Z"
      fill="black"
    />
  </svg>
);

export default SvgHappyUser;
