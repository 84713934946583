import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import format from '../../utils/format';
import { useAuth0 } from '@auth0/auth0-react';
import ComponentsNavigation from '../../sharedComponents/Navigation/Navigation';
import UserAuthButton from '../../sharedComponents/UserAuthButton/UserAuthButton';
import { assets } from '../../sharedComponents/assets';
import { UserLanguageSelector } from '../UserLanguageSelector/UserLanguageSelector';
import useUserLanguageConfig from '../../hooks/useUserLanguageConfig';
import { NavigationProps } from './Navigation.types';

const Navigation: React.FC<NavigationProps> = ({ isDisabled = false }) => {
  const LangGlobe = assets.svg.icons.LangGlobe;
  const location = useLocation();

  const { t } = useTranslation();

  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { supportedLanguages } = useUserLanguageConfig();

  const navConfig = [
    {
      path: '/trips',
      name: t('sunshine.action.my_trips'),
      private: true,
    },
    {
      path: '/profile',
      name: t('sunshine.action.profile'),
      private: true,
    },
    {
      path: '/payout-management',
      name: t('sunshine.action.payout_accounts'),
      private: true,
    },
  ];

  const navItems: { element: JSX.Element; path: string }[] = [];

  navConfig.forEach((r) => {
    const renderPrivateNavItem = isAuthenticated && r.private;
    const renderPublicNavItem = !isAuthenticated && !r.private;

    if (renderPrivateNavItem || renderPublicNavItem) {
      navItems.push({
        element: <Link to={r.path}>{format.toTitleCase(r.name)}</Link>,
        path: r.path,
      });
    }
  });

  const logoLinkWrapper = (logo: JSX.Element): JSX.Element => {
    return <Link to="/trips">{logo}</Link>;
  };

  return (
    <ComponentsNavigation
      items={navItems}
      currentPath={location.pathname}
      logoLinkWrapper={logoLinkWrapper}
      langSelector={
        <UserLanguageSelector
          icon={<LangGlobe />}
          options={supportedLanguages}
        />
      }
      authButton={
        <UserAuthButton
          signInOnClick={(): void => void loginWithRedirect()}
          signOutOnClick={(): void =>
            logout({ returnTo: window.location.origin + `?logout=true` })
          }
          isSignedIn={isAuthenticated}
          signInLabel={t('action.signin')}
          signOutLabel={t('action.signout')}
          isDisabled={isDisabled}
        />
      }
    />
  );
};

export default Navigation;
