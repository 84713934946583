import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSensibleBadge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 245 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M244.618 82.1873C244.618 119.988 211.072 150.631 169.69 150.631C128.308 150.631 94.7617 119.988 94.7617 82.1873C94.7617 44.3866 128.308 13.7432 169.69 13.7432C211.072 13.7432 244.618 44.3866 244.618 82.1873Z"
      fill="#FFE500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5135 10.8642L10.5135 86.1494C10.5134 127.728 44.362 161.435 86.1163 161.435C127.871 161.435 161.719 127.728 161.719 86.1494C161.719 44.5705 127.871 10.8642 86.1164 10.8642L10.5135 10.8642ZM86.1163 171.524C133.467 171.524 171.852 133.301 171.852 86.1494C171.852 38.9981 133.467 0.774426 86.1164 0.774422L0.381103 0.774414L0.381096 86.1494C0.381092 133.301 38.7661 171.524 86.1163 171.524Z"
      fill="#00FFE0"
    />
    <path
      d="M4.7041 86.8575V0.774414L97.7469 6.51328L132.638 23.7299L154.841 40.9465L167.529 86.8575L154.841 132.768L121.008 161.463L80.3014 167.202L33.78 153.989L10.5193 109.146L4.7041 86.8575Z"
      fill="#00FFE0"
    />
  </svg>
);

export default SvgSensibleBadge;
