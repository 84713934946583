import i18next from 'i18next';
import * as dateFNS from 'date-fns';
import sensibleDate from '../utils/types/SensibleDate';

export const translate_wo_hooks = (
  t: string,
  args?: {
    val: Date;
    formatParams: {
      val: {
        month: string;
        day: string;
        year?: string;
      };
    };
  },
): string => {
  return i18next.t(t, { ...args });
};

export const i18nformatDateRangeLong = (
  startDate: string,
  endDate: string,
): string => {
  const start = sensibleDate.parseRFC(startDate);
  const end = sensibleDate.parseRFC(endDate);

  if (!sensibleDate.isValid(start) || !sensibleDate.isValid(end)) {
    return '';
  }
  const spansDays = dateFNS.getDate(start) !== dateFNS.getDate(end);
  const spansMonths = dateFNS.getMonth(start) !== dateFNS.getMonth(end);
  const spansYears = dateFNS.getYear(start) !== dateFNS.getYear(end);

  if (spansYears) {
    const startingDate = translate_wo_hooks('formatter.date', {
      val: start,
      formatParams: {
        val: { month: 'short', day: 'numeric', year: 'numeric' },
      },
    });

    const endingDate = translate_wo_hooks('formatter.date', {
      val: end,
      formatParams: {
        val: {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        },
      },
    });
    return `${startingDate} - ${endingDate}`;
  }

  if (spansMonths) {
    const startingDate = translate_wo_hooks('formatter.date', {
      val: start,
      formatParams: {
        val: {
          month: 'short',
          day: 'numeric',
        },
      },
    });

    const endingDate = translate_wo_hooks('formatter.date', {
      val: end,
      formatParams: {
        val: {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        },
      },
    });
    return `${startingDate} - ${endingDate} `;
  }

  if (spansDays) {
    const format = new Intl.DateTimeFormat(i18next.language, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    return format.formatRange(start, end);
  } else {
    return translate_wo_hooks('formatter.date', {
      val: start,
      formatParams: {
        val: {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        },
      },
    });
  }
};
