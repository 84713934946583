import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageProps } from '../Page.types';
import { useStore } from '../../store/hooks';
import { default as helpers } from '../../utils/helpers/helpers';
import { type PolicyBundle } from '../../utils/types/Entity';
import { claimIsExpired, getPolicyBundlesWithOpenClaims } from '../../utils';
import Footer from '../../components/Footer/Footer';

const Notification: React.FunctionComponent<PageProps> = ({
  layout: Layout,
  navigation: Navigation,
}) => {
  const navigate = useNavigate();

  const {
    policyBundles: { policyBundles },
  } = useStore();

  useEffect(() => {
    let notificationPolicyBundle: PolicyBundle | undefined;

    const policyHasExpiredClaim = (bun: PolicyBundle): boolean => {
      return bun.policies.some((p) => {
        return p.exposures.some((e) => {
          return e.claims.some(claimIsExpired);
        });
      });
    };

    const navigateToSlug = (slug: string): void => {
      return navigate(`/trips/${slug}`);
    };

    notificationPolicyBundle = policyBundles.find((ue) =>
      ue.policies.some((p) => p.isActive),
    );

    if (
      notificationPolicyBundle &&
      !policyHasExpiredClaim(notificationPolicyBundle)
    ) {
      return navigateToSlug(notificationPolicyBundle.slug);
    }

    const policyBundlesWithOpenClaims =
      getPolicyBundlesWithOpenClaims(policyBundles);

    const filteredClaims = policyBundlesWithOpenClaims.filter((b) => {
      return !policyHasExpiredClaim(b);
    });

    if (filteredClaims.length) {
      helpers.sortByKey(filteredClaims, 'coverageEndDate', true);
      notificationPolicyBundle = filteredClaims[0];

      return navigateToSlug(notificationPolicyBundle.slug);
    }

    if (!notificationPolicyBundle) {
      return navigate('/trips');
    }
  }, [navigate, policyBundles]);

  /* 
    This is the logic we can use when protect provides slug and uses in link
  */
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const id = params.get('id');

  //   const notificationPolicyBundle = policyBundles.find((ue) => ue.slug === id);

  //   if (notificationPolicyBundle) {
  //     navigate(`/trips/${notificationPolicyBundle.slug}`);
  //   } else {
  //     navigate('/trips');
  //   }
  // }, [policyBundles]);

  return (
    <Layout navigation={<Navigation />} footer={<Footer />}>
      <div></div>
    </Layout>
  );
};

export default Notification;
