import { type Policy } from '../types/Entity';

/**
 * @description
 * Given a `policy`, returns the cumulative number of losses recorded.
 *
 * It deconstructs the `losses` field from the first `peril` on the first `exposure`. Currently,
 * a `policy` can only have one `exposure` and one `peril`, so we can safely deconstruct this
 * field without iterating over the entirety of the `exposures` and `perils` fields. The recorded
 * `amount` of each loss is summed and returned.
 */
const totalLossesForPolicy = (policy: Policy): number => {
  const [
    {
      perils: [{ losses }],
    },
  ] = policy.exposures;

  return losses.map((l) => l.amount).reduce((acc, a) => acc + a, 0);
};

export default {
  totalLossesForPolicy,
};
