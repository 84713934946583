import { useTranslation } from 'react-i18next';
import {
  ClaimStatus,
  type Claim,
  type PolicyBundle,
} from '../../utils/types/Entity';
import { useEffect, useState } from 'react';
import Typography from '../../sharedComponents/Typography/Typography';
import { ClaimsProps } from './Claims.types';
import OpenClaims from '../OpenClaims/OpenClaims';
import ProcessingClaims from '../ProcessingClaims/ProcessingClaims';
import { ClaimStatusWrapperComponent } from '../../components';

const bundleClaimsByStatus = (
  b: PolicyBundle,
): Record<ClaimStatus, Claim[]> => {
  const claims: Record<ClaimStatus, Claim[]> = {
    [ClaimStatus.Open]: [],
    [ClaimStatus.Processing]: [],
    [ClaimStatus.Paid]: [],
    [ClaimStatus.Expired]: [],
    [ClaimStatus.Canceled]: [],
  };

  b.policies.forEach((policy) => {
    policy.exposures.forEach((exposure) => {
      exposure.claims.forEach((claim) => {
        claims[claim.status].push(claim);
      });
    });
  });

  return claims;
};

const Claims: React.FC<ClaimsProps> = ({ policyBundle }): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [openClaims, setOpenClaims] = useState<Claim[]>([]);
  const [processingClaims, setProcessingClaims] = useState<Claim[]>([]);
  const [paidClaims, setPaidClaims] = useState<Claim[]>([]);
  // const [expiredClaims, setExpiredClaims] = useState<Claim[]>([]);
  // const [canceledClaims, setCanceledClaims] = useState<Claim[]>([]);

  useEffect(() => {
    const claims = bundleClaimsByStatus(policyBundle);
    const hasClaims = Object.values(claims).some((c) => c.length > 0);

    if (hasClaims) {
      setOpenClaims(claims.open);
      setProcessingClaims(claims.processing);
      setPaidClaims(claims.paid);
      // setExpiredClaims(claims.expired);
      // setCanceledClaims(claims.canceled);
    }

    setLoading(false);
  }, [policyBundle]);

  if (loading) {
    return <div></div>;
  }

  const hasOpenClaims = openClaims.length > 0;
  const hasProcessingClaims = processingClaims.length > 0;
  const hasPaidClaims = paidClaims.length > 0;
  // const hasExpiredClaims = expiredClaims.length > 0;
  // const hasCanceledClaims = canceledClaims.length > 0;

  if (hasOpenClaims) {
    return (
      <OpenClaims
        policyBundle={policyBundle}
        openClaims={openClaims}
        currency={policyBundle.currency}
      />
    );
  }

  if (hasProcessingClaims) {
    return (
      <ProcessingClaims
        policyBundle={policyBundle}
        processingClaims={processingClaims}
      />
    );
  }

  if (hasPaidClaims) {
    return (
      <ClaimStatusWrapperComponent policyBundle={policyBundle}>
        <Typography variant="body-1">
          {t('sunshine.content.reimbursement_balance.payouts_completed')}
        </Typography>
      </ClaimStatusWrapperComponent>
    );
  }

  // if (hasExpiredClaims) {
  //   return (
  //     <Typography variant="body-1">
  //       The reimbursement window has expired. Please reach out to customer
  //       support for additional help.
  //     </Typography>
  //   );
  // }

  // if (hasCanceledClaims) {
  //   return (
  //     <Typography variant="body-1">
  //       The reimbursement was canceled. Please reach out to customer support
  //       for additional help.
  //     </Typography>
  //   );
  // }

  return <ClaimStatusWrapperComponent policyBundle={policyBundle} />;
};

export default Claims;
