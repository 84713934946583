import { FC } from 'react';
import { BaseCheckboxInputProps } from './BaseCheckboxInput.types';
import styles from './base-checkbox-input.module.scss';
import Typography from '../../Typography/Typography';

const BaseCheckboxInput: FC<BaseCheckboxInputProps> = ({
  checked,
  value,
  label,
  onChangeHandler,
}) => {
  return (
    <div
      key={label}
      className={`${styles.container} ${checked ? styles.checked : ''}`}
    >
      <label htmlFor={value}>
        <input
          type="checkbox"
          id={value}
          name={value}
          checked={checked}
          onClick={onChangeHandler}
        />
        <Typography variant="label-3">{label}</Typography>
      </label>
    </div>
  );
};

export default BaseCheckboxInput;
