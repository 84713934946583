import { FC, useState } from 'react';
import { NavigationProps } from './Navigation.types';
import NavigationItem from '../NavigationItem/NavigationItem';
import { SensibleLogo } from '../assets/png/sensible';
import styles from './navigation.module.scss';

const Navigation: FC<NavigationProps> = ({
  items,
  authButton: AuthButton,
  currentPath,
  logoLinkWrapper,
  langSelector: LangSelector,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const renderNavItems = (): JSX.Element[] => {
    const navItems: JSX.Element[] = [];

    items.forEach((item) =>
      navItems.push(
        <NavigationItem key={item.path} currentPath={item.path === currentPath}>
          {item.element}
        </NavigationItem>,
      ),
    );

    return navItems;
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.logoLink}>
          {logoLinkWrapper(
            <img
              src={SensibleLogo}
              className={styles.logo}
              alt="Sensible Weather logo"
            />,
          )}
        </div>
        {LangSelector && (
          <div className={styles.langSelectorMobile}>{LangSelector}</div>
        )}
        <button
          className={`${styles.mobileMenuIcon} ${
            openMenu ? styles.openMenu : ''
          }`}
          onClick={(): void => setOpenMenu(!openMenu)}
          type="button"
          aria-label="Toggle Navigation"
        >
          <span className={`${styles.dot} ${styles.firstDot}`}></span>
          <span className={`${styles.dot} ${styles.secondDot}`}></span>
          <span className={`${styles.dot} ${styles.thirdDot}`}></span>
        </button>
      </div>

      <div
        className={`${styles.content} ${
          openMenu ? styles.openMenu : styles.closeMenu
        }`}
      >
        <div className={styles.items}>{renderNavItems()}</div>
        {LangSelector && (
          <div className={styles.langSelectorDesktop}>{LangSelector}</div>
        )}
        {AuthButton && <div className={styles.authButton}>{AuthButton}</div>}
      </div>
    </div>
  );
};

export default Navigation;
