/**
 * @description
 * Given an array of objects and a `sortBy` key, sorts the objects by values ascending, unless `reversed` is passed in which case, sorts by values descending.
 *
 * @example helpers.sortByKey([{ a: 2 },{ a:1 }], 'a') === [ {a: 1 },{ a:2 }];
 * @example helpers.sortByKey([{ a: 1 },{ a:2 }], 'a', true) === [{ a: 2 },{ a:1 }]
 */
const sortByKey = <T, K extends keyof T>(
  arr: T[],
  sortBy: K,
  reversed = false,
): T[] => {
  return arr.slice().sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      if (reversed) return 1;

      return -1;
    }

    if (a[sortBy] > b[sortBy]) {
      if (reversed) return -1;

      return 1;
    }

    return 0;
  });
};

export default { sortByKey };
