import { useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PayoutProvider } from '../../utils/types/Entity';
import {
  AddACHAccountContainerProps,
  achCustomerAccount,
} from './AddACHAccountContainer.types';
import AddPayoutAccountBaseModule from '../AddPayoutAccountBaseModule/AddPayoutAccountBaseModule';
import AddPayoutAccountACHForm from '../../components/AddPayoutAccountACHForm/AddPayoutAccountACHForm';
import { ACHMaintenanceNotice } from '../../components';
import { ConfigContext } from '../../contexts';
import { PayoutAccountAPI } from '../../api';
import { useActions, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';

const PROVIDER = PayoutProvider.ACH;
const FORM_ID = 'add_ach_form';

interface DwollaSuccessfulResponse {
  location: string; // "https://api-sandbox.dwolla.com/funding-sources/eb0dfd37-c92b-49c1-ac8c-e9d75c34c7a9"
}
function isDwollaSuccessfulResponse(
  val: unknown,
): val is DwollaSuccessfulResponse {
  if (!val || typeof val !== 'object') {
    return false;
  }

  return Object.hasOwn(val, 'location');
}

interface DwollaErrorResponse {
  code: string; // ValidationError
  message: string; // "Validation error(s) present. See embedded errors list for more details."
  _embedded: {
    code: string; // "InvalidFormat"
    message: string; // "Routing number must be exactly 9 characters."
    path: string; // "/routingNumber"
    _links: Record<string, unknown>;
  }[];
}

interface DwollaResponseBody {
  resource: string;
  response: DwollaSuccessfulResponse | DwollaErrorResponse;
}

const AddACHAccountContainer: React.FC<AddACHAccountContainerProps> = ({
  onAccountCreationSuccess,
  onBackClick,
}): JSX.Element => {
  const { t } = useTranslation();
  const configEnvelope = useContext(ConfigContext);

  const [customerAccount, setCustomerAccount] = useState<achCustomerAccount>();
  const [fetchCustomerError, setFetchCustomerError] = useState('');
  const [loadDwollaError, setLoadDwollaError] = useState('');
  const [generateFundingSourceTokenError, setGenerateFundingSourceTokenError] =
    useState('');

  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useActions();

  const { payoutAccounts } = useAppSelector((state) => state.payoutAccount);
  const { user } = useAppSelector((state) => state.user);

  const dwollaDisabled = configEnvelope.config?.dwollaDisabled;

  useEffect(() => {
    const fetchCustomerAccount = async (): Promise<void> => {
      setFetchCustomerError('');

      try {
        const accessToken = await getAccessTokenSilently();
        const requireCustomerAccountToBeValid = false;

        let _customerAccount =
          await PayoutAccountAPI.getPayoutAccountByProvider(
            accessToken,
            PROVIDER,
            requireCustomerAccountToBeValid,
          );

        if (!_customerAccount) {
          const [firstName, ...succeedingNames] = user.name.split(' ');
          const lastName = succeedingNames.join(' ');

          _customerAccount = await PayoutAccountAPI.createPayoutAccount(
            accessToken,
            PROVIDER,
            {
              firstName,
              lastName,
              email: user.email,
            },
          );
        }

        setCustomerAccount(_customerAccount);
      } catch (e) {
        setFetchCustomerError(t('error.unable_to_create_ach_customer'));
      }
    };

    void fetchCustomerAccount();
  }, [getAccessTokenSilently, t, user.email, user.name]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
  const { dwolla } = window as unknown as { dwolla: any };
  const [dwollaReady, setDwollaReady] = useState(false);
  useEffect(() => {
    if (
      configEnvelope.ready &&
      configEnvelope.config &&
      !dwollaReady &&
      customerAccount
    ) {
      const fetchToken = async (): Promise<{ token: string }> => {
        try {
          const accessToken = await getAccessTokenSilently();
          const { token } = await PayoutAccountAPI.generateProviderToken(
            accessToken,
            PROVIDER,
            customerAccount?.providerCustomerID || '',
          );

          return { token };
        } catch (e) {
          setGenerateFundingSourceTokenError(t('error.occured'));

          return { token: '' };
        }
      };

      const onSuccess = async ({
        response,
      }: DwollaResponseBody): Promise<void> => {
        if (!isDwollaSuccessfulResponse(response)) {
          return;
        }

        const accessToken = await getAccessTokenSilently();

        const newAccount = await PayoutAccountAPI.getPayoutAccountByProvider(
          accessToken,
          PROVIDER,
          true,
        );

        onAccountCreationSuccess(newAccount);
      };

      const { dwollaEnv } = configEnvelope.config;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      dwolla.configure({
        environment: dwollaEnv,
        styles: '/add-payout-account-ach-form.css',
        token: async () => await fetchToken(),
        success: async (res: DwollaResponseBody) => await onSuccess(res),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error: (_err: unknown) => {
          setLoadDwollaError(
            'Error loading ACH account form. Please refresh to try again.',
          );
        },
      });
      setDwollaReady(true);
    }
  }, [
    dwolla,
    dwollaReady,
    configEnvelope,
    getAccessTokenSilently,
    customerAccount,
    payoutAccounts,
    dispatch,
    onAccountCreationSuccess,
    t,
  ]);

  if (dwollaDisabled) {
    return <ACHMaintenanceNotice />;
  }

  if (!dwollaReady || !customerAccount || dwollaDisabled === undefined) {
    return <></>;
  }

  return (
    <AddPayoutAccountBaseModule
      step={0}
      provider={PayoutProvider.ACH}
      formReady={dwollaReady}
      formID={FORM_ID}
      processingRequest={false}
      errorMsg={
        fetchCustomerError || generateFundingSourceTokenError || loadDwollaError
      }
      resetErrorMsg={(): void => {
        return;
      }}
      exit={onBackClick}
      edit={(): void => {
        return;
      }}
      save={(): void => {
        return;
      }}
      disableSteps
    >
      <AddPayoutAccountACHForm
        providerCustomerID={customerAccount.providerCustomerID}
      />
    </AddPayoutAccountBaseModule>
  );
};

export default AddACHAccountContainer;
