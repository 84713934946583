import { AddPayoutAccountACHFormProps } from './AddPayoutAccountACHForm.types';

const AddPayoutAccountACHForm: React.FC<AddPayoutAccountACHFormProps> = ({
  providerCustomerID,
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <dwolla-funding-source-create customerId={providerCustomerID} />
  );
};

export default AddPayoutAccountACHForm;
