import { err } from '../utils';
import axios, { AxiosResponse } from 'axios';

const URL = '/api/auth';

const verifyUserEmail = async (
  token: string,
  langLocale: string,
): Promise<void> => {
  try {
    const res: AxiosResponse<Record<string, unknown>> = await axios.post(
      `${URL}/send-email-verification`,
      { langLocale },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
    return;
  } catch (e) {
    console.error(e);
    throw err.handleError(e, 'error sending email verification');
  }
};

const acceptTermsAndPolicies = async (token: string): Promise<void> => {
  try {
    const res: AxiosResponse<Record<string, unknown>> = await axios.patch(
      `${URL}/accept-terms-and-policies`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
    return;
  } catch (e) {
    throw err.handleError(e, 'error accepting terms and policies');
  }
};

const updateUserPassword = async (
  token: string,
  langLocale: string,
): Promise<number | void> => {
  try {
    const res: AxiosResponse<Record<string, unknown>> = await axios.post(
      `${URL}/update-password`,
      { langLocale },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
    return;
  } catch (e) {
    throw err.handleError(e, 'error requesting password update');
  }
};

export default {
  verifyUserEmail,
  updateUserPassword,
  acceptTermsAndPolicies,
};
