/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as dateFNS from 'date-fns';
import * as dateTZ from 'date-fns-tz';
import {
  type SensibleDate,
  type FormatString,
  UnitTime,
} from './SensibleDate.types';

const supportedFormats: FormatString[] = [
  'MM/dd/yy',
  'MM/dd/yyyy',
  'MM-dd-yy',
  'MM-dd-yyyy',
  'MMMM d',
  'MMMM d, yyyy',
  'MMM. d',
  'MMM. d, yyyy',
  'yyyy-MM-dd',
  'EEEE, MMM dd',
  'EEEE, MMM. dd',
  'EEEE, MMM dd, yyyy',
  'EEEE, MMM. dd, yyyy',
];

const RFC_FORMAT_S = "yyyy-MM-dd'T'HH:mm:ss'Z'";
const RFC_FORMAT_MS = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

const now = (): SensibleDate => {
  return new Date();
};

const getFormat = (str: string): FormatString | '' => {
  let match: FormatString | undefined;
  let i = 0;

  while (!match && i < supportedFormats.length) {
    if (dateFNS.isMatch(str, supportedFormats[i])) {
      match = supportedFormats[i];
    }

    i++;
  }

  return match || '';
};

const parse = (str: string): SensibleDate => {
  const f = getFormat(str);
  //@ts-ignore
  if (!f) return null;

  return dateFNS.parse(str, f, new Date());
};

const parseRFC = (str: string): SensibleDate => {
  //@ts-ignore
  if (typeof str !== 'string') return null;

  const isRFCSeconds = dateFNS.isMatch(str, RFC_FORMAT_S);
  const isRFCMilliseconds = dateFNS.isMatch(str, RFC_FORMAT_MS);

  const rfcEl = str.split('.');
  rfcEl.pop();
  const rfc = rfcEl[0] + 'Z';
  const isRFC = dateFNS.isMatch(rfc, RFC_FORMAT_S);

  if (!isRFCSeconds && !isRFCMilliseconds && !isRFC) {
    //@ts-ignore
    return null;
  }

  return dateTZ.toDate(str);
};

const isValid = (date: SensibleDate | string | null): boolean => {
  let d = date;

  if (typeof d === 'string') {
    d = parse(d);
  }

  return dateFNS.isValid(d);
};

const isAfter = (date: SensibleDate, dateToCompare: SensibleDate): boolean => {
  return dateFNS.isAfter(date, dateToCompare);
};

const isBefore = (date: SensibleDate, dateToCompare: SensibleDate): boolean => {
  return dateFNS.isBefore(date, dateToCompare);
};

const isEqual = (date: SensibleDate, dateToCompare: SensibleDate): boolean => {
  return dateFNS.isEqual(date, dateToCompare);
};

const isFuture = (date: SensibleDate): boolean => {
  return dateFNS.isFuture(date);
};

const isPast = (date: SensibleDate): boolean => {
  return dateFNS.isPast(date);
};

const format = (
  date: SensibleDate,
  formatString: FormatString,
  tz?: string,
): string => {
  if (!isValid(date)) return '';

  if (tz) return dateTZ.formatInTimeZone(date, tz, formatString);

  return dateFNS.format(date, formatString);
};

const add = (
  date: SensibleDate,
  unit: UnitTime,
  number: number,
): SensibleDate => {
  //@ts-ignore
  if (!isValid(date)) return null;

  const duration: { [key: string]: number } = {};
  duration[unit] = number;

  return dateFNS.add(date, duration);
};

const subtract = (
  date: SensibleDate,
  unit: UnitTime,
  number: number,
): SensibleDate => {
  //@ts-ignore
  if (!isValid(date)) return null;

  const duration: { [key: string]: number } = {};
  duration[unit] = number;

  return dateFNS.sub(date, duration);
};

const getUnixTime = (date: SensibleDate): number => {
  //@ts-ignore
  if (!isValid(date)) return null;

  return dateFNS.getUnixTime(date);
};

const numberOfDaysInRange = (
  start: SensibleDate,
  end: SensibleDate,
): number => {
  //@ts-ignore
  if (!isValid(start) || !isValid(end)) return null;

  /* + 1 at the end to make it "inclusive" of start and end dates */
  return Math.abs(dateFNS.differenceInCalendarDays(end, start)) + 1;
};

const millisecondsToDuration = (ms: number): dateFNS.Duration => {
  const interval: dateFNS.Interval = { start: 0, end: ms };

  const d = dateFNS.intervalToDuration(interval);

  return d;
};

const displayHour = (date: SensibleDate, tz?: string): string => {
  if (tz) {
    return dateTZ.formatInTimeZone(date, tz, 'H');
  }

  return dateFNS.format(date, 'H');
};

const militaryHourToAmPm = (hour: number): string => {
  if (hour === 0) {
    return '12am';
  }
  if (hour < 12) {
    return `${hour}am`;
  }

  if (hour === 12) {
    return `12pm`;
  }

  return `${hour - 12}pm`;
};

const displayAmPmHour = (date: SensibleDate, tz?: string): string => {
  const hour = displayHour(date, tz);
  const intHour = parseInt(hour, 10);

  if (intHour === 0) {
    return '12am';
  }

  if (intHour < 12) {
    return `${hour}am`;
  }

  if (intHour === 12) {
    return `${hour}pm`;
  }

  return `${intHour - 12}pm`;
};

const displayTimeZoneAbbreviation = (
  date: SensibleDate,
  tz: string,
): string => {
  return dateTZ.formatInTimeZone(date, tz, 'zzz');
};

export default {
  add,
  format,
  getFormat,
  getUnixTime,
  isValid,
  millisecondsToDuration,
  now,
  isAfter,
  isBefore,
  isEqual,
  isFuture,
  isPast,
  numberOfDaysInRange,
  subtract,
  parse,
  parseRFC,
  displayHour,
  militaryHourToAmPm,
  displayAmPmHour,
  displayTimeZoneAbbreviation,
};
