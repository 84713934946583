import { ValidationError } from '../../error';

export enum CustomerSupportCode {
  Unknown = 1,
  IncorrectForecast,
}

export function isCustomerSupportCode(
  value: unknown,
): asserts value is CustomerSupportCode {
  switch (value) {
    case CustomerSupportCode.Unknown:
    case CustomerSupportCode.IncorrectForecast:
      return;
    default:
      throw new ValidationError(value, 'customerSupportCode', [
        `error validating CustomerSupportCode: received ${JSON.stringify(
          value,
        )}`,
      ]);
  }
}

export const stringToCustomerSupportCode = (
  str: string,
): CustomerSupportCode => {
  if (typeof str !== 'string') return CustomerSupportCode.Unknown;

  switch (str.toLowerCase()) {
    case 'incorrect_forecast':
      return CustomerSupportCode.IncorrectForecast;
    default:
      return CustomerSupportCode.Unknown;
  }
};
