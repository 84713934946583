import BaseLayout from '../base/BaseLayout/BaseLayout';
import { FC, Children, isValidElement } from 'react';
import { LayoutProps } from '../types/layout.types';

const SandSwitchLayout: FC<LayoutProps> = ({
  children,
  navigation,
  footer,
}) => {
  const contentElements = Children.toArray(children);

  const layoutContent: {
    displayContent: JSX.Element;
    colorTheme: string;
  }[] = [];

  contentElements.forEach((ce, i) => {
    if (isValidElement(ce)) {
      layoutContent.push({
        displayContent: ce,
        colorTheme: i % 2 === 0 ? 'sand' : 'snow',
      });
    }
  });

  return (
    <BaseLayout
      layoutContent={layoutContent}
      navigation={navigation}
      footer={footer}
    />
  );
};

export default SandSwitchLayout;
