import { useTranslation } from 'react-i18next';
import { PayoutProvider } from '../../utils/types/Entity';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { PayoutAccountAPI } from '../../api';
import {
  AddPayoutAccountConfirmation,
  AddPayoutAccountPayPalForm,
} from '../../components';
import {
  AddPayPalAccountContainerProps,
  PAYPAL_ACCOUNT_TYPES,
} from './AddPayPalAccountContainer.types';
import AddPayoutAccountBaseModule from '../AddPayoutAccountBaseModule/AddPayoutAccountBaseModule';

const AddPayPalAccountContainer: React.FC<AddPayPalAccountContainerProps> = ({
  onBackClick,
  onAccountCreationSuccess,
}) => {
  const { t } = useTranslation();
  const { PHONE, EMAIL } = PAYPAL_ACCOUNT_TYPES;

  const [step, setStep] = useState<0 | 1>(0);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [createAccountError, setCreateAccountError] = useState('');

  const [formReady, setFormReady] = useState(false);

  const [accountID, setAccountID] = useState('');
  const [accountType, setAccountType] = useState<typeof PHONE | typeof EMAIL>(
    PHONE,
  );

  const PROVIDER = PayoutProvider.PayPal;

  const { getAccessTokenSilently } = useAuth0();

  const createAccount = async (): Promise<void> => {
    setProcessingRequest(true);
    setCreateAccountError('');
    try {
      const accessToken = await getAccessTokenSilently();

      const paypalAccount = await PayoutAccountAPI.createPayoutAccount(
        accessToken,
        PROVIDER,
        {
          providerCustomerID: accountID,
          providerCustomerAccountID: accountType,
        },
      );

      onAccountCreationSuccess(paypalAccount);
    } catch (e) {
      setCreateAccountError(t('error.saving_account'));
    } finally {
      setProcessingRequest(false);
    }
  };

  const FORM_ID = 'add_paypal_form';

  return (
    <AddPayoutAccountBaseModule
      step={step}
      provider={PROVIDER}
      formReady={formReady}
      formID={FORM_ID}
      processingRequest={processingRequest}
      errorMsg={createAccountError}
      resetErrorMsg={(): void => setCreateAccountError('')}
      exit={onBackClick}
      edit={(): void => setStep(0)}
      save={(): void => void createAccount()}
    >
      {step === 0 && (
        <AddPayoutAccountPayPalForm
          inputAccountID={accountID}
          accountType={accountType}
          setAccountType={setAccountType}
          setInputAccountID={setAccountID}
          onSubmit={(): void => setStep(1)}
          formID={FORM_ID}
          setFormReady={setFormReady}
        />
      )}

      {step === 1 && (
        <AddPayoutAccountConfirmation
          inputValues={[
            {
              label: t('label.account_type'),
              value: t(`label.${accountType.toLowerCase()}`),
            },
            {
              label: t('label.account_id'),
              value: accountID,
            },
          ]}
        />
      )}
    </AddPayoutAccountBaseModule>
  );
};

export default AddPayPalAccountContainer;
