import React, { useEffect, useRef } from 'react';
import { ModalProps } from './Modal.types';
import styles from './modal.module.scss';
import CloseButton from '../CloseButton/CloseButton';

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  isActive,
  onCloseHandler,
}) => {
  const overlayPinBoardRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const setOceanOverlay = (): void => {
      const overlay = document.createElement('div');

      overlay.onclick = onCloseHandler;
      overlay.className = styles.overlay;

      if (!overlayPinBoardRef.current) return;

      overlayPinBoardRef.current.append(overlay);
    };

    const removeOceanOverlay = (): void => {
      if (!overlayPinBoardRef.current) return;

      overlayPinBoardRef.current.innerHTML = '';
    };

    if (isActive) {
      setOceanOverlay();
      scroll({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      removeOceanOverlay();
    }
  }, [isActive]);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <div ref={overlayPinBoardRef} />
      <div
        className={`${styles.container} ${
          isActive ? styles.active : styles.inactive
        }`}
      >
        <div className={styles.headerWrapper}>
          {title}
          <CloseButton onClickHandler={onCloseHandler} />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

export default Modal;
