//is used in client
import { ValidationError } from '../../error';
import { stringValidator } from '../../validate';
import { CurrencyUnit } from '../Currency/Currency.types';

export enum PayoutProvider {
  PayPal = 'PayPal',
  ACH = 'ACH',
}

export function isPayoutProvider(
  value: unknown,
): asserts value is PayoutProvider {
  switch (value) {
    case PayoutProvider.PayPal:
    case PayoutProvider.ACH:
      return;
    default:
      throw new ValidationError(value, 'payoutProvider', [
        `error validating PayoutProvider, received ${JSON.stringify(value)}`,
      ]);
  }
}

export const stringToPayoutProvider = (str: string): PayoutProvider | null => {
  try {
    stringValidator(str, 'str');
  } catch (e) {
    return null;
  }

  switch (str.toLowerCase()) {
    case 'paypal':
      return PayoutProvider.PayPal;
    case 'dwolla':
    case 'ach':
      return PayoutProvider.ACH;
    default:
      return null;
  }
};

export const payoutProviderSupportedCurrencies = {
  [PayoutProvider.ACH]: [CurrencyUnit.USD],
  [PayoutProvider.PayPal]: [
    CurrencyUnit.USD,
    CurrencyUnit.GBP,
    CurrencyUnit.EUR,
    CurrencyUnit.CHF,
    CurrencyUnit.SEK,
  ],
};
