import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgExclamationMark = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={14.5} cy={14.5} r={14.5} fill="#181313" />
    <path
      d="M13.856 17.128C13.716 17.128 13.646 17.058 13.646 16.918L13.457 7.51C13.457 7.37 13.527 7.3 13.667 7.3H15.263C15.403 7.3 15.473 7.37 15.473 7.51L15.305 16.918C15.305 17.058 15.235 17.128 15.095 17.128H13.856ZM14.381 21.937C13.989 21.937 13.667 21.811 13.415 21.559C13.163 21.307 13.037 20.985 13.037 20.593C13.037 20.201 13.163 19.879 13.415 19.627C13.667 19.375 13.989 19.249 14.381 19.249C14.773 19.249 15.095 19.375 15.347 19.627C15.599 19.879 15.725 20.201 15.725 20.593C15.725 20.985 15.599 21.307 15.347 21.559C15.095 21.811 14.773 21.937 14.381 21.937Z"
      fill="white"
    />
  </svg>
);

export default SvgExclamationMark;
