import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSensibleLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 639 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M244.737 82.1843C244.737 119.985 211.191 150.628 169.809 150.628C128.427 150.628 94.8809 119.985 94.8809 82.1843C94.8809 44.3837 128.427 13.7402 169.809 13.7402C211.191 13.7402 244.737 44.3837 244.737 82.1843Z"
      fill="#FFE500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6326 10.8613L10.6326 86.1465C10.6326 127.725 44.4811 161.432 86.2355 161.432C127.99 161.432 161.838 127.725 161.838 86.1465C161.838 44.5676 127.99 10.8613 86.2355 10.8613L10.6326 10.8613ZM86.2355 171.521C133.586 171.521 171.971 133.298 171.971 86.1465C171.971 38.9952 133.586 0.771497 86.2355 0.771493L0.500249 0.771486L0.500242 86.1465C0.500238 133.298 38.8852 171.521 86.2355 171.521Z"
      fill="#00FFE0"
    />
    <path
      d="M4.82324 86.8545V0.771484L97.866 6.51035L132.757 23.727L154.96 40.9436L167.648 86.8545L154.96 132.765L121.127 161.46L80.4205 167.199L33.8991 153.986L10.6384 109.143L4.82324 86.8545Z"
      fill="#00FFE0"
    />
    <path
      d="M112.905 128.771C127.796 128.771 140.711 121.142 140.863 106.332C141.015 95.1124 133.57 88.8294 124.149 85.0895L113.665 81.0505C109.562 79.5545 106.523 77.4602 106.523 73.8699C106.523 70.7284 108.802 68.0356 113.665 68.0356C118.071 68.0356 122.326 70.13 127.188 74.1691C128.556 75.2162 129.619 75.3658 130.683 74.1691L137.368 66.2405C138.128 65.3429 138.432 64.1461 137.52 63.099C131.291 55.7688 122.326 51.8793 112.601 51.8793C98.7739 51.8793 86.6181 59.9575 86.6181 74.0195C86.6181 84.192 93.3038 90.3254 102.876 94.0653L112.297 97.8052C117.767 100.049 120.046 101.844 120.046 105.435C120.046 109.623 116.704 111.568 111.689 111.568C105.915 111.568 100.749 109.025 94.5194 104.387C93.3038 103.49 91.9363 103.191 90.7207 104.986L85.4025 112.316C84.3389 113.961 84.1869 115.607 85.0986 116.654C91.0246 123.536 100.445 128.771 112.905 128.771Z"
      fill="#0B1419"
    />
    <path
      d="M225.245 86.8847C225.245 68.3348 212.178 51.8793 189.537 51.8793C166.593 51.8793 151.247 68.4844 151.247 90.0262C151.247 112.166 167.657 128.771 192.121 128.771C202.605 128.771 212.178 125.78 218.559 119.497C219.927 118.3 220.079 117.253 219.319 116.205L213.697 108.576C212.937 107.529 212.178 107.379 211.114 107.978C205.036 111.418 199.566 112.466 193.792 112.466C181.636 112.466 174.191 107.379 171.912 98.1044H217.04C223.574 98.1044 225.245 94.0653 225.245 86.8847ZM189.689 68.9332C198.654 68.9332 205.34 73.7203 206.556 83.5936H171.608C173.279 73.8699 180.421 68.9332 189.689 68.9332Z"
      fill="#0B1419"
    />
    <path
      d="M282.786 51.8793C271.693 51.8793 264.552 57.2647 260.297 64.7445L259.69 56.068C259.69 54.2728 258.778 53.6744 257.41 53.6744H241.608C240.24 53.6744 239.329 54.572 239.329 55.9184V124.732C239.329 126.079 240.24 126.976 241.608 126.976H258.018C259.386 126.976 260.297 126.079 260.297 124.732V86.7351C260.297 76.413 266.071 70.8779 274.884 70.8779C283.849 70.8779 288.711 76.413 288.711 86.7351V124.732C288.711 126.079 289.623 126.976 290.991 126.976H307.249C308.617 126.976 309.68 126.079 309.68 124.732V82.0976C309.68 62.9494 298.436 51.8793 282.786 51.8793Z"
      fill="#0B1419"
    />
    <path
      d="M352.993 128.771C367.884 128.771 380.8 121.142 380.952 106.332C381.104 95.1124 373.658 88.8294 364.238 85.0895L353.753 81.0505C349.651 79.5545 346.612 77.4602 346.612 73.8699C346.612 70.7284 348.891 68.0356 353.753 68.0356C358.16 68.0356 362.414 70.13 367.277 74.1691C368.644 75.2162 369.708 75.3658 370.771 74.1691L377.457 66.2405C378.217 65.3429 378.521 64.1461 377.609 63.099C371.379 55.7688 362.414 51.8793 352.69 51.8793C338.862 51.8793 326.707 59.9575 326.707 74.0195C326.707 84.192 333.392 90.3254 342.965 94.0653L352.386 97.8052C357.856 100.049 360.135 101.844 360.135 105.435C360.135 109.623 356.792 111.568 351.778 111.568C346.004 111.568 340.838 109.025 334.608 104.387C333.392 103.49 332.025 103.191 330.809 104.986L325.491 112.316C324.427 113.961 324.275 115.607 325.187 116.654C331.113 123.536 340.534 128.771 352.993 128.771Z"
      fill="#0B1419"
    />
    <path
      d="M406.664 40.6596C413.35 40.6596 418.212 35.5734 418.212 29.1408C418.212 22.8577 413.35 17.7715 406.664 17.7715C399.978 17.7715 394.964 22.8577 394.964 29.1408C394.964 35.5734 399.978 40.6596 406.664 40.6596ZM398.307 126.976H414.869C416.237 126.976 417.148 126.079 417.148 124.732V55.9184C417.148 54.572 416.237 53.6744 414.869 53.6744H398.307C396.939 53.6744 396.028 54.572 396.028 55.9184V124.732C396.028 126.079 396.939 126.976 398.307 126.976Z"
      fill="#0B1419"
    />
    <path
      d="M479.563 51.8793C469.079 51.8793 461.481 55.7688 456.467 62.351V22.2594C456.467 20.913 455.555 20.0154 454.188 20.0154H437.626C436.258 20.0154 435.346 20.913 435.346 22.2594V124.732C435.346 126.079 436.41 126.976 437.778 126.976H452.668C454.036 126.976 454.948 126.079 454.948 124.732L455.252 116.654C460.418 124.134 468.319 128.771 479.563 128.771C500.38 128.771 514.815 112.166 514.815 90.3254C514.815 68.634 500.38 51.8793 479.563 51.8793ZM475.157 109.922C463.913 109.922 456.163 102.143 456.163 90.3254C456.163 78.5073 463.913 70.7284 475.157 70.7284C486.097 70.7284 494.302 78.6569 494.302 90.3254C494.302 102.143 486.097 109.922 475.157 109.922Z"
      fill="#0B1419"
    />
    <path
      d="M531.406 126.976H547.968C549.335 126.976 550.247 126.079 550.247 124.732V22.2594C550.247 20.913 549.335 20.0154 547.968 20.0154H531.406C530.038 20.0154 529.126 20.913 529.126 22.2594V124.732C529.126 126.079 530.038 126.976 531.406 126.976Z"
      fill="#0B1419"
    />
    <path
      d="M638.5 86.8847C638.5 68.3348 625.433 51.8793 602.792 51.8793C579.848 51.8793 564.502 68.4844 564.502 90.0262C564.502 112.166 580.912 128.771 605.375 128.771C615.86 128.771 625.433 125.78 631.814 119.497C633.182 118.3 633.334 117.253 632.574 116.205L626.952 108.576C626.192 107.529 625.433 107.379 624.369 107.978C618.291 111.418 612.821 112.466 607.047 112.466C594.891 112.466 587.446 107.379 585.166 98.1044H630.295C636.829 98.1044 638.5 94.0653 638.5 86.8847ZM602.944 68.9332C611.909 68.9332 618.595 73.7203 619.811 83.5936H584.863C586.534 73.8699 593.676 68.9332 602.944 68.9332Z"
      fill="#0B1419"
    />
  </svg>
);

export default SvgSensibleLogo;
