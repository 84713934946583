import { FC } from 'react';
import { PayoutProvider } from '../../utils/types/Entity';
import { PayoutProviderLogoProps } from './PayoutProviderLogo.types';
import {
  PayPalColorLogo,
  PayPalWhiteLogo,
} from '../assets/svg-tsx/providerLogos';
import Typography from '../Typography/Typography';
import styles from './payout-provider-logo.module.scss';

const PayoutProviderLogo: FC<PayoutProviderLogoProps> = ({
  payoutAccountProvider,
  darkBackground = false,
  achTitleText = 'Bank Account',
}) => {
  const BankAccount = (
    <Typography variant="h5" color="midnight">
      {achTitleText}
    </Typography>
  );

  const BankAccountDarkBackground = (
    <Typography variant="h5" color="snow">
      {achTitleText}
    </Typography>
  );

  if (darkBackground) {
    return {
      [PayoutProvider.PayPal]: (
        <div className={styles.logoWrapper}>
          <PayPalWhiteLogo title="PayPalWhiteLogo" />
        </div>
      ),
      [PayoutProvider.ACH]: BankAccountDarkBackground,
    }[payoutAccountProvider];
  }

  return {
    [PayoutProvider.PayPal]: (
      <div className={styles.logoWrapper}>
        <PayPalColorLogo title="PayPalColorLogo" />
      </div>
    ),
    [PayoutProvider.ACH]: BankAccount,
  }[payoutAccountProvider];
};

export default PayoutProviderLogo;
