import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgGeoSun = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 275 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M137.5 0L145.774 32.3626L159.01 1.69286L162.12 34.9514L179.99 6.72974L177.859 40.0654L199.924 14.9866L192.604 47.5784L218.32 26.2602L205.992 57.3057L234.727 40.2728L217.694 69.0076L248.74 56.6795L227.422 82.396L260.013 75.0763L234.935 97.1413L268.27 95.0102L240.049 112.88L273.307 115.99L242.637 129.226L275 137.5L242.637 145.774L273.307 159.01L240.049 162.12L268.27 179.99L234.935 177.859L260.013 199.924L227.422 192.604L248.74 218.32L217.694 205.992L234.727 234.727L205.992 217.694L218.32 248.74L192.604 227.422L199.924 260.013L177.859 234.935L179.99 268.27L162.12 240.049L159.01 273.307L145.774 242.637L137.5 275L129.226 242.637L115.99 273.307L112.88 240.049L95.0102 268.27L97.1413 234.935L75.0763 260.013L82.396 227.422L56.6795 248.74L69.0076 217.694L40.2728 234.727L57.3057 205.992L26.2602 218.32L47.5784 192.604L14.9866 199.924L40.0654 177.859L6.72974 179.99L34.9514 162.12L1.69286 159.01L32.3626 145.774L0 137.5L32.3626 129.226L1.69286 115.99L34.9514 112.88L6.72974 95.0102L40.0654 97.1413L14.9866 75.0763L47.5784 82.396L26.2602 56.6795L57.3057 69.0076L40.2728 40.2728L69.0076 57.3057L56.6795 26.2602L82.396 47.5784L75.0763 14.9866L97.1413 40.0654L95.0102 6.72974L112.88 34.9514L115.99 1.69286L129.226 32.3626L137.5 0Z"
      fill="#FFE500"
    />
  </svg>
);

export default SvgGeoSun;
