//used in client
import { User, isUser } from './User.types';
import { isPolicy, Policy } from './Policy.types';
import {
  stringValidator,
  numberValidator,
  objectValidator,
  arrayValidator,
} from '../../validate';
import { CurrencyUnit, isCurrencyUnit } from '../Currency/Currency.types';

export interface PolicyBundle {
  id: string;
  policyHolder: User;
  protectedParty: User;
  billTo: User;
  manager: {
    name: string;
  };
  createdAt: string;
  updatedAt: string;
  canceledAt: string | undefined;
  coverageStartDate: string;
  coverageEndDate: string;
  coverageStartHour: number;
  coverageEndHour: number;
  policies: Policy[];
  priceCharged?: number;
  reservationID?: string;
  slug: string;
  currency: CurrencyUnit;
  productID: string;
}

function isManagerName(value: unknown): asserts value is { name: string } {
  objectValidator(value, 'managerName');

  const { name } = value;

  stringValidator(name, 'managerName.name', { nonEmpty: true });
}

export function isPolicyBundle(value: unknown): asserts value is PolicyBundle {
  objectValidator(value, 'policyBundle');

  const {
    id,
    policyHolder,
    protectedParty,
    billTo,
    manager,
    createdAt,
    updatedAt,
    coverageStartDate,
    coverageEndDate,
    coverageStartHour,
    coverageEndHour,
    policies,
    priceCharged,
    reservationID,
    slug,
    currency,
  } = value;

  const requiredStrings = [
    { name: 'policyBundle.id', value: id },
    { name: 'policyBundle.createdAt', value: createdAt },
    { name: 'policyBundle.updatedAt', value: updatedAt },
    { name: 'policyBundle.slug', value: slug },
    { name: 'policyBundle.coverageStartDate', value: coverageStartDate },
    { name: 'policyBundle.coverageEndDate', value: coverageEndDate },
  ];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const requiredNumbers = [
    { name: 'policyBundle.coverageStartHour', value: coverageStartHour },
    { name: 'policyBundle.coverageEndHour', value: coverageEndHour },
  ];

  requiredNumbers.forEach((num) => {
    numberValidator(num.value, num.name);
  });

  const optionalStrings = [
    { name: 'policyBundle.reservationID', value: reservationID },
  ];

  optionalStrings.forEach((str) => {
    if (str.value !== undefined) {
      stringValidator(str.value, str.name);
    }
  });

  const optionalNumbers = [
    { name: 'policyBundle.priceCharged', value: priceCharged },
  ];

  optionalNumbers.forEach((num) => {
    if (num.value !== undefined) {
      numberValidator(num.value, num.name);
    }
  });

  arrayValidator(policies, 'policyBundle.policies', {
    typeguard: isPolicy,
    nonEmpty: true,
  });

  isCurrencyUnit(currency);

  isUser(policyHolder);
  isUser(protectedParty);
  isUser(billTo);

  isManagerName(manager);
}
