import React from 'react';
import utilsFormat from '../../utils/format';
import { TypographyProps } from './Typography.types';
import styles from './typography.module.scss';

type getElementSignature = (
  children: TypographyProps['children'],
  variant: TypographyProps['variant'],
  className: TypographyProps['className'],
  truncate: TypographyProps['truncate'],
  align: TypographyProps['align'],
  color: TypographyProps['color'],
  format: TypographyProps['format'],
) => JSX.Element;

const getElement: getElementSignature = (
  children,
  variant,
  className,
  truncate,
  align,
  color = 'midnight',
  format,
) => {
  const colorClass = styles[color.split(' ').join('-')];
  const cssClasses = `${styles[variant]} ${
    align ? styles[align] : ''
  } ${colorClass} ${className || ''}`;

  let formattedChildren = children;

  if (typeof children === 'string') {
    switch (format) {
      case 'sentence':
        formattedChildren = utilsFormat.toSentenceCase(children);
        break;
      case 'punctuated sentence':
        formattedChildren = utilsFormat.toSentenceCaseAndPunctuation(children);
        break;
      case 'title':
        formattedChildren = utilsFormat.toTitleCase(children);
        break;
      case 'upper case':
        formattedChildren = utilsFormat.toUpperCase(children);
        break;
      case 'lower case':
        formattedChildren = utilsFormat.toLowerCase(children);
        break;
      default:
        break;
    }
  }

  const style = truncate
    ? {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as const,
        overflow: 'hidden',
      }
    : {};

  const elements = {
    display: (
      <h1 className={cssClasses} style={style}>
        {formattedChildren}
      </h1>
    ),
    'small-display': (
      <h6 className={cssClasses} style={style}>
        {formattedChildren}
      </h6>
    ),
    h1: (
      <h1 className={cssClasses} style={style}>
        {formattedChildren}
      </h1>
    ),
    h2: (
      <h2 className={cssClasses} style={style}>
        {formattedChildren}
      </h2>
    ),
    h3: (
      <h3 className={cssClasses} style={style}>
        {formattedChildren}
      </h3>
    ),
    h4: (
      <h4 className={cssClasses} style={style}>
        {formattedChildren}
      </h4>
    ),
    h5: (
      <h5 className={cssClasses} style={style}>
        {formattedChildren}
      </h5>
    ),
    h6: (
      <h6 className={cssClasses} style={style}>
        {formattedChildren}
      </h6>
    ),
    h7: (
      <h6 className={cssClasses} style={style}>
        {formattedChildren}
      </h6>
    ),
    'body-1': (
      <p className={cssClasses} style={style}>
        {formattedChildren}
      </p>
    ),
    'body-2': (
      <p className={cssClasses} style={style}>
        {formattedChildren}
      </p>
    ),
    'body-3': (
      <p className={cssClasses} style={style}>
        {formattedChildren}
      </p>
    ),
    'body-4': (
      <p className={cssClasses} style={style}>
        {formattedChildren}
      </p>
    ),
    'label-1': (
      <p className={cssClasses} style={style}>
        {formattedChildren}
      </p>
    ),
    'label-2': (
      <p className={cssClasses} style={style}>
        {formattedChildren}
      </p>
    ),
    'label-3': (
      <p className={cssClasses} style={style}>
        {formattedChildren}
      </p>
    ),
    error: (
      <p className={cssClasses} style={style}>
        {formattedChildren}
      </p>
    ),
  };

  return elements[variant];
};

const Typography: React.FC<TypographyProps> = ({
  children,
  variant,
  className,
  truncate = false,
  align = 'left',
  color = 'midnight',
  format = 'none',
}) => getElement(children, variant, className, truncate, align, color, format);

export default Typography;
