import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BoldTextButton from '../../sharedComponents/BoldTextButton/BoldTextButton';
import Modal from '../../sharedComponents/Modal/Modal';
import Accordion from '../../sharedComponents/Accordion/Accordion';
import PayoutProviderLogo from '../../sharedComponents/PayoutProviderLogo/PayoutProviderLogo';
import Typography from '../../sharedComponents/Typography/Typography';
import { useActions } from '../../store/hooks';
import { PayoutAccountAPI } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { ManagePayoutAccountDrawerProps } from './ManagePayoutAccountDrawer.types';
import styles from './manage-payout-account-drawer.module.scss';

const ManagePayoutAccountDrawer: FC<ManagePayoutAccountDrawerProps> = ({
  isExpanded,
  account,
  clickHandler,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [userErrors, setUserErrors] = useState<Record<string, string>>({
    updateError: '',
    deleteError: '',
  });
  const { id, isDefault, providerName, providerCustomerID, displayName } =
    account;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useActions();

  const openModal = (): void => setIsOpen(true);
  const closeModal = (): void => {
    setIsOpen(false);
    setUserErrors({ updateError: '', deleteError: '' });
  };

  const renderedHeader = (
    <div className={styles.vrtWrapperOuter}>
      <div className={styles.header}>
        <PayoutProviderLogo
          payoutAccountProvider={providerName}
          achTitleText={t('label.bank_account')}
        />
      </div>
      <Typography
        variant="label-1"
        color="midnight-40"
        format="none"
        className={styles.displayName}
      >
        {displayName}
      </Typography>
    </div>
  );

  const updateAccountHandler = (): void => {
    const updateAccount = async (): Promise<void> => {
      const token = await getAccessTokenSilently();
      try {
        await PayoutAccountAPI.changeDefaultStatus(
          token,
          id,
          isDefault ? false : true,
        );
        dispatch.updateDefaultAccountStatus({
          payoutAccount: account,
          isDefault: !isDefault,
        });
      } catch (e) {
        setUserErrors({
          deleteError: '',
          updateError: t('error.update_account'),
        });
        setTimeout(
          () =>
            setUserErrors({
              ...userErrors,
              updateError: '',
            }),
          3000,
        );
      }
    };
    void updateAccount();
  };

  const deleteAccountHandler = (): void => {
    const removeAccount = async (): Promise<void> => {
      try {
        const token = await getAccessTokenSilently();
        await PayoutAccountAPI.deletePayoutAccount(
          token,
          id,
          providerName,
          providerCustomerID,
        );

        dispatch.removePayoutAccount({ id });
      } catch (e) {
        setUserErrors({
          updateError: '',
          deleteError: t('error.delete_account'),
        });
        setTimeout(
          () =>
            setUserErrors({
              ...userErrors,
              deleteError: '',
            }),
          3000,
        );
      }
    };
    void removeAccount();
  };

  const bodyContent = (
    <div className={styles.vrtWrapperOuter}>
      <BoldTextButton
        title={`${
          isDefault
            ? t('sunshine.label.remove_as_primary')
            : t('sunshine.label.set_as_primary')
        }`}
        colorVariant="midnight"
        errMsg={userErrors.updateError}
        clickHandler={updateAccountHandler}
      />
      <BoldTextButton
        title={t('sunshine.label.remove_account')}
        colorVariant="status red"
        errMsg={userErrors.deleteError}
        clickHandler={openModal}
      />
      <Modal
        isActive={isOpen}
        onCloseHandler={closeModal}
        title={renderedHeader}
      >
        <div className={styles.vrtWrapperInner}>
          <Typography variant="body-1" align="left">
            {t('sunshine.label.conf_remove_account')}
          </Typography>

          <BoldTextButton
            title={t('sunshine.label.keep_account')}
            colorVariant="midnight"
            errMsg=""
            clickHandler={closeModal}
          />
          <BoldTextButton
            title={t('sunshine.label.remove_account')}
            colorVariant="status red"
            errMsg={userErrors.deleteError}
            clickHandler={deleteAccountHandler}
          />
        </div>
      </Modal>
    </div>
  );
  return (
    <Accordion
      title={renderedHeader}
      content={bodyContent}
      isExpanded={isExpanded}
      clickHandler={(): void => clickHandler(id)}
      selected={isDefault}
    />
  );
};

export default ManagePayoutAccountDrawer;
