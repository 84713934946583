import { CloseButtonProps } from './CloseButton.types';
import styles from './close-button.module.scss';
import { PlusSign } from '../assets/svg-tsx/sensible';

const CloseButton: React.FC<CloseButtonProps> = ({
  onClickHandler,
  colorVariant = 'midnight',
}) => {
  // TODO: #2262 make area-label a translated prop
  return (
    <div className={styles.container}>
      <button
        aria-label="close"
        className={styles[colorVariant]}
        onClick={onClickHandler}
      >
        <PlusSign />
      </button>
    </div>
  );
};

export default CloseButton;
