import { useContext } from 'react';
import { ConfigContext } from '../contexts';

export type SupportedLanguages =
  | 'English'
  | 'Français'
  | 'Svenska'
  | 'Test Keys';

export const i18NextSupportedLngsConfig = ['en', 'fr', 'sv', 'cimode', 'eo'];

const useUserLanguageConfig = (): {
  languageMap: Record<string, string>;
  supportedLanguages: SupportedLanguages[];
  i18NextSupportedLngsConfig: string[];
  languageAbbreviationToWord: (arg: string) => SupportedLanguages;
  languageWordToAbbreviation: (arg: SupportedLanguages) => string;
} => {
  const context = useContext(ConfigContext);

  const toBoolean = (arg: string | undefined): boolean => {
    if (arg === 'true') {
      return true;
    } else return false;
  };

  const newLangTesting = toBoolean(context.config?.newLangTesting);
  const testKeys = toBoolean(context.config?.testTransKeys);

  // update newLang var with new language that is being tested. After testing is done, add new language to the following:
  // SupportedLanguages type,
  // i18NextSupportedLngsConfig array,
  // languageMap obj and
  // supportedLanguages array

  const newLang = {
    languageName: 'Svenska',
    languageCode: 'sv-SE',
  };

  const languageMap: Record<SupportedLanguages, string> = {
    English: 'en-US',
    Français: 'fr-FR',
    Svenska: 'sv-SE',
    'Test Keys': 'cimode',
  };

  const supportedLanguages: SupportedLanguages[] = [
    'English',
    'Français',
    'Svenska',
  ];

  if (newLangTesting) {
    languageMap[newLang.languageName as SupportedLanguages] =
      newLang.languageCode;
    supportedLanguages.push(newLang.languageName as SupportedLanguages);
  }

  if (testKeys) {
    supportedLanguages.push('Test Keys');
  }

  const languageAbbreviationToWord = (abr: string): SupportedLanguages => {
    const abrMatch = Object.entries(languageMap).find(
      ([, value]) => value === abr,
    );
    return abrMatch?.[0] as SupportedLanguages;
  };

  const languageWordToAbbreviation = (word: SupportedLanguages): string => {
    return languageMap[word];
  };

  return {
    languageMap,
    supportedLanguages,
    i18NextSupportedLngsConfig,
    languageAbbreviationToWord,
    languageWordToAbbreviation,
  };
};

export default useUserLanguageConfig;
