import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import { ReimbursementBalanceComponentProps } from './ReimbursementBalanceComponent.types';

const ReimbursementBalanceComponent: React.FunctionComponent<
  ReimbursementBalanceComponentProps
> = ({ balance, currency }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h7" color="ocean">
        {t('formatter.currency', { val: balance, currency })}
      </Typography>
    </div>
  );
};

export default ReimbursementBalanceComponent;
