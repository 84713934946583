import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSignOut = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.0219 11.3623C12.014 11.3623 13.6289 9.74739 13.6289 7.75535C13.6289 5.76331 12.014 4.14844 10.0219 4.14844C8.02991 4.14844 6.41504 5.76331 6.41504 7.75535C6.41504 9.74739 8.02991 11.3623 10.0219 11.3623Z" />
    <path d="M10 0C4.49244 0 0 4.47084 0 10C0 15.5292 4.49244 20 10 20C15.5076 20 20 15.5076 20 10C20 4.49244 15.5076 0 10 0ZM15.8747 16.3067C15.1404 14.3413 13.5421 12.2462 10 12.2462C6.47948 12.2462 4.85961 14.3413 4.12527 16.3067C2.4406 14.73 1.36069 12.4838 1.36069 10C1.36069 5.24838 5.22678 1.36069 10 1.36069C14.7732 1.36069 18.6393 5.22678 18.6393 10C18.6393 12.4838 17.5594 14.73 15.8747 16.3067Z" />
  </svg>
);

export default SvgSignOut;
