import { useContext, useEffect, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { ConfigContext } from '../contexts/configContext';
import { Config } from '../types/config';

const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [clientID, setClientID] = useState('');
  const [domain, setDomain] = useState('');
  const [audience, setAudience] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const configEnvelope = useContext(ConfigContext);

  useEffect(() => {
    if (configEnvelope.ready) {
      if (configEnvelope.error) {
        setError(true);
        setIsLoading(false);
        return;
      }

      setError(false);
      const { authDomain, reactAuthClientID, protectAuthAudience } =
        configEnvelope.config as Config;

      setDomain(authDomain);
      setClientID(reactAuthClientID);
      setAudience(protectAuthAudience);

      setIsLoading(false);
    }
  }, [configEnvelope]);

  // TODO #979: Replace with error screen
  if (error) {
    return <div>Error</div>;
  }

  if (isLoading) {
    return <></>;
  }

  /**
   * * If the user goes to the /notifications path, preserves their current * URL params and redirects them back to that page. Else, strips * the params and sends them back to the site origin. Exact redirect URIs must be whitelisted or the user received an error. Only /notification and / are allowed.
   **/
  let redirectUri =
    window.location.pathname === '/notification'
      ? window.location.href
      : window.location.origin;

  redirectUri += redirectUri.includes('?') ? '&' : '?';
  redirectUri += 'login=true';

  if (!(domain && clientID && redirectUri && audience)) {
    return <></>;
  }

  return (
    <Auth0Provider
      clientId={clientID}
      domain={domain}
      audience={audience}
      redirectUri={redirectUri}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
