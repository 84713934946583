import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDarkSensibleLogoStacked = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 640 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M229.127 77.1349C229.127 112.52 197.725 141.204 158.988 141.204C120.251 141.204 88.8486 112.52 88.8486 77.1349C88.8486 41.7503 120.251 13.0654 158.988 13.0654C197.725 13.0654 229.127 41.7503 229.127 77.1349Z"
      fill="#6D8EA0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.98456 10.3711L9.98455 80.8444C9.98455 119.766 41.6697 151.318 80.7553 151.318C119.841 151.318 151.526 119.766 151.526 80.8444C151.526 41.9231 119.841 10.3711 80.7553 10.3711L9.98456 10.3711ZM80.7553 160.763C125.079 160.763 161.011 124.982 161.011 80.8444C161.011 36.7068 125.079 0.92619 80.7553 0.926186L0.499831 0.926179L0.499824 80.8444C0.49982 124.982 36.4314 160.763 80.7553 160.763Z"
      fill="#3D6881"
    />
    <path
      d="M4.54688 81.5068V0.925781L91.6428 6.29785L124.304 22.4141L145.088 38.5303L156.965 81.5068L145.088 124.483L113.417 151.344L75.3123 156.716L31.7644 144.348L9.99037 102.37L4.54688 81.5068Z"
      fill="#3D6881"
    />
    <path
      d="M105.71 121.458C119.655 121.458 131.751 114.254 131.893 100.27C132.035 89.676 125.063 83.7433 116.24 80.212L106.422 76.3981C102.58 74.9856 99.7338 73.008 99.7338 69.6179C99.7338 66.6516 101.868 64.109 106.422 64.109C110.548 64.109 114.533 66.0865 119.086 69.9004C120.367 70.8892 121.363 71.0304 122.359 69.9004L128.62 62.4139C129.331 61.5664 129.616 60.4364 128.762 59.4476C122.928 52.5261 114.533 48.8535 105.426 48.8535C92.4767 48.8535 81.0929 56.4812 81.0929 69.7591C81.0929 79.3644 87.354 85.1559 96.3187 88.6872L105.141 92.2186C110.264 94.3374 112.398 96.0325 112.398 99.4226C112.398 103.378 109.268 105.214 104.572 105.214C99.1646 105.214 94.3265 102.813 88.4924 98.4338C87.354 97.5863 86.0733 97.3038 84.935 98.9988L79.9546 105.92C78.9585 107.474 78.8162 109.028 79.67 110.017C85.2195 116.514 94.0419 121.458 105.71 121.458Z"
      fill="white"
    />
    <path
      d="M210.915 81.907C210.915 64.3915 198.678 48.8535 177.476 48.8535C155.989 48.8535 141.617 64.5327 141.617 84.8734C141.617 105.779 156.985 121.458 179.895 121.458C189.713 121.458 198.678 118.633 204.654 112.701C205.935 111.57 206.077 110.582 205.366 109.593L200.101 102.389C199.389 101.4 198.678 101.259 197.682 101.824C191.99 105.073 186.867 106.062 181.46 106.062C170.076 106.062 163.104 101.259 160.969 92.5011H203.231C209.35 92.5011 210.915 88.6872 210.915 81.907ZM177.618 64.9565C186.013 64.9565 192.274 69.4766 193.413 78.7994H160.685C162.25 69.6179 168.938 64.9565 177.618 64.9565Z"
      fill="white"
    />
    <path
      d="M264.801 48.8535C254.413 48.8535 247.726 53.9386 243.741 61.0014L243.172 52.8086C243.172 51.1136 242.318 50.5485 241.038 50.5485H226.239C224.958 50.5485 224.104 51.3961 224.104 52.6674V117.644C224.104 118.916 224.958 119.763 226.239 119.763H241.607C242.887 119.763 243.741 118.916 243.741 117.644V81.7658C243.741 72.0192 249.148 66.7928 257.402 66.7928C265.797 66.7928 270.351 72.0192 270.351 81.7658V117.644C270.351 118.916 271.204 119.763 272.485 119.763H287.711C288.992 119.763 289.988 118.916 289.988 117.644V77.3869C289.988 59.3063 279.458 48.8535 264.801 48.8535Z"
      fill="white"
    />
    <path
      d="M330.55 121.458C344.495 121.458 356.59 114.254 356.732 100.27C356.875 89.676 349.902 83.7433 341.08 80.212L331.261 76.3981C327.419 74.9856 324.573 73.008 324.573 69.6179C324.573 66.6516 326.708 64.109 331.261 64.109C335.388 64.109 339.372 66.0865 343.926 69.9004C345.206 70.8892 346.203 71.0304 347.199 69.9004L353.46 62.4139C354.171 61.5664 354.456 60.4364 353.602 59.4476C347.768 52.5261 339.372 48.8535 330.265 48.8535C317.316 48.8535 305.933 56.4812 305.933 69.7591C305.933 79.3644 312.194 85.1559 321.158 88.6872L329.981 92.2186C335.103 94.3374 337.238 96.0325 337.238 99.4226C337.238 103.378 334.107 105.214 329.412 105.214C324.004 105.214 319.166 102.813 313.332 98.4338C312.194 97.5863 310.913 97.3038 309.775 98.9988L304.794 105.92C303.798 107.474 303.656 109.028 304.51 110.017C310.059 116.514 318.882 121.458 330.55 121.458Z"
      fill="white"
    />
    <path
      d="M380.812 38.2594C387.073 38.2594 391.626 33.4567 391.626 27.3828C391.626 21.4501 387.073 16.6475 380.812 16.6475C374.551 16.6475 369.855 21.4501 369.855 27.3828C369.855 33.4567 374.551 38.2594 380.812 38.2594ZM372.985 119.763H388.496C389.776 119.763 390.63 118.916 390.63 117.644V52.6674C390.63 51.3961 389.776 50.5485 388.496 50.5485H372.985C371.705 50.5485 370.851 51.3961 370.851 52.6674V117.644C370.851 118.916 371.705 119.763 372.985 119.763Z"
      fill="white"
    />
    <path
      d="M449.081 48.8535C439.262 48.8535 432.147 52.5261 427.452 58.7413V20.8851C427.452 19.6138 426.598 18.7663 425.317 18.7663H409.807C408.526 18.7663 407.672 19.6138 407.672 20.8851V117.644C407.672 118.916 408.668 119.763 409.949 119.763H423.894C425.175 119.763 426.029 118.916 426.029 117.644L426.313 110.017C431.151 117.079 438.551 121.458 449.081 121.458C468.575 121.458 482.093 105.779 482.093 85.1559C482.093 64.674 468.575 48.8535 449.081 48.8535ZM444.954 103.66C434.424 103.66 427.167 96.315 427.167 85.1559C427.167 73.9968 434.424 66.6515 444.954 66.6515C455.199 66.6515 462.883 74.138 462.883 85.1559C462.883 96.315 455.199 103.66 444.954 103.66Z"
      fill="white"
    />
    <path
      d="M497.63 119.763H513.141C514.422 119.763 515.275 118.916 515.275 117.644V20.8851C515.275 19.6138 514.422 18.7663 513.141 18.7663H497.63C496.35 18.7663 495.496 19.6138 495.496 20.8851V117.644C495.496 118.916 496.35 119.763 497.63 119.763Z"
      fill="white"
    />
    <path
      d="M597.923 81.907C597.923 64.3915 585.685 48.8535 564.483 48.8535C542.996 48.8535 528.624 64.5327 528.624 84.8734C528.624 105.779 543.992 121.458 566.902 121.458C576.721 121.458 585.685 118.633 591.662 112.701C592.943 111.57 593.085 110.582 592.373 109.593L587.108 102.389C586.397 101.4 585.685 101.259 584.689 101.824C578.997 105.073 573.875 106.062 568.468 106.062C557.084 106.062 550.111 101.259 547.977 92.5011H590.239C596.358 92.5011 597.923 88.6872 597.923 81.907ZM564.625 64.9565C573.021 64.9565 579.282 69.4766 580.42 78.7994H547.692C549.257 69.6179 555.945 64.9565 564.625 64.9565Z"
      fill="white"
    />
    <path
      d="M345.321 179.87H349.487C350.141 179.87 350.713 179.544 350.958 178.89L362.394 146.294L373.749 178.89C373.994 179.544 374.565 179.87 375.219 179.87H379.385C380.038 179.87 380.61 179.544 380.855 178.89L394.007 141.228C394.334 140.33 394.007 139.84 393.108 139.84H389.351C388.697 139.84 388.371 140.166 388.126 140.82L377.179 173.416L365.907 140.82C365.662 140.166 365.09 139.84 364.436 139.84H360.352C359.698 139.84 359.127 140.166 358.881 140.82L347.609 173.416L336.663 140.82C336.417 140.166 336.009 139.84 335.356 139.84H331.68C330.781 139.84 330.454 140.33 330.781 141.228L343.851 178.89C344.096 179.544 344.668 179.87 345.321 179.87Z"
      fill="white"
    />
    <path
      d="M438.033 157.813C438.033 148.009 431.171 138.859 419.327 138.859C407.4 138.859 399.232 147.927 399.232 159.692C399.232 171.374 407.319 180.851 420.389 180.851C425.944 180.851 431.09 179.135 434.684 175.704C435.256 175.132 435.583 174.724 435.011 173.988L433.622 172.109C433.295 171.701 432.805 171.701 432.397 172.028C429.293 174.397 425.78 175.867 420.879 175.867C411.158 175.867 405.685 169.904 404.95 161.979H435.092C437.461 161.979 438.033 160.754 438.033 157.813ZM419.245 144.088C427.332 144.088 432.56 149.561 432.805 157.404H404.95C405.685 149.316 411.321 144.088 419.245 144.088Z"
      fill="white"
    />
    <path
      d="M486.159 139.84H482.647C481.911 139.84 481.585 140.248 481.585 140.983L481.503 147.764C478.562 142.29 473.253 138.859 466.064 138.859C454.383 138.859 446.377 147.927 446.377 159.855C446.377 171.864 454.383 180.851 466.064 180.851C473.253 180.851 478.562 177.501 481.503 172.109L481.585 178.727C481.585 179.462 481.911 179.87 482.647 179.87H486.159C486.894 179.87 487.221 179.462 487.221 178.727V140.983C487.221 140.248 486.894 139.84 486.159 139.84ZM466.881 175.377C458.304 175.377 452.096 169.168 452.096 159.855C452.096 150.623 458.304 144.333 466.881 144.333C475.458 144.333 481.421 150.705 481.421 160.018C481.421 169.25 475.458 175.377 466.881 175.377Z"
      fill="white"
    />
    <path
      d="M516.136 144.741C516.872 144.741 517.28 144.333 517.28 143.598V140.983C517.28 140.248 516.872 139.84 516.136 139.84H509.356V130.363C509.356 129.628 509.03 129.219 508.294 129.219H504.537C503.802 129.219 503.475 129.628 503.475 130.363V139.84H498.247C497.512 139.84 497.103 140.248 497.103 140.983V143.598C497.103 144.333 497.512 144.741 498.247 144.741H503.475V172.191C503.475 178.073 506.416 180.851 511.97 180.851C514.094 180.851 517.117 180.442 517.117 179.054V176.603C517.117 175.786 516.79 175.377 515.565 175.541C514.829 175.622 513.686 175.704 513.032 175.704C510.582 175.704 509.356 174.56 509.356 171.456V144.741H516.136Z"
      fill="white"
    />
    <path
      d="M546.186 138.859C539.57 138.859 535.404 142.127 533.117 146.212V122.602C533.117 121.866 532.79 121.458 532.055 121.458H528.297C527.562 121.458 527.235 121.866 527.235 122.602V178.727C527.235 179.462 527.562 179.87 528.297 179.87H532.055C532.79 179.87 533.117 179.462 533.117 178.727V155.77C533.117 148.663 538.099 144.333 544.716 144.333C551.333 144.333 555.09 148.663 555.09 155.77V178.727C555.09 179.462 555.335 179.87 556.071 179.87H559.91C560.645 179.87 560.972 179.462 560.972 178.727V155.198C560.972 144.578 554.927 138.859 546.186 138.859Z"
      fill="white"
    />
    <path
      d="M610.103 157.813C610.103 148.009 603.241 138.859 591.397 138.859C579.47 138.859 571.301 147.927 571.301 159.692C571.301 171.374 579.389 180.851 592.458 180.851C598.013 180.851 603.159 179.135 606.754 175.704C607.326 175.132 607.652 174.724 607.08 173.988L605.692 172.109C605.365 171.701 604.875 171.701 604.466 172.028C601.362 174.397 597.85 175.867 592.949 175.867C583.228 175.867 577.755 169.904 577.02 161.979H607.162C609.531 161.979 610.103 160.754 610.103 157.813ZM591.315 144.088C599.402 144.088 604.63 149.561 604.875 157.404H577.02C577.755 149.316 583.391 144.088 591.315 144.088Z"
      fill="white"
    />
    <path
      d="M639.196 139.676C638.461 139.186 637.153 138.859 635.52 138.859C630.537 138.859 627.841 141.882 626.289 145.64L626.126 141.065C626.126 140.166 625.799 139.84 624.982 139.84H621.551C620.816 139.84 620.489 140.248 620.489 140.983V178.727C620.489 179.462 620.816 179.87 621.551 179.87H625.309C626.044 179.87 626.371 179.462 626.371 178.727V155.198C626.371 150.705 628.331 144.251 635.52 144.251C636.337 144.251 637.317 144.333 638.379 144.66C639.277 144.905 639.849 144.741 639.849 143.843V141.065C639.849 140.33 639.686 140.003 639.196 139.676Z"
      fill="white"
    />
  </svg>
);

export default SvgDarkSensibleLogoStacked;
