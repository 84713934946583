import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSensibleLogoStacked = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 684 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M244.737 82.1843C244.737 119.985 211.191 150.628 169.809 150.628C128.427 150.628 94.8809 119.985 94.8809 82.1843C94.8809 44.3837 128.427 13.7402 169.809 13.7402C211.191 13.7402 244.737 44.3837 244.737 82.1843Z"
      fill="#FFE500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6326 10.8613L10.6326 86.1465C10.6326 127.725 44.4811 161.432 86.2355 161.432C127.99 161.432 161.838 127.725 161.838 86.1465C161.838 44.5676 127.99 10.8613 86.2355 10.8613L10.6326 10.8613ZM86.2355 171.521C133.586 171.521 171.971 133.298 171.971 86.1465C171.971 38.9952 133.586 0.771496 86.2355 0.771492L0.500244 0.771485L0.500236 86.1465C0.500232 133.298 38.8852 171.521 86.2355 171.521Z"
      fill="#00FFE0"
    />
    <path
      d="M4.82324 86.8545V0.771484L97.866 6.51035L132.757 23.727L154.96 40.9436L167.648 86.8545L154.96 132.765L121.127 161.46L80.4205 167.199L33.8991 153.986L10.6384 109.143L4.82324 86.8545Z"
      fill="#00FFE0"
    />
    <path
      d="M112.905 128.771C127.796 128.771 140.711 121.142 140.863 106.332C141.015 95.1124 133.57 88.8294 124.149 85.0895L113.665 81.0505C109.562 79.5545 106.523 77.4602 106.523 73.8699C106.523 70.7284 108.802 68.0356 113.665 68.0356C118.071 68.0356 122.326 70.13 127.188 74.1691C128.556 75.2162 129.619 75.3658 130.683 74.1691L137.368 66.2405C138.128 65.3429 138.432 64.1461 137.52 63.099C131.291 55.7688 122.326 51.8793 112.601 51.8793C98.7739 51.8793 86.6181 59.9575 86.6181 74.0195C86.6181 84.192 93.3038 90.3254 102.876 94.0653L112.297 97.8052C117.767 100.049 120.046 101.844 120.046 105.435C120.046 109.623 116.704 111.568 111.689 111.568C105.915 111.568 100.749 109.025 94.5194 104.387C93.3038 103.49 91.9363 103.191 90.7207 104.986L85.4025 112.316C84.3389 113.961 84.1869 115.607 85.0986 116.654C91.0246 123.536 100.445 128.771 112.905 128.771Z"
      fill="#0B1419"
    />
    <path
      d="M225.245 86.8847C225.245 68.3348 212.178 51.8793 189.537 51.8793C166.593 51.8793 151.247 68.4844 151.247 90.0262C151.247 112.166 167.657 128.771 192.121 128.771C202.605 128.771 212.178 125.78 218.559 119.497C219.927 118.3 220.079 117.253 219.319 116.205L213.697 108.576C212.937 107.529 212.178 107.379 211.114 107.978C205.036 111.418 199.566 112.466 193.792 112.466C181.636 112.466 174.191 107.379 171.912 98.1044H217.04C223.574 98.1044 225.245 94.0653 225.245 86.8847ZM189.689 68.9332C198.654 68.9332 205.34 73.7203 206.556 83.5936H171.608C173.279 73.8699 180.421 68.9332 189.689 68.9332Z"
      fill="#0B1419"
    />
    <path
      d="M282.786 51.8793C271.693 51.8793 264.552 57.2647 260.297 64.7445L259.69 56.068C259.69 54.2728 258.778 53.6744 257.41 53.6744H241.608C240.24 53.6744 239.329 54.572 239.329 55.9184V124.732C239.329 126.079 240.24 126.976 241.608 126.976H258.018C259.386 126.976 260.297 126.079 260.297 124.732V86.7351C260.297 76.413 266.071 70.8779 274.884 70.8779C283.849 70.8779 288.711 76.413 288.711 86.7351V124.732C288.711 126.079 289.623 126.976 290.991 126.976H307.249C308.617 126.976 309.68 126.079 309.68 124.732V82.0976C309.68 62.9494 298.436 51.8793 282.786 51.8793Z"
      fill="#0B1419"
    />
    <path
      d="M352.993 128.771C367.884 128.771 380.8 121.142 380.952 106.332C381.104 95.1124 373.658 88.8294 364.238 85.0895L353.753 81.0505C349.651 79.5545 346.612 77.4602 346.612 73.8699C346.612 70.7284 348.891 68.0356 353.753 68.0356C358.16 68.0356 362.414 70.13 367.277 74.1691C368.644 75.2162 369.708 75.3658 370.771 74.1691L377.457 66.2405C378.217 65.3429 378.521 64.1461 377.609 63.099C371.379 55.7688 362.414 51.8793 352.69 51.8793C338.862 51.8793 326.707 59.9575 326.707 74.0195C326.707 84.192 333.392 90.3254 342.965 94.0653L352.386 97.8052C357.856 100.049 360.135 101.844 360.135 105.435C360.135 109.623 356.792 111.568 351.778 111.568C346.004 111.568 340.838 109.025 334.608 104.387C333.392 103.49 332.025 103.191 330.809 104.986L325.491 112.316C324.427 113.961 324.275 115.607 325.187 116.654C331.113 123.536 340.534 128.771 352.993 128.771Z"
      fill="#0B1419"
    />
    <path
      d="M406.664 40.6596C413.35 40.6596 418.212 35.5734 418.212 29.1408C418.212 22.8577 413.35 17.7715 406.664 17.7715C399.978 17.7715 394.964 22.8577 394.964 29.1408C394.964 35.5734 399.978 40.6596 406.664 40.6596ZM398.307 126.976H414.869C416.237 126.976 417.148 126.079 417.148 124.732V55.9184C417.148 54.572 416.237 53.6744 414.869 53.6744H398.307C396.939 53.6744 396.028 54.572 396.028 55.9184V124.732C396.028 126.079 396.939 126.976 398.307 126.976Z"
      fill="#0B1419"
    />
    <path
      d="M479.563 51.8793C469.079 51.8793 461.481 55.7688 456.467 62.351V22.2594C456.467 20.913 455.555 20.0154 454.188 20.0154H437.626C436.258 20.0154 435.346 20.913 435.346 22.2594V124.732C435.346 126.079 436.41 126.976 437.778 126.976H452.668C454.036 126.976 454.948 126.079 454.948 124.732L455.252 116.654C460.418 124.134 468.319 128.771 479.563 128.771C500.38 128.771 514.815 112.166 514.815 90.3254C514.815 68.634 500.38 51.8793 479.563 51.8793ZM475.157 109.922C463.913 109.922 456.163 102.143 456.163 90.3254C456.163 78.5073 463.913 70.7284 475.157 70.7284C486.097 70.7284 494.302 78.6569 494.302 90.3254C494.302 102.143 486.097 109.922 475.157 109.922Z"
      fill="#0B1419"
    />
    <path
      d="M531.406 126.976H547.968C549.335 126.976 550.247 126.079 550.247 124.732V22.2594C550.247 20.913 549.335 20.0154 547.968 20.0154H531.406C530.038 20.0154 529.126 20.913 529.126 22.2594V124.732C529.126 126.079 530.038 126.976 531.406 126.976Z"
      fill="#0B1419"
    />
    <path
      d="M638.5 86.8847C638.5 68.3348 625.432 51.8793 602.792 51.8793C579.848 51.8793 564.502 68.4844 564.502 90.0262C564.502 112.166 580.912 128.771 605.375 128.771C615.86 128.771 625.432 125.78 631.814 119.497C633.182 118.3 633.334 117.253 632.574 116.205L626.952 108.576C626.192 107.529 625.433 107.379 624.369 107.978C618.291 111.418 612.821 112.466 607.047 112.466C594.891 112.466 587.446 107.379 585.167 98.1044H630.295C636.829 98.1044 638.5 94.0653 638.5 86.8847ZM602.944 68.9332C611.909 68.9332 618.595 73.7203 619.81 83.5936H584.863C586.534 73.8699 593.676 68.9332 602.944 68.9332Z"
      fill="#0B1419"
    />
    <path
      d="M369.151 191.715H373.597C374.295 191.715 374.905 191.363 375.167 190.659L387.373 155.534L399.491 190.659C399.753 191.363 400.363 191.715 401.061 191.715H405.507C406.205 191.715 406.815 191.363 407.076 190.659L421.113 150.075C421.462 149.107 421.113 148.579 420.154 148.579H416.144C415.446 148.579 415.097 148.931 414.836 149.635L403.153 184.76L391.122 149.635C390.86 148.931 390.25 148.579 389.552 148.579H385.193C384.496 148.579 383.885 148.931 383.624 149.635L371.592 184.76L359.909 149.635C359.648 148.931 359.212 148.579 358.515 148.579H354.591C353.632 148.579 353.283 149.107 353.632 150.075L367.582 190.659C367.843 191.363 368.454 191.715 369.151 191.715Z"
      fill="#0B1419"
    />
    <path
      d="M468.102 167.946C468.102 157.382 460.779 147.523 448.137 147.523C435.408 147.523 426.689 157.294 426.689 169.971C426.689 182.56 435.321 192.771 449.27 192.771C455.199 192.771 460.691 190.923 464.528 187.225C465.138 186.609 465.487 186.169 464.876 185.377L463.394 183.352C463.045 182.912 462.522 182.912 462.086 183.264C458.773 185.817 455.024 187.401 449.793 187.401C439.418 187.401 433.577 180.975 432.792 172.436H464.964C467.492 172.436 468.102 171.115 468.102 167.946ZM448.05 153.157C456.681 153.157 462.261 159.055 462.522 167.506H432.792C433.577 158.791 439.593 153.157 448.05 153.157Z"
      fill="#0B1419"
    />
    <path
      d="M519.467 148.579H515.718C514.933 148.579 514.585 149.019 514.585 149.811L514.497 157.118C511.359 151.22 505.692 147.523 498.019 147.523C485.552 147.523 477.008 157.294 477.008 170.147C477.008 183.088 485.552 192.771 498.019 192.771C505.692 192.771 511.359 189.162 514.497 183.352L514.585 190.483C514.585 191.275 514.933 191.715 515.718 191.715H519.467C520.252 191.715 520.6 191.275 520.6 190.483V149.811C520.6 149.019 520.252 148.579 519.467 148.579ZM498.891 186.873C489.737 186.873 483.111 180.183 483.111 170.147C483.111 160.199 489.737 153.421 498.891 153.421C508.046 153.421 514.41 160.287 514.41 170.323C514.41 180.271 508.046 186.873 498.891 186.873Z"
      fill="#0B1419"
    />
    <path
      d="M551.462 153.861C552.246 153.861 552.682 153.421 552.682 152.628V149.811C552.682 149.019 552.246 148.579 551.462 148.579H544.225V138.367C544.225 137.575 543.877 137.135 543.092 137.135H539.081C538.297 137.135 537.948 137.575 537.948 138.367V148.579H532.368C531.583 148.579 531.148 149.019 531.148 149.811V152.628C531.148 153.421 531.583 153.861 532.368 153.861H537.948V183.44C537.948 189.778 541.087 192.771 547.015 192.771C549.282 192.771 552.508 192.331 552.508 190.835V188.194C552.508 187.313 552.159 186.873 550.851 187.049C550.067 187.137 548.846 187.225 548.149 187.225C545.533 187.225 544.225 185.993 544.225 182.648V153.861H551.462Z"
      fill="#0B1419"
    />
    <path
      d="M583.534 147.523C576.472 147.523 572.026 151.044 569.584 155.445V130.004C569.584 129.212 569.236 128.771 568.451 128.771H564.441C563.656 128.771 563.307 129.212 563.307 130.004V190.483C563.307 191.275 563.656 191.715 564.441 191.715H568.451C569.236 191.715 569.584 191.275 569.584 190.483V165.745C569.584 158.086 574.903 153.421 581.965 153.421C589.027 153.421 593.037 158.086 593.037 165.745V190.483C593.037 191.275 593.299 191.715 594.083 191.715H598.181C598.966 191.715 599.314 191.275 599.314 190.483V165.129C599.314 153.685 592.863 147.523 583.534 147.523Z"
      fill="#0B1419"
    />
    <path
      d="M651.752 167.946C651.752 157.382 644.428 147.523 631.787 147.523C619.058 147.523 610.339 157.294 610.339 169.971C610.339 182.56 618.97 192.771 632.92 192.771C638.849 192.771 644.341 190.923 648.177 187.225C648.788 186.609 649.136 186.169 648.526 185.377L647.044 183.352C646.695 182.912 646.172 182.912 645.736 183.264C642.423 185.817 638.674 187.401 633.443 187.401C623.068 187.401 617.227 180.975 616.442 172.436H648.613C651.142 172.436 651.752 171.115 651.752 167.946ZM631.699 153.157C640.331 153.157 645.91 159.055 646.172 167.506H616.442C617.227 158.791 623.242 153.157 631.699 153.157Z"
      fill="#0B1419"
    />
    <path
      d="M682.802 148.403C682.018 147.875 680.623 147.523 678.879 147.523C673.561 147.523 670.684 150.78 669.027 154.829L668.853 149.899C668.853 148.931 668.504 148.579 667.632 148.579H663.971C663.186 148.579 662.837 149.019 662.837 149.811V190.483C662.837 191.275 663.186 191.715 663.971 191.715H667.981C668.766 191.715 669.115 191.275 669.115 190.483V165.129C669.115 160.287 671.207 153.333 678.879 153.333C679.751 153.333 680.797 153.421 681.931 153.773C682.89 154.037 683.5 153.861 683.5 152.893V149.899C683.5 149.107 683.326 148.755 682.802 148.403Z"
      fill="#0B1419"
    />
  </svg>
);

export default SvgSensibleLogoStacked;
