import { CurrencyUnit } from '../utils/types/Currency/Currency.types';
import {
  payoutProviderSupportedCurrencies,
  type PayoutAccount,
  PayoutProvider,
} from '../utils/types/Entity';

/* 
  Returns the default payout account from a list of payout accounts, if one exists.
*/
export const getDefaultPayoutAccount = (
  payoutAccounts: PayoutAccount[],
): PayoutAccount | undefined => {
  return payoutAccounts.find((account) => account.isDefault);
};

/* 
  Returns a boolean value denoting whether or not a provider
  can be used to process payouts in a given currency.
*/
export const payoutProviderSupportsCurrency = (
  provider: PayoutProvider,
  currency: CurrencyUnit,
): boolean => {
  return payoutProviderSupportedCurrencies[provider].includes(currency);
};

/* 
  Returns a boolean value denoting whether or not a ACH provider
  is under temporary maintenance.
*/
//TODO: refactor to determine the maintenance status of default account
export const achProviderMaintenance = (
  defaultAccount: PayoutAccount | null,
  underMaintenance: boolean | undefined,
): boolean | undefined => {
  const account = defaultAccount?.providerName === PayoutProvider.ACH;
  return account && underMaintenance;
};

/* 
  Returns a boolean value denoting whether or not a payout account
  can be used to process payouts in a given currency.
*/
export const payoutAccountSupportsCurrency = (
  payoutAccount: PayoutAccount,
  currency: CurrencyUnit,
): boolean => {
  return payoutProviderSupportsCurrency(payoutAccount.providerName, currency);
};

/* 
  Returns a boolean value denoting whether or not a default payout account,
  if one exists, can be used to process payouts in a given currency.
*/
export const defaultPayoutAccountSupportsCurrency = (
  payoutAccounts: PayoutAccount[],
  currency: CurrencyUnit,
): boolean => {
  const defaultPayoutAccount = getDefaultPayoutAccount(payoutAccounts);

  if (defaultPayoutAccount) {
    return payoutAccountSupportsCurrency(defaultPayoutAccount, currency);
  }

  return false;
};

/* 
  Returns a boolean value denoting whether or not a user has at least one existing,
  non-default payout account that can be used to process payouts in a given currency.
*/
export const alternatePayoutAccountSupportsCurrency = (
  payoutAccounts: PayoutAccount[],
  currency: CurrencyUnit,
): boolean => {
  return !!payoutAccounts.find((account) => {
    return (
      !account.isDefault && payoutAccountSupportsCurrency(account, currency)
    );
  });
};

/* 
  Returns a boolean value denoting whether or not a user has an existing
  account for a given provider.
*/
export const hasPayoutAccountForProvider = (
  payoutAccounts: PayoutAccount[],
  provider: PayoutProvider,
): boolean => {
  return !!payoutAccounts.find((account) => account.providerName === provider);
};

/* 
  Returns a list of payout providers that are not represented in a given
  list of payout accounts. Optionally filters additionally by whether the
  provider can be used to process payouts in a given currency (ie, filters ).
*/
export const additionalPayoutProviders = (
  payoutAccounts: PayoutAccount[],
  currency?: CurrencyUnit,
): PayoutProvider[] => {
  const providers = Object.keys(
    payoutProviderSupportedCurrencies,
  ) as PayoutProvider[];

  return providers.filter((provider) => {
    if (!hasPayoutAccountForProvider(payoutAccounts, provider)) {
      return !currency || payoutProviderSupportsCurrency(provider, currency);
    }

    return false;
  });
};

/*
  Returns a boolean value denoting whether additional providers are available, ie,
  a user does not have an existing account for the provider, that can be used to process
  payouts in a given currency.
*/
export const additionalPayoutProviderSupportsCurrency = (
  payoutAccounts: PayoutAccount[],
  currency: CurrencyUnit,
): boolean => {
  return !!additionalPayoutProviders(payoutAccounts, currency).length;
};

/*
  Returns a list of payout accounts filtered by their ability to
  process payouts in a given currency.
*/
export const payoutAccountsWithCurrencySupport = (
  payoutAccounts: PayoutAccount[],
  currency: CurrencyUnit,
): PayoutAccount[] => {
  return payoutAccounts.filter((account) => {
    return payoutAccountSupportsCurrency(account, currency);
  });
};
