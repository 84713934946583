import { useTranslation, Trans } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import styles from './ach-maintenance-notice.module.scss';

const ACHMaintenanceNotice: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="body-2"
        className={styles.maintenanceText}
        color="status red"
      >
        {t('sunshine.ach_maintenance_notice.explainer')}
      </Typography>

      <Typography variant="label-3" className={styles.maintenanceText}>
        <Trans i18nKey="sunshine.ach_maintenance_notice.customer_support">
          <a href={`mailto:${t('generic.label.customer_support_email')}`}>
            {t('generic.label.customer_support_email')}
          </a>
        </Trans>
      </Typography>
    </>
  );
};

export default ACHMaintenanceNotice;
