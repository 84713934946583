import { FC } from 'react';
import { BaseLayoutDisplayProps } from './BaseLayoutDisplay.types';
import styles from './base-layout-display.module.scss';
const BaseLayoutDisplay: FC<BaseLayoutDisplayProps> = ({
  displayContent,
  colorTheme,
  first,
  last,
}) => {
  return (
    <div
      className={`${styles.container} ${styles[colorTheme]} ${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        first && styles.first
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      } ${last && styles.last}`}
    >
      <div className={styles.contentWrapper}>{displayContent}</div>
    </div>
  );
};

export default BaseLayoutDisplay;
