import { Loss, isLoss } from './Loss.types';
import { isPayout, Payout } from './Payout.types';
import {
  arrayValidator,
  numberValidator,
  objectValidator,
  stringValidator,
} from '../../validate';
import { ValidationError } from '../../error';

export interface Claim {
  id: string;
  exposureID: string;
  createdAt: string;
  updatedAt: string;
  expiresAt?: string;
  coverageAmount: number;
  deductibleTierSatisfied: number;
  claimAmount: number;
  losses?: Loss[];
  payout: Payout | null;
  status: ClaimStatus;
}

export enum ClaimStatus {
  Open = 'open',
  Processing = 'processing',
  Paid = 'paid',
  Expired = 'expired',
  Canceled = 'canceled',
}

export function isClaimStatus(value: unknown): asserts value is ClaimStatus {
  switch (value) {
    case ClaimStatus.Open:
    case ClaimStatus.Processing:
    case ClaimStatus.Paid:
    case ClaimStatus.Expired:
    case ClaimStatus.Canceled:
      return;
    default:
      throw new ValidationError(value, 'claimStatus', [
        `error validating ClaimStatus: received ${JSON.stringify(value)}`,
      ]);
  }
}

export const stringToClaimStatus = (str: string): ClaimStatus | null => {
  if (typeof str !== 'string') return null;

  switch (str.toLowerCase()) {
    case 'open':
      return ClaimStatus.Open;
    case 'processing':
      return ClaimStatus.Processing;
    case 'paid':
      return ClaimStatus.Paid;
    case 'expired':
      return ClaimStatus.Expired;
    case 'canceled':
      return ClaimStatus.Canceled;
    default:
      return null;
  }
};

export function isClaim(value: unknown): asserts value is Claim {
  objectValidator(value, 'claim');

  const {
    id,
    exposureID,
    createdAt,
    updatedAt,
    expiresAt,
    coverageAmount,
    deductibleTierSatisfied,
    claimAmount,
    losses,
    payout,
    status,
  } = value;

  const requiredStrings = [
    { name: 'claim.id', value: id },
    { name: 'claim.exposureID', value: exposureID },
    { name: 'claim.createdAt', value: createdAt },
    { name: 'claim.updatedAt', value: updatedAt },
  ];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const optionalStrings = [{ name: 'expiresAt', value: expiresAt }];

  optionalStrings.forEach((str) => {
    if (str.value !== undefined) {
      stringValidator(str.value, str.name);
    }
  });

  const requiredNumbers = [
    { name: 'claim.coverageAmount', value: coverageAmount },
    { name: 'claim.deductibleTierSatisfied', value: deductibleTierSatisfied },
    { name: 'claim.claimAmount', value: claimAmount },
  ];

  requiredNumbers.forEach((num) => {
    numberValidator(num.value, num.name);
  });

  if (payout !== null) {
    isPayout(payout);
  }

  const optionalArrays = [
    { name: 'claim.losses', value: losses, typeguard: isLoss },
  ];
  optionalArrays.forEach((arr) => {
    arrayValidator(arr.value, arr.name, { typeguard: arr.typeguard });
  });

  isClaimStatus(status);
}
