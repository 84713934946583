import React, { createContext, useEffect, useState } from 'react';
import { Config } from '../types/config';
import { ConfigAPI } from '../api';

interface ConfigEnvelope {
  config?: Config;
  error?: unknown;
  ready: boolean;
  loading: boolean;
}

const DefaultConfigEnvelope = { ready: false, loading: false };

const ConfigContext = createContext<ConfigEnvelope>(DefaultConfigEnvelope);

const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [configEnvelope, setConfigEnvelope] = useState<ConfigEnvelope>(
    DefaultConfigEnvelope,
  );

  useEffect(() => {
    if (configEnvelope.ready || configEnvelope.loading) {
      return;
    }
    setConfigEnvelope({ ...configEnvelope, loading: true });
    const getConfig = async (): Promise<void> => {
      try {
        const config = await ConfigAPI.getConfig();
        setConfigEnvelope({ ready: true, loading: false, config });
      } catch (error) {
        setConfigEnvelope({ ready: true, loading: false, error });
      }
    };
    void getConfig();
  }, [configEnvelope]);

  if (!configEnvelope.ready) {
    return <></>;
  }

  return (
    <ConfigContext.Provider value={configEnvelope}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigProvider, ConfigContext };
