import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDarkSensibleBadge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 105 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M104.628 35.6853C104.628 51.8543 90.2921 64.9619 72.6072 64.9619C54.9224 64.9619 40.5859 51.8543 40.5859 35.6853C40.5859 19.5163 54.9224 6.40869 72.6072 6.40869C90.2921 6.40869 104.628 19.5163 104.628 35.6853Z"
      fill="#6D8EA0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.65494 5.2443L4.65494 37.5151C4.65494 55.3378 19.1031 69.7859 36.9257 69.7859C54.7484 69.7859 69.1965 55.3378 69.1965 37.5151C69.1965 19.6924 54.7484 5.24431 36.9257 5.24431L4.65494 5.2443ZM36.9257 74.1108C57.137 74.1108 73.5215 57.7264 73.5215 37.5151C73.5215 17.3038 57.137 0.919356 36.9257 0.919354L0.329993 0.919351L0.32999 37.5151C0.329988 57.7264 16.7145 74.1108 36.9257 74.1108Z"
      fill="#3D6881"
    />
    <path
      d="M2.16016 37.8307V0.919434L41.8927 3.38018L56.7924 10.7624L66.274 18.1447L71.6921 37.8307L66.274 57.5166L51.8258 69.8204L34.4428 72.2811L14.5766 66.6157L4.64344 47.3875L2.16016 37.8307Z"
      fill="#3D6881"
    />
  </svg>
);

export default SvgDarkSensibleBadge;
