import { ValidationError } from '../error';

/**
 * @function objectValidator
 * @description Validates that a variable is present, is an object, and satisfies any optional constraints.
 * Does not check that constraints are strictly compatible - throws an error at the first failed validation encountered.
 */
export function objectValidator(
  /** The variable value to validate */
  value: unknown,
  /** The variable name for error message purposes */
  name: string,
  /** Optional parameters to validate against */
  opts?: {
    /** Pass keys to require object to contain specific keys */
    keys?: string[];
    /** Error message to display if keys triggers */
    keysErrorMsg?: string;
    /** Pass errorMsg to supply a catch-all error message on failed validation */
    errorMsg?: string;
  },
): asserts value is Record<string, unknown> {
  // Variable must exist
  if (typeof value === 'undefined') {
    throw new ValidationError(value, name, ['value is undefined']);
  }
  if (value === null) {
    throw new ValidationError(value, name, ['value is null']);
  }

  // Variable must be an non-null object
  if (value instanceof Array) {
    throw new ValidationError(value, name, ['value is of type Array']);
  }
  if (typeof value !== 'object') {
    throw new ValidationError(value, name, [
      `value is not of type object, received type ${typeof value}`,
    ]);
  }

  // Handle keys
  if (opts?.keys && opts.keys instanceof Array) {
    for (let idx = 0; idx < opts.keys.length; idx++) {
      const key = opts.keys[idx];

      if (typeof key !== 'string') {
        throw new ValidationError(value, name, [
          `key is malformatted, received key of type ${typeof key}`,
        ]);
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!value.hasOwnProperty(key)) {
        const keysError = opts.keysErrorMsg || `missing key ${key}`;
        throw new ValidationError(value, name, [keysError]);
      }
    }
  }
}
