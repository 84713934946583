import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../sharedComponents/TextInput/TextInput';
import { assets } from '../../sharedComponents/assets';
import Typography from '../../sharedComponents/Typography/Typography';
import { validators } from '../../utils';
import Button from '../../components/Button/Button';
import { fieldKey, InputFieldData, InputFieldDisplay } from './Profile.types';
import { PageProps } from '../Page.types';
import { useActions, useStore } from '../../store/hooks';
import { AuthAPI, UserAPI } from '../../api';
import styles from './profile.module.scss';
import Footer from '../../components/Footer/Footer';

const EditIcon = assets.svg.sensible.EditIcon;
const Success = assets.svg.sensible.Success;
const Exit = assets.svg.sensible.SvgX;

const validatePhone = (phone: string): boolean => {
  let validFormat = validators.phoneValidator(phone);
  if (!validFormat) {
    validFormat = validators.intlPhoneValidator(phone);
  }

  return validFormat;
};

const Profile: React.FunctionComponent<PageProps> = ({
  layout: Layout,
  navigation: Navigation,
}) => {
  const { t } = useTranslation();

  const NAME_KEY = 'name';
  const PHONE_KEY = 'phone';

  const nameRef = useRef<HTMLInputElement>();
  const phoneRef = useRef<HTMLInputElement>();

  const refs = useMemo(() => {
    return {
      [NAME_KEY]: nameRef,
      [PHONE_KEY]: phoneRef,
    };
  }, []);

  const store = useStore();
  const user = store.user.user;
  const dispatch = useActions();

  const [inputValues, setInputValues] = useState<InputFieldData>({
    [NAME_KEY]: user?.name || '',
    [PHONE_KEY]: user?.phone || '',
  });
  const resetInitialInputValues = (): void => {
    setInputValues({
      [NAME_KEY]: user?.name || '',
      [PHONE_KEY]: user?.phone || '',
    });
  };

  const [displayState, setDisplayState] = useState<{
    [NAME_KEY]: InputFieldDisplay;
    [PHONE_KEY]: InputFieldDisplay;
  }>({
    [NAME_KEY]: 'read-only',
    [PHONE_KEY]: 'read-only',
  });
  const resetInitialDisplayState = (): void => {
    setDisplayState({
      [NAME_KEY]: 'read-only',
      [PHONE_KEY]: 'read-only',
    });
  };

  const [notification, setNotification] = useState<string>('');
  const notificationAlert = (alert: string): void => {
    setNotification(alert);
    setTimeout(() => {
      setNotification('');
    }, 3000);
  };

  const [inputError, setInputError] = useState<InputFieldData>({
    [NAME_KEY]: '',
    [PHONE_KEY]: '',
  });
  const resetInitialErrorState = (): void => {
    setInputError({ [NAME_KEY]: '', [PHONE_KEY]: '' });
  };

  const [passwordResetRequest, setPasswordResetRequest] = useState<string>('');

  const { getAccessTokenSilently } = useAuth0();

  const nameInputValidator = (name: string): boolean => {
    const nonEmpty = name.length > 0;
    const hasChanged = name !== user?.name;
    const isFullName = name.split(' ').length > 1;
    if (!isFullName) {
      setInputError({
        ...inputError,
        [NAME_KEY]: t('error.first_last_name_required'),
      });
    }

    return nonEmpty && hasChanged && isFullName;
  };

  const phoneInputValidator = (phone: string): boolean => {
    const nonEmpty = phone.length > 0;
    const hasChanged = phone !== user?.phone;
    const validFormat = validatePhone(phone);

    if (!validFormat) {
      setInputError({
        ...inputError,
        [PHONE_KEY]: t('error.phone_number_invalid'),
      });
    }

    return nonEmpty && hasChanged && validFormat;
  };

  const fieldValidators = {
    [NAME_KEY]: nameInputValidator,
    [PHONE_KEY]: phoneInputValidator,
  };

  const submitFieldChange = async (key: fieldKey): Promise<void> => {
    const isValid = fieldValidators[key](inputValues[key]);
    if (!isValid) {
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const reqBody = {
        [key]: inputValues[key],
      };

      const updatedUser = await UserAPI.updateUser(token, reqBody);

      dispatch.setUser(updatedUser);

      notificationAlert(t('sunshine.label.info_updated'));
      resetInitialDisplayState();
    } catch (error) {
      notificationAlert(t('error.smth_went_wrong'));
    }
  };

  useEffect(() => {
    const inEdit = Object.keys(displayState).find((k) => {
      return displayState[k as fieldKey] === 'edit-save';
    });

    if (inEdit && refs[inEdit as fieldKey]) {
      refs[inEdit as fieldKey].current?.focus();
    }
  }, [displayState, refs]);

  const setFieldToEdit = (key: fieldKey): void => {
    const displayStateCopy = { ...displayState };

    Object.keys(displayStateCopy).forEach((k) => {
      displayStateCopy[k as fieldKey] = k === key ? 'edit-save' : 'read-only';
    });

    setDisplayState({ ...displayStateCopy });
  };

  const handleIconClick = (key: fieldKey): void => {
    if (displayState[key] === 'read-only') {
      setNotification('');
      resetInitialErrorState();
      resetInitialInputValues();
      setFieldToEdit(key);
    } else {
      void submitFieldChange(key);
    }
  };

  const renderInputButton = (key: fieldKey): JSX.Element => {
    const isReadOnly = displayState[key] === 'read-only';

    const isEmpty = inputValues[key].length === 0;
    const hasNotChanged = inputValues[key] === user?.[key];

    return (
      <>
        {isReadOnly ? null : (
          <button
            className={styles.iconButtonX}
            onClick={resetInitialDisplayState}
            name="ButtonX"
            title="close"
          >
            <Exit />
          </button>
        )}
        <button
          className={styles.iconButton}
          onClick={(): void => void handleIconClick(key)}
          name="ReadAndSave"
          title="save"
          disabled={isReadOnly ? false : isEmpty || hasNotChanged}
        >
          {isReadOnly ? <EditIcon /> : <Success />}
        </button>
      </>
    );
  };

  const requestPasswordChange = async (): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      await AuthAPI.updateUserPassword(token, user.langLocale);
      setPasswordResetRequest(t('label.password_reset_instructions'));
    } catch (error) {
      setPasswordResetRequest(t('error.unable_to_process_req'));
    }
  };

  const nameInputRef = refs[NAME_KEY]
    ? { inputRef: refs[NAME_KEY] as React.MutableRefObject<HTMLInputElement> }
    : {};

  const phoneInputRef = refs[PHONE_KEY]
    ? { inputRef: refs[PHONE_KEY] as React.MutableRefObject<HTMLInputElement> }
    : {};

  return (
    <Layout navigation={<Navigation />} footer={<Footer />}>
      <div className={styles.formContainer}>
        <div className={styles.fieldWrapper}>
          {displayState[NAME_KEY] === 'read-only' ? (
            <Typography variant="body-1">{user?.name} </Typography>
          ) : (
            <TextInput
              {...nameInputRef}
              value={inputValues[NAME_KEY]}
              handleChange={(value): void =>
                setInputValues({ ...inputValues, [NAME_KEY]: value })
              }
              label={t('label.name')}
              errorMsg={inputError[NAME_KEY]}
            />
          )}
          {renderInputButton(NAME_KEY)}
        </div>
        <div className={styles.fieldWrapper}>
          {displayState[PHONE_KEY] === 'read-only' ? (
            <Typography variant="body-1">{user?.phone} </Typography>
          ) : (
            <TextInput
              {...phoneInputRef}
              value={inputValues[PHONE_KEY]}
              handleChange={(value): void =>
                setInputValues({
                  ...inputValues,
                  [PHONE_KEY]: value,
                })
              }
              label={t('label.phone')}
              errorMsg={inputError[PHONE_KEY]}
            />
          )}
          {renderInputButton(PHONE_KEY)}
        </div>
        <div className={styles.fieldWrapper}>
          <Typography variant="body-1">{user?.email} </Typography>
        </div>

        {notification && (
          <div className={styles.notificationWrapper}>
            <Typography variant="body-2" align="center" color="status green">
              {notification}
            </Typography>{' '}
          </div>
        )}
        <hr className={styles.divider} />
        <div className={styles.passwordBlockPositionWrapper}>
          {passwordResetRequest ? (
            <Typography variant="body-2" align="center" color="status green">
              {passwordResetRequest}
            </Typography>
          ) : (
            <Button
              colorVariant="ocean"
              label={t('action.change_password')}
              clickHandling={(): void => void requestPasswordChange()}
              errorMsg={passwordResetRequest ? passwordResetRequest : ''}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
