import { User } from '@auth0/auth0-react';
import { UserMetadata } from '../types/auth';

const USER_APP_METADATA_KEY = 'https://sensibleweather.io/sunshine';

export const hasAcceptedTermsAndPolicies = (
  authUser: User | undefined,
): boolean => {
  if (!authUser) return false;
  const sunshine = authUser[USER_APP_METADATA_KEY] as UserMetadata;
  const required = [
    sunshine?.accepted_toc,
    sunshine?.accepted_privacy_policy,
    sunshine?.marketing_contactable,
  ];
  return required.every((r) => !!r);
};

export const acceptedTermsAndPoliciesMetadata = {
  [USER_APP_METADATA_KEY]: {
    accepted_toc: true,
    accepted_privacy_policy: true,
    marketing_contactable: true,
  },
};

/**
 * Returns a `Bearer token` header to be added or spread to the `headers` field of a request
 * either on the `server` or the `client` (works for both making the request, this is generic)
 */
export const bearerAuthHeader = (token: string): { Authorization: string } => {
  return { Authorization: `Bearer ${token}` };
};
