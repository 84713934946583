import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import enFallbackResources from './locales/en/translation.json';
import frFallbackResources from './locales/fr/translation.json';
import svFallbackResources from './locales/sv/translation.json';
import { i18nextHygraphPlugin } from '../HygraphPlugin';
import { i18NextSupportedLngsConfig } from '../hooks/useUserLanguageConfig';
import { ConfigAPI } from '../api/index';

type HygraphPluginOptions = Record<string, string | number>;

const configPromise = ConfigAPI.getConfig();

const getHygraphPluginOptions = async (): Promise<HygraphPluginOptions> => {
  const res = await configPromise;
  const {
    hygraphRefreshRateMinutes,
    hygraphURL,
    hygraphStage,
    hygraphPassword,
    hygraphUsername,
  } = res;
  return {
    hygraphUrl: hygraphURL,
    hygraphStage: hygraphStage,
    hygraphUsername: hygraphUsername,
    hygraphPassword: hygraphPassword,
    reloadInterval: 60 * 1000 * (Number(hygraphRefreshRateMinutes) || 60),
  };
};

const getHygraphPluginFallbackOptions =
  async (): Promise<HygraphPluginOptions> => {
    const { hygraphRefreshRateMinutes, hygraphStage, hygraphFallbackURL } =
      await configPromise;
    return {
      hygraphUrl: hygraphFallbackURL,
      hygraphStage: hygraphStage,
      reloadInterval: 60 * 1000 * (Number(hygraphRefreshRateMinutes) || 60),
    };
  };

void i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .use(LanguageDetector)
  .use(ChainedBackend)
  .init({
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
    },
    supportedLngs: i18NextSupportedLngsConfig,
    defaultNS: 'translation',
    ns: 'translation',
    preload: [],
    backend: {
      backends: [
        LocalStorageBackend,
        i18nextHygraphPlugin,
        i18nextHygraphPlugin,
        resourcesToBackend({
          en: {
            translation: enFallbackResources,
          },
          fr: {
            translation: frFallbackResources,
          },
          sv: {
            translation: svFallbackResources,
          },
        }),
      ],
      backendOptions: [
        { expirationTime: 60 * 60 * 1000 },
        getHygraphPluginOptions(),
        getHygraphPluginFallbackOptions(),
      ],
    },

    nonExplicitSupportedLngs: true,
  });

export { i18n };
