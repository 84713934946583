import { err, auth } from '../utils';
import axios, { AxiosResponse } from 'axios';

const URL = '/api/claim';

const approveClaims = async (
  token: string,
  claims: { id: string }[],
): Promise<void> => {
  try {
    const res: AxiosResponse<void> = await axios.patch(
      `${URL}/approve`,
      {
        claims,
      },
      {
        headers: auth.bearerAuthHeader(token),
      },
    );

    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }

    return;
  } catch (e) {
    throw err.handleError(e, 'error approving claims');
  }
};

export default {
  approveClaims,
};
