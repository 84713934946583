import { ValidationError } from '../error';

/**
 * @function stringValidator
 * @description Validates that a variable is present, is a string, and satisfies any optional constraints.
 * Does not check that constraints are strictly compatible - throws an error at the first failed validation encountered.
 */
export function stringValidator(
  /** The variable value to validate */
  value: unknown,
  /** The variable name for error message purposes */
  name: string,
  /** Optional parameters to validate against */
  opts?: {
    /** Pass nonEmpty to require string being at least length 1 and not an empty string */
    nonEmpty?: boolean;
    /** Error message to display if nonEmpty triggers */
    nonEmptyErrorMsg?: string;
    /** Pass minLength to require string being at least minLength # of characters */
    minLength?: number;
    /** Error message to display if minLength triggers */
    minLengthErrorMsg?: string;
    /** Pass maxLength to require string being at most maxLength # of characters */
    maxLength?: number;
    /** Error message to display if maxLength triggers */
    maxLengthErrorMsg?: string;
    /** Pass errorMsg to supply a catch-all error message on failed validation */
    errorMsg?: string;
  },
): asserts value is string {
  // Variable must exist
  if (typeof value === 'undefined') {
    throw new ValidationError(value, name, ['value is undefined']);
  }

  // Variable must be a string
  if (typeof value !== 'string') {
    throw new ValidationError(value, name, [
      `value is not of type string, received type ${typeof value}`,
    ]);
  }

  // Handle nonEmpty
  if (opts?.nonEmpty && (!value || !value.length)) {
    const nonEmptyError = opts.nonEmptyErrorMsg || 'value is empty';
    throw new ValidationError(value, name, [nonEmptyError]);
  }

  // Handle minLength
  if (opts?.minLength && value.length < Math.max(opts.minLength, 0)) {
    const minLengthError =
      opts.minLengthErrorMsg ||
      `value does not meet minimum length of ${opts.minLength}`;

    throw new ValidationError(value, name, [minLengthError]);
  }

  // Handle maxLength
  if (opts?.maxLength && value.length > opts.maxLength) {
    const maxLengthError =
      opts.maxLengthErrorMsg ||
      `value is longer than maximum length of ${opts.maxLength}`;

    throw new ValidationError(value, name, [maxLengthError]);
  }
}
