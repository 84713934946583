import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { type PolicyBundle } from '../../utils/types/Entity';

interface PolicyBundleState {
  ready: boolean;
  policyBundles: PolicyBundle[];
  selectedPolicyBundleID: string;
}

const policyBundlesInitialState: PolicyBundleState = {
  ready: false,
  policyBundles: [],
  selectedPolicyBundleID: '',
};

const policyBundlesSlice = createSlice({
  name: 'policyBundles',
  initialState: policyBundlesInitialState,
  reducers: {
    setPolicyBundles: (
      state: PolicyBundleState,
      action: PayloadAction<PolicyBundle[]>,
    ) => {
      state.policyBundles = action.payload;
    },
    setSelectedPolicyBundle: (
      state: PolicyBundleState,
      action: PayloadAction<string>,
    ) => {
      state.selectedPolicyBundleID = action.payload;
    },
    setPolicyBundlesReady: (state: PolicyBundleState) => {
      state.ready = true;
    },
  },
});

export const policyBundlesActions = policyBundlesSlice.actions;

export const policyBundlesReducer = policyBundlesSlice.reducer;
