import { FC, ReactNode } from 'react';
import { IconTextButtonProps } from './IconTextButton.types';
import styles from './icon-text-button.module.scss';
import Typography from '../Typography/Typography';

const IconTextButton: FC<IconTextButtonProps> = ({
  onClickHandler,
  href,
  disabled = false,
  icon,
  label,
  format = 'title',
}) => {
  const Icon = icon;

  const ContentWrapper = ({
    children,
  }: {
    children: ReactNode;
  }): JSX.Element => {
    if (href?.length) {
      return (
        <a
          className={styles.contentWrapperButton}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    } else if (onClickHandler) {
      return (
        <button
          className={styles.contentWrapperButton}
          onClick={onClickHandler}
          disabled={disabled}
        >
          {children}
        </button>
      );
    } else {
      return <div className={styles.contentWrapperDiv}>{children}</div>;
    }
  };

  return (
    <div className={styles.container}>
      <ContentWrapper>
        <div className={styles.iconWrapper}>
          <Icon />
        </div>
        <Typography variant="body-2" format={format}>
          {label}
        </Typography>
      </ContentWrapper>
    </div>
  );
};

export default IconTextButton;
