export type SensibleDate = Date;

/* a format string added here needs to be added to the array of supported formats */
export type FormatString =
  | 'MM/dd/yy'
  | 'MM/dd/yyyy'
  | 'MM-dd-yy'
  | 'MM-dd-yyyy'
  | 'MMMM d'
  | 'MMMM d, yyyy'
  | 'MMM. d'
  | 'MMM. d, yyyy'
  | 'yyyy-MM-dd'
  | 'EEEE, MMM dd'
  | 'EEEE, MMM. dd'
  | 'EEEE, MMM dd, yyyy'
  | 'EEEE, MMM. dd, yyyy';

export enum UnitTime {
  Years = 'years',
  Months = 'months',
  Weeks = 'weeks',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds',
  Milliseconds = 'milliseconds',
}
