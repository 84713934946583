import { Glyph } from '@visx/glyph';

type GlyphProps = {
  left: number;
  top: number;
};
const CloudGlyph = ({ left, top }: GlyphProps) => {
  return (
    <Glyph left={left} top={top}>
      <svg width="64" height="64" viewBox="-5 0 120 120">
        <path
          d="M28.1167 14.9122C30.3684 13.796 32.8471 13.2138 35.3603 13.2109C37.8736 13.208 40.3536 13.7844 42.6079 14.8955C44.8622 16.0065 46.8301 17.6223 48.3587 19.6172C49.8872 21.6121 50.9354 23.9326 51.4217 26.3983C51.908 28.864 51.8194 31.4087 51.1628 33.8346"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.3208 1V6.03125"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.9927 28.6719H57.9614"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.8847 9.10785L51.3276 12.6649"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.873 46.04L55.873 46.54C56.1178 46.54 56.3267 46.3627 56.3664 46.1211L55.873 46.04ZM45.1113 35.2574L44.648 35.4454L44.7986 35.8164L45.1935 35.7506L45.1113 35.2574ZM31.8174 28.7461L31.321 28.8059L31.391 29.3873L31.9543 29.227L31.8174 28.7461ZM9.8487 31.7722L9.91428 32.2679L10.4196 32.201L10.343 31.6971L9.8487 31.7722ZM1.88348 46.033L1.42668 46.2363C1.50697 46.4168 1.68594 46.533 1.88341 46.533L1.88348 46.033ZM56.3664 46.1211C56.4538 45.5895 56.5052 45.0335 56.5052 44.4678H55.5052C55.5052 44.9734 55.4592 45.4749 55.3797 45.9589L56.3664 46.1211ZM56.5052 44.4678C56.5052 39.0299 52.1005 34.6252 46.6626 34.6252V35.6252C51.5482 35.6252 55.5052 39.5822 55.5052 44.4678H56.5052ZM46.6626 34.6252C46.1035 34.6252 45.5541 34.6767 45.0291 34.7642L45.1935 35.7506C45.6702 35.6711 46.1644 35.6252 46.6626 35.6252V34.6252ZM45.5746 35.0694C43.8522 30.8251 39.6944 27.8287 34.8296 27.8287V28.8287C39.2726 28.8287 43.0731 31.5645 44.648 35.4454L45.5746 35.0694ZM34.8296 27.8287C33.7396 27.8287 32.6779 27.9813 31.6805 28.2652L31.9543 29.227C32.863 28.9683 33.8326 28.8287 34.8296 28.8287V27.8287ZM32.3138 28.6863C31.6231 22.9495 26.7379 18.5 20.8122 18.5V19.5C26.2256 19.5 30.69 23.5653 31.321 28.8059L32.3138 28.6863ZM20.8122 18.5C14.4143 18.5 9.22348 23.6908 9.22348 30.0887H10.2235C10.2235 24.2431 14.9666 19.5 20.8122 19.5V18.5ZM9.22348 30.0887C9.22348 30.6835 9.26699 31.2721 9.35437 31.8473L10.343 31.6971C10.2634 31.1732 10.2235 30.6348 10.2235 30.0887H9.22348ZM9.78311 31.2765C4.54276 31.9699 0.5 36.4574 0.5 41.887H1.5C1.5 36.9652 5.16507 32.8962 9.91428 32.2679L9.78311 31.2765ZM0.5 41.887C0.5 43.4346 0.83587 44.9089 1.42668 46.2363L2.34028 45.8297C1.80413 44.6251 1.5 43.2889 1.5 41.887H0.5ZM1.88341 46.533L55.873 46.54L55.8731 45.54L1.88354 45.533L1.88341 46.533Z"
          fill="#0B1419"
        />
      </svg>
    </Glyph>
  );
};

export default CloudGlyph;
