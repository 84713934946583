export const phoneValidator = (str: string): boolean => {
  const r =
    /^(\+1|1)?[-. ]?\(?([2-9][0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  return r.test(str);
};

export const intlPhoneValidator = (str: string): boolean => {
  const r = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  return r.test(str);
};
