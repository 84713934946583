import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgOceanSensibleLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 180 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M69.2625 23.5355C69.2625 34.1488 59.8436 42.7527 48.2247 42.7527C36.6059 42.7527 27.187 34.1488 27.187 23.5355C27.187 12.9222 36.6059 4.31836 48.2247 4.31836C59.8436 4.31836 69.2625 12.9222 69.2625 23.5355Z"
      fill="#CED9DF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.53605 3.48787L3.53604 24.6258C3.53604 36.3 13.0398 45.7638 24.7632 45.7638C36.4866 45.7638 45.9903 36.3 45.9903 24.6258C45.9903 12.9516 36.4866 3.48787 24.7632 3.48787L3.53605 3.48787ZM24.7632 48.5967C38.0578 48.5967 48.8352 37.8646 48.8352 24.6258C48.8352 11.3871 38.0578 0.654948 24.7632 0.654947L0.69117 0.654945L0.691168 24.6258C0.691167 37.8645 11.4686 48.5967 24.7632 48.5967Z"
      fill="#9EB3C0"
    />
    <path
      d="M1.90527 24.8249V0.655273L28.029 2.26658L37.8255 7.10052L44.0595 11.9345L47.6219 24.8249L44.0595 37.7154L34.56 45.772L23.1308 47.3833L10.069 43.6735L3.53801 31.0828L1.90527 24.8249Z"
      fill="#9EB3C0"
    />
    <path
      d="M32.2448 36.8161C36.4276 36.8161 40.0554 34.6553 40.0981 30.4609C40.1408 27.2833 38.0494 25.5038 35.4032 24.4446L32.4582 23.3007C31.3059 22.877 30.4522 22.2838 30.4522 21.267C30.4522 20.3773 31.0925 19.6146 32.4582 19.6146C33.696 19.6146 34.891 20.2078 36.2568 21.3517C36.641 21.6483 36.9397 21.6907 37.2385 21.3517L39.1164 19.1062C39.3298 18.852 39.4152 18.513 39.1591 18.2165C37.4092 16.1404 34.891 15.0389 32.1595 15.0389C28.2755 15.0389 24.8611 17.3267 24.8611 21.3094C24.8611 24.1904 26.739 25.9275 29.4279 26.9867L32.0741 28.0459C33.6106 28.6814 34.2508 29.1898 34.2508 30.2067C34.2508 31.393 33.3119 31.9438 31.9034 31.9438C30.2815 31.9438 28.8304 31.2235 27.0805 29.9101C26.739 29.6559 26.3549 29.5711 26.0134 30.0796L24.5196 32.1556C24.2209 32.6217 24.1782 33.0877 24.4343 33.3843C26.0988 35.3332 28.745 36.8161 32.2448 36.8161Z"
      fill="#0A4161"
    />
    <path
      d="M63.8003 24.953C63.8003 19.6994 60.1297 15.0389 53.7703 15.0389C47.3255 15.0389 43.0147 19.7417 43.0147 25.8427C43.0147 32.1132 47.6243 36.8161 54.4959 36.8161C57.4408 36.8161 60.1297 35.9687 61.9223 34.1893C62.3064 33.8503 62.3491 33.5538 62.1357 33.2572L60.5565 31.0964C60.3431 30.7998 60.1297 30.7575 59.8309 30.9269C58.1237 31.9014 56.5872 32.198 54.9653 32.198C51.5509 32.198 49.4595 30.7575 48.8193 28.1306H61.4955C63.3308 28.1306 63.8003 26.9867 63.8003 24.953ZM53.813 19.8688C56.3311 19.8688 58.2091 21.2246 58.5505 24.0209H48.734C49.2034 21.267 51.2094 19.8688 53.813 19.8688Z"
      fill="#0A4161"
    />
    <path
      d="M79.9629 15.0389C76.8472 15.0389 74.8412 16.5641 73.6462 18.6825L73.4755 16.2252C73.4755 15.7167 73.2194 15.5473 72.8352 15.5473H68.3964C68.0123 15.5473 67.7562 15.8015 67.7562 16.1828V35.6722C67.7562 36.0535 68.0123 36.3077 68.3964 36.3077H73.006C73.3901 36.3077 73.6462 36.0535 73.6462 35.6722V24.9106C73.6462 21.9872 75.268 20.4196 77.7435 20.4196C80.2617 20.4196 81.6275 21.9872 81.6275 24.9106V35.6722C81.6275 36.0535 81.8836 36.3077 82.2677 36.3077H86.8345C87.2187 36.3077 87.5174 36.0535 87.5174 35.6722V23.5972C87.5174 18.1741 84.3591 15.0389 79.9629 15.0389Z"
      fill="#0A4161"
    />
    <path
      d="M99.6838 36.8161C103.866 36.8161 107.494 34.6553 107.537 30.4609C107.58 27.2833 105.488 25.5038 102.842 24.4446L99.8972 23.3007C98.7448 22.877 97.8912 22.2838 97.8912 21.267C97.8912 20.3773 98.5314 19.6146 99.8972 19.6146C101.135 19.6146 102.33 20.2078 103.696 21.3517C104.08 21.6483 104.379 21.6907 104.677 21.3517L106.555 19.1062C106.769 18.852 106.854 18.513 106.598 18.2165C104.848 16.1404 102.33 15.0389 99.5984 15.0389C95.7145 15.0389 92.3 17.3267 92.3 21.3094C92.3 24.1904 94.178 25.9275 96.8669 26.9867L99.5131 28.0459C101.05 28.6814 101.69 29.1898 101.69 30.2067C101.69 31.393 100.751 31.9438 99.3423 31.9438C97.7205 31.9438 96.2693 31.2235 94.5194 29.9101C94.178 29.6559 93.7938 29.5711 93.4524 30.0796L91.9586 32.1556C91.6598 32.6217 91.6171 33.0877 91.8732 33.3843C93.5378 35.3332 96.184 36.8161 99.6838 36.8161Z"
      fill="#0A4161"
    />
    <path
      d="M114.759 11.8612C116.637 11.8612 118.003 10.4207 118.003 8.59889C118.003 6.81942 116.637 5.37891 114.759 5.37891C112.881 5.37891 111.473 6.81942 111.473 8.59889C111.473 10.4207 112.881 11.8612 114.759 11.8612ZM112.412 36.3077H117.064C117.448 36.3077 117.704 36.0535 117.704 35.6722V16.1828C117.704 15.8015 117.448 15.5473 117.064 15.5473H112.412C112.028 15.5473 111.772 15.8015 111.772 16.1828V35.6722C111.772 36.0535 112.028 36.3077 112.412 36.3077Z"
      fill="#0A4161"
    />
    <path
      d="M135.236 15.0389C132.291 15.0389 130.157 16.1404 128.749 18.0046V6.64995C128.749 6.26864 128.493 6.01443 128.108 6.01443H123.456C123.072 6.01443 122.816 6.26864 122.816 6.64995V35.6722C122.816 36.0535 123.115 36.3077 123.499 36.3077H127.682C128.066 36.3077 128.322 36.0535 128.322 35.6722L128.407 33.3843C129.858 35.5027 132.078 36.8161 135.236 36.8161C141.083 36.8161 145.138 32.1132 145.138 25.9275C145.138 19.7841 141.083 15.0389 135.236 15.0389ZM133.998 31.4777C130.84 31.4777 128.663 29.2746 128.663 25.9275C128.663 22.5804 130.84 20.3772 133.998 20.3772C137.071 20.3772 139.376 22.6228 139.376 25.9275C139.376 29.2746 137.071 31.4777 133.998 31.4777Z"
      fill="#0A4161"
    />
    <path
      d="M149.798 36.3077H154.451C154.835 36.3077 155.091 36.0535 155.091 35.6722V6.64995C155.091 6.26864 154.835 6.01443 154.451 6.01443H149.798C149.414 6.01443 149.158 6.26864 149.158 6.64995V35.6722C149.158 36.0535 149.414 36.3077 149.798 36.3077Z"
      fill="#0A4161"
    />
    <path
      d="M179.88 24.953C179.88 19.6994 176.21 15.0389 169.85 15.0389C163.405 15.0389 159.095 19.7417 159.095 25.8427C159.095 32.1132 163.704 36.8161 170.576 36.8161C173.521 36.8161 176.21 35.9687 178.002 34.1893C178.386 33.8503 178.429 33.5538 178.216 33.2572L176.637 31.0964C176.423 30.7998 176.21 30.7575 175.911 30.9269C174.204 31.9014 172.667 32.198 171.045 32.198C167.631 32.198 165.54 30.7575 164.899 28.1306H177.576C179.411 28.1306 179.88 26.9867 179.88 24.953ZM169.893 19.8688C172.411 19.8688 174.289 21.2246 174.631 24.0209H164.814C165.283 21.267 167.289 19.8688 169.893 19.8688Z"
      fill="#0A4161"
    />
  </svg>
);

export default SvgOceanSensibleLogo;
