import { type PayoutAccount } from '../../utils/types/Entity';

export interface AddPayPalAccountContainerProps {
  onBackClick: () => void;
  onAccountCreationSuccess: (payoutAccount: PayoutAccount) => void;
}

export const PAYPAL_ACCOUNT_TYPES = {
  PHONE: 'Phone' as const,
  EMAIL: 'Email' as const,
};
