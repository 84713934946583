import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import { PolicyDetailsProps } from './PolicyDetails.types';
import styles from './policy-details.module.scss';

const PolicyDetails: React.FC<PolicyDetailsProps> = ({
  minHoursCovered,
  coverageStartHour,
  coverageEndHour,
  tz,
}) => {
  const { t } = useTranslation();
  const startHour = t('formatter.date', {
    val: new Date(coverageStartHour),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: tz,
      },
    },
  });
  const endHour = t('formatter.date', {
    val: new Date(coverageEndHour),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: tz,
      },
    },
  });
  const guaranteeExplainer = t('sunshine.content.guarantee_details.explainer', {
    minHoursCovered,
    coverageStartHour: startHour,
    coverageEndHour: endHour,
  });
  const guaranteeNotifierText = t(
    'sunshine.content.guarantee_details.explainer.notifier',
  );
  return (
    <div className={styles.container}>
      <Typography variant="body-1">{guaranteeExplainer}</Typography>

      <Typography variant="body-1">{guaranteeNotifierText}</Typography>
    </div>
  );
};

export default PolicyDetails;
