import { FC } from 'react';
import { NavigationItemProps } from './NavigationItem.types';
import styles from './navigation-item.module.scss';

const NavigationItem: FC<NavigationItemProps> = ({
  children,
  currentPath = false,
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <div className={`${styles.container} ${currentPath && styles.current}`}>
      {children}
    </div>
  );
};

export default NavigationItem;
