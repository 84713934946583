import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import styles from './footer.module.scss';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <footer className={styles.container}>
      <ul className={styles.legalLinks}>
        <li>
          <a
            className={styles.anchors}
            href={t('content.url.terms_of_service')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography color="snow" variant="body-3">
              {t('content.label.terms_of_service')}
            </Typography>
          </a>
        </li>{' '}
        <li aria-hidden>
          <Typography color="snow" variant="body-3">
            |
          </Typography>
        </li>
        <li>
          <a
            className={styles.anchors}
            href={t('content.url.privacy_policy')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography color="snow" variant="body-3">
              {t('comms.label.privacy_policy')}
            </Typography>
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
