import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSensibleLogoLong = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 207 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M51.3008 17.1002C51.3008 25.0531 44.2493 31.5002 35.5508 31.5002C26.8523 31.5002 19.8008 25.0531 19.8008 17.1002C19.8008 9.1473 26.8523 2.7002 35.5508 2.7002C44.2493 2.7002 51.3008 9.1473 51.3008 17.1002Z"
      fill="#FFE500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.12728 2.12728L2.12727 18C2.12727 26.7663 9.23373 33.8727 18 33.8727C26.7663 33.8727 33.8727 26.7663 33.8727 18C33.8727 9.23374 26.7663 2.12728 18 2.12728L2.12728 2.12728ZM18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 7.79826e-06 18 6.92918e-06L3.06922e-06 5.35557e-06L1.49561e-06 18C6.26527e-07 27.9411 8.05887 36 18 36Z"
      fill="#00FFE0"
    />
    <path
      d="M0.900391 18.1552V0L20.4432 1.21034L27.7718 4.84138L32.4355 8.47241L35.1004 18.1552L32.4355 27.8379L25.329 33.8897L16.779 35.1L7.00753 32.3134L2.12182 22.8558L0.900391 18.1552Z"
      fill="#00FFE0"
    />
    <path
      d="M24.3147 27.1435C27.4213 27.1435 30.1158 25.5185 30.1475 22.3643C30.1792 19.9747 28.6259 18.6365 26.6605 17.84L24.4732 16.9797C23.6173 16.6611 22.9833 16.2151 22.9833 15.4504C22.9833 14.7813 23.4588 14.2078 24.4732 14.2078C25.3925 14.2078 26.2801 14.6539 27.2945 15.5141C27.5798 15.7371 27.8017 15.769 28.0236 15.5141L29.4184 13.8255C29.5769 13.6343 29.6403 13.3794 29.4501 13.1564C28.1504 11.5952 26.2801 10.7668 24.2513 10.7668C21.3666 10.7668 18.8306 12.4873 18.8306 15.4823C18.8306 17.6488 20.2254 18.9551 22.2225 19.7517L24.1879 20.5482C25.3291 21.0261 25.8046 21.4084 25.8046 22.1731C25.8046 23.0652 25.1072 23.4794 24.0611 23.4794C22.8565 23.4794 21.7787 22.9378 20.479 21.9501C20.2254 21.7589 19.9401 21.6952 19.6865 22.0775L18.577 23.6387C18.3551 23.9892 18.3234 24.3397 18.5136 24.5627C19.7499 26.0283 21.7153 27.1435 24.3147 27.1435Z"
      fill="#0B1419"
    />
    <path
      d="M47.7516 18.2223C47.7516 14.2715 45.0254 10.7668 40.3021 10.7668C35.5154 10.7668 32.3137 14.3034 32.3137 18.8914C32.3137 23.6069 35.7373 27.1435 40.841 27.1435C43.0283 27.1435 45.0254 26.5062 46.3568 25.1681C46.6421 24.9132 46.6738 24.6901 46.5153 24.4671L45.3424 22.8422C45.1839 22.6192 45.0254 22.5873 44.8035 22.7147C43.5355 23.4476 42.3943 23.6706 41.1897 23.6706C38.6537 23.6706 37.1004 22.5873 36.6249 20.6119H46.0398C47.4029 20.6119 47.7516 19.7517 47.7516 18.2223ZM40.3338 14.399C42.2041 14.399 43.5989 15.4185 43.8525 17.5214H36.5615C36.9102 15.4504 38.4001 14.399 40.3338 14.399Z"
      fill="#0B1419"
    />
    <path
      d="M59.756 10.7668C57.4419 10.7668 55.952 11.9138 55.0644 13.5069L54.9376 11.6589C54.9376 11.2766 54.7474 11.1491 54.4621 11.1491H51.1653C50.88 11.1491 50.6898 11.3403 50.6898 11.627V26.2832C50.6898 26.57 50.88 26.7611 51.1653 26.7611H54.5889C54.8742 26.7611 55.0644 26.57 55.0644 26.2832V18.1905C55.0644 15.992 56.269 14.8132 58.1076 14.8132C59.9779 14.8132 60.9923 15.992 60.9923 18.1905V26.2832C60.9923 26.57 61.1825 26.7611 61.4678 26.7611H64.8597C65.145 26.7611 65.3669 26.57 65.3669 26.2832V17.2028C65.3669 13.1245 63.0211 10.7668 59.756 10.7668Z"
      fill="#0B1419"
    />
    <path
      d="M74.4031 27.1435C77.5097 27.1435 80.2042 25.5185 80.2359 22.3643C80.2676 19.9747 78.7143 18.6365 76.7489 17.84L74.5616 16.9797C73.7057 16.6611 73.0717 16.2151 73.0717 15.4504C73.0717 14.7813 73.5473 14.2078 74.5616 14.2078C75.481 14.2078 76.3685 14.6539 77.3829 15.5141C77.6682 15.7371 77.8901 15.769 78.112 15.5141L79.5068 13.8255C79.6653 13.6343 79.7287 13.3794 79.5385 13.1564C78.2388 11.5952 76.3685 10.7668 74.3397 10.7668C71.455 10.7668 68.919 12.4873 68.919 15.4823C68.919 17.6488 70.3138 18.9551 72.3109 19.7517L74.2763 20.5482C75.4175 21.0261 75.8931 21.4084 75.8931 22.1731C75.8931 23.0652 75.1956 23.4794 74.1495 23.4794C72.9449 23.4794 71.8671 22.9378 70.5674 21.9501C70.3138 21.7589 70.0285 21.6952 69.7749 22.0775L68.6654 23.6387C68.4435 23.9892 68.4118 24.3397 68.602 24.5627C69.8383 26.0283 71.8037 27.1435 74.4031 27.1435Z"
      fill="#0B1419"
    />
    <path
      d="M85.6002 8.37721C86.995 8.37721 88.0094 7.29392 88.0094 5.92389C88.0094 4.58572 86.995 3.50244 85.6002 3.50244C84.2054 3.50244 83.1593 4.58572 83.1593 5.92389C83.1593 7.29392 84.2054 8.37721 85.6002 8.37721ZM83.8567 26.7611H87.312C87.5973 26.7611 87.7875 26.57 87.7875 26.2832V11.627C87.7875 11.3403 87.5973 11.1491 87.312 11.1491H83.8567C83.5714 11.1491 83.3812 11.3403 83.3812 11.627V26.2832C83.3812 26.57 83.5714 26.7611 83.8567 26.7611Z"
      fill="#0B1419"
    />
    <path
      d="M100.809 10.7668C98.6215 10.7668 97.0365 11.5952 95.9904 12.9971V4.45828C95.9904 4.17153 95.8002 3.98036 95.5149 3.98036H92.0596C91.7743 3.98036 91.5841 4.17153 91.5841 4.45828V26.2832C91.5841 26.57 91.806 26.7611 92.0913 26.7611H95.1979C95.4832 26.7611 95.6734 26.57 95.6734 26.2832L95.7368 24.5627C96.8146 26.1558 98.463 27.1435 100.809 27.1435C105.152 27.1435 108.163 23.6069 108.163 18.9551C108.163 14.3352 105.152 10.7668 100.809 10.7668ZM99.8895 23.1289C97.5437 23.1289 95.927 21.4722 95.927 18.9551C95.927 16.4381 97.5437 14.7813 99.8895 14.7813C102.172 14.7813 103.884 16.4699 103.884 18.9551C103.884 21.4722 102.172 23.1289 99.8895 23.1289Z"
      fill="#0B1419"
    />
    <path
      d="M111.624 26.7611H115.08C115.365 26.7611 115.555 26.57 115.555 26.2832V4.45828C115.555 4.17153 115.365 3.98036 115.08 3.98036H111.624C111.339 3.98036 111.149 4.17153 111.149 4.45828V26.2832C111.149 26.57 111.339 26.7611 111.624 26.7611Z"
      fill="#0B1419"
    />
    <path
      d="M133.967 18.2223C133.967 14.2715 131.241 10.7668 126.517 10.7668C121.731 10.7668 118.529 14.3034 118.529 18.8914C118.529 23.6069 121.953 27.1435 127.056 27.1435C129.244 27.1435 131.241 26.5062 132.572 25.1681C132.857 24.9132 132.889 24.6901 132.731 24.4671L131.558 22.8422C131.399 22.6192 131.241 22.5873 131.019 22.7147C129.751 23.4476 128.61 23.6706 127.405 23.6706C124.869 23.6706 123.316 22.5873 122.84 20.6119H132.255C133.618 20.6119 133.967 19.7517 133.967 18.2223ZM126.549 14.399C128.419 14.399 129.814 15.4185 130.068 17.5214H122.777C123.126 15.4504 124.615 14.399 126.549 14.399Z"
      fill="#0B1419"
    />
    <path
      d="M140.749 26.9264H141.681C141.828 26.9264 141.956 26.8541 142.01 26.7096L144.569 19.5013L147.109 26.7096C147.164 26.8541 147.292 26.9264 147.438 26.9264H148.37C148.516 26.9264 148.644 26.8541 148.699 26.7096L151.641 18.3812C151.714 18.1825 151.641 18.0741 151.44 18.0741H150.6C150.453 18.0741 150.38 18.1464 150.325 18.2909L147.877 25.4992L145.355 18.2909C145.3 18.1464 145.172 18.0741 145.026 18.0741H144.112C143.966 18.0741 143.838 18.1464 143.783 18.2909L141.261 25.4992L138.812 18.2909C138.757 18.1464 138.666 18.0741 138.52 18.0741H137.697C137.496 18.0741 137.423 18.1825 137.496 18.3812L140.42 26.7096C140.475 26.8541 140.603 26.9264 140.749 26.9264Z"
      fill="#0B1419"
    />
    <path
      d="M161.491 22.0486C161.491 19.8807 159.956 17.8573 157.306 17.8573C154.638 17.8573 152.81 19.8626 152.81 22.4641C152.81 25.0475 154.619 27.1432 157.543 27.1432C158.786 27.1432 159.937 26.7638 160.741 26.005C160.869 25.8786 160.942 25.7882 160.815 25.6256L160.504 25.2101C160.431 25.1198 160.321 25.1198 160.23 25.1921C159.535 25.716 158.749 26.0412 157.653 26.0412C155.478 26.0412 154.254 24.7223 154.089 22.97H160.833C161.363 22.97 161.491 22.699 161.491 22.0486ZM157.287 19.0135C159.097 19.0135 160.266 20.2239 160.321 21.9583H154.089C154.254 20.1697 155.515 19.0135 157.287 19.0135Z"
      fill="#0B1419"
    />
    <path
      d="M172.257 18.0741H171.472C171.307 18.0741 171.234 18.1644 171.234 18.327L171.216 19.8265C170.558 18.6161 169.37 17.8573 167.762 17.8573C165.148 17.8573 163.357 19.8626 163.357 22.5002C163.357 25.1559 165.148 27.1432 167.762 27.1432C169.37 27.1432 170.558 26.4025 171.216 25.2101L171.234 26.6735C171.234 26.8361 171.307 26.9264 171.472 26.9264H172.257C172.422 26.9264 172.495 26.8361 172.495 26.6735V18.327C172.495 18.1644 172.422 18.0741 172.257 18.0741ZM167.944 25.9328C166.026 25.9328 164.637 24.5598 164.637 22.5002C164.637 20.4588 166.026 19.0677 167.944 19.0677C169.863 19.0677 171.197 20.4769 171.197 22.5364C171.197 24.5778 169.863 25.9328 167.944 25.9328Z"
      fill="#0B1419"
    />
    <path
      d="M178.964 19.1581C179.128 19.1581 179.22 19.0677 179.22 18.9051V18.327C179.22 18.1644 179.128 18.0741 178.964 18.0741H177.447V15.9785C177.447 15.8159 177.374 15.7255 177.209 15.7255H176.369C176.204 15.7255 176.131 15.8159 176.131 15.9785V18.0741H174.962C174.797 18.0741 174.706 18.1644 174.706 18.327V18.9051C174.706 19.0677 174.797 19.1581 174.962 19.1581H176.131V25.2282C176.131 26.5289 176.789 27.1432 178.032 27.1432C178.507 27.1432 179.183 27.0528 179.183 26.7457V26.2037C179.183 26.0231 179.11 25.9328 178.836 25.9689C178.671 25.987 178.416 26.005 178.269 26.005C177.721 26.005 177.447 25.7521 177.447 25.0656V19.1581H178.964Z"
      fill="#0B1419"
    />
    <path
      d="M185.687 17.8573C184.206 17.8573 183.274 18.5799 182.763 19.4832V14.2622C182.763 14.0996 182.689 14.0093 182.525 14.0093H181.684C181.52 14.0093 181.447 14.0996 181.447 14.2622V26.6735C181.447 26.8361 181.52 26.9264 181.684 26.9264H182.525C182.689 26.9264 182.763 26.8361 182.763 26.6735V21.5969C182.763 20.0252 183.877 19.0677 185.358 19.0677C186.838 19.0677 187.679 20.0252 187.679 21.5969V26.6735C187.679 26.8361 187.733 26.9264 187.898 26.9264H188.757C188.921 26.9264 188.994 26.8361 188.994 26.6735V21.4705C188.994 19.1219 187.642 17.8573 185.687 17.8573Z"
      fill="#0B1419"
    />
    <path
      d="M199.986 22.0486C199.986 19.8807 198.451 17.8573 195.801 17.8573C193.133 17.8573 191.305 19.8626 191.305 22.4641C191.305 25.0475 193.114 27.1432 196.038 27.1432C197.281 27.1432 198.432 26.7638 199.237 26.005C199.364 25.8786 199.438 25.7882 199.31 25.6256L198.999 25.2101C198.926 25.1198 198.816 25.1198 198.725 25.1921C198.03 25.716 197.245 26.0412 196.148 26.0412C193.973 26.0412 192.749 24.7223 192.584 22.97H199.328C199.858 22.97 199.986 22.699 199.986 22.0486ZM195.783 19.0135C197.592 19.0135 198.761 20.2239 198.816 21.9583H192.584C192.749 20.1697 194.01 19.0135 195.783 19.0135Z"
      fill="#0B1419"
    />
    <path
      d="M206.494 18.038C206.33 17.9296 206.038 17.8573 205.672 17.8573C204.557 17.8573 203.954 18.5257 203.607 19.3568L203.57 18.3451C203.57 18.1464 203.497 18.0741 203.315 18.0741H202.547C202.383 18.0741 202.309 18.1644 202.309 18.327V26.6735C202.309 26.8361 202.383 26.9264 202.547 26.9264H203.388C203.552 26.9264 203.625 26.8361 203.625 26.6735V21.4705C203.625 20.4769 204.064 19.0497 205.672 19.0497C205.855 19.0497 206.074 19.0677 206.312 19.14C206.513 19.1942 206.641 19.1581 206.641 18.9593V18.3451C206.641 18.1825 206.604 18.1102 206.494 18.038Z"
      fill="#0B1419"
    />
  </svg>
);

export default SvgSensibleLogoLong;
