//is used in client
import {
  arrayValidator,
  booleanValidator,
  objectValidator,
  stringValidator,
} from '../../validate';

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  authID: string;
  email: string;
  phone: string;
  locale: string;
  langLocale: string;
  addresses: Address[];
}

interface Address {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  isDefault: boolean;
  addressLine1: string;
  addressLine2?: string;
  state: string;
  postalCode: string;
  country: string;
}

function isAddress(value: unknown): asserts value is Address {
  objectValidator(value, 'address');

  const {
    id,
    createdAt,
    updatedAt,
    name,
    addressLine1,
    addressLine2,
    state,
    postalCode,
    country,
    isDefault,
  } = value;

  const requiredStrings = [
    { name: 'address.id', value: id },
    { name: 'address.createdAt', value: createdAt },
    { name: 'address.updatedAt', value: updatedAt },
    { name: 'address.name', value: name },
    { name: 'address.addressLine1', value: addressLine1 },
    { name: 'address.state', value: state },
    { name: 'address.postalCode', value: postalCode },
    { name: 'address.country', value: country },
  ];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const optionalStrings = [
    { name: 'address.addressLine2', value: addressLine2 },
  ];

  optionalStrings.forEach((str) => {
    if (str.value !== undefined) {
      stringValidator(str.value, str.name);
    }
  });

  const requiredBooleans = [{ name: 'address.isDefault', value: isDefault }];

  requiredBooleans.forEach((bool) => {
    booleanValidator(bool.value, bool.name);
  });
}

export function isUser(value: unknown): asserts value is User {
  objectValidator(value, 'user');

  const {
    id,
    createdAt,
    updatedAt,
    name,
    authID,
    email,
    phone,
    locale,
    langLocale,
    addresses,
  } = value;

  const requiredStrings = [
    { name: 'user.id', value: id },
    { name: 'user.createdAt', value: createdAt },
    { name: 'user.updatedAt', value: updatedAt },
    { name: 'user.name', value: name },
    { name: 'user.email', value: email },
    { name: 'user.phone', value: phone },
    { name: 'user.locale', value: locale },
    { name: 'user.langLocale', value: langLocale },
  ];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const optionalStrings = [{ name: 'user.authID', value: authID }];

  optionalStrings.forEach((str) => {
    if (str.value !== undefined) {
      stringValidator(str.value, str.name);
    }
  });

  const requiredArrays = [
    { name: 'user.addresses', value: addresses, typeguard: isAddress },
  ];

  requiredArrays.forEach((arr) => {
    arrayValidator(arr.value, arr.name, { typeguard: arr.typeguard });
  });
}
