import { default as helpers } from '../utils/helpers/helpers';
import sensibleDate from '../utils/types/SensibleDate';
import {
  type Claim,
  ClaimStatus,
  type PolicyBundle,
  type Policy,
} from '../utils/types/Entity';

/**
 * Aggregate the list of claims from a PolicyBundle into a separate
 * array. If we encounter an error, fail gracefully by returning
 * an empty array.
 */
export const getClaimsFromPolicyBundle = (
  policyBundle: PolicyBundle,
): Claim[] => {
  try {
    const claimsCollection: Claim[] = [];
    if (policyBundle) {
      policyBundle.policies.forEach((policy) => {
        if (policy.exposures[0].claims.length > 0) {
          policy.exposures[0].claims.forEach((claim) => {
            claimsCollection.push(claim);
          });
        }
      });
    }

    return helpers.sortByKey(claimsCollection, 'createdAt');
  } catch {
    return [];
  }
};

export const claimIsDue = (claim: Claim): boolean => {
  return claim && claim.status === ClaimStatus.Open;
};

export const claimIsPaid = (claim: Claim): boolean => {
  return claim && claim.status === ClaimStatus.Paid;
};

export const claimIsProcessing = (claim: Claim): boolean => {
  return claim && claim.status === ClaimStatus.Processing;
};

export const claimIsExpired = (claim: Claim): boolean => {
  return claim && claim.status === ClaimStatus.Expired;
};

/**
 * Given a list of claims, tally up the balance due.
 * If we get an error, fail gracefully by returning a zero balance
 * (so that we don't allow for a reimbursement flow in an error state);
 */
export const getBalanceDueFromClaims = (claims: Claim[] = []): number => {
  try {
    let balanceDue = 0;

    claims.forEach((claim) => {
      if (claimIsDue(claim)) {
        balanceDue += claim.claimAmount;
      }
    });

    return balanceDue;
  } catch {
    return 0;
  }
};

/**
 * Given a valid list of claims, retrieves the most recent claim that is processing
 * a payout. In an error state, we probably want to allow the error to escalate to the
 * handling component.
 *
 * The original logic is hidden behind this comment here, and used a `let` with a `forEach` to find the most recent
 * `payoutProcessing`, but would recommend we instead use a `reduce`.
 *
 * This comment will be removed as part of the PR review.
 *
 * ```typescript
 * let pc: Claim = claims[0];
 *
 * hasPayoutProcessing.forEach((claim) => {
 *   if (!claim.payout) return;
 *   if (!pc.payout) return;
 *
 *   const currentPCCreatedAt = sensibleDate.parseRFC(pc.payout?.createdAt);
 *   const currentClaimCreatedAt = sensibleDate.parseRFC(claim.payout.createdAt);
 *
 *   if (sensibleDate.isAfter(currentClaimCreatedAt, currentPCCreatedAt)) {
 *     pc = claim;
 *   }
 * });
 *
 * return pc;
 * ```
 * Note, this can silently fail and give us a claim that doesn't actually have a payout,
 * but we have guarded against this by using `claimHasPayoutProcessing` above;
 * there's still a chance that an error throws...
 */
export const getMostRecentProcessingPayout = (claims: Claim[] = []): Claim => {
  const payoutClaim: Claim = claims
    .filter(claimIsProcessing)
    .reduce((mostRecentClaim: Claim, claim: Claim) => {
      if (!claim.payout) {
        return mostRecentClaim;
      } else if (mostRecentClaim.payout) {
        const mostRecentCreatedAt = sensibleDate.parseRFC(
          mostRecentClaim.payout.createdAt,
        );
        const currentClaimCreatedAt = sensibleDate.parseRFC(
          claim.payout.createdAt,
        );

        if (sensibleDate.isAfter(currentClaimCreatedAt, mostRecentCreatedAt)) {
          mostRecentClaim = claim;
        }
      }
      return mostRecentClaim;
    }, claims[0]);

  return payoutClaim;
};

/**
 * Inspects the claims within a policy's exposures, and returns true if any of them
 * are an open claim.
 */
export const policyHasOpenClaims = (policy: Policy): boolean => {
  return policy.exposures.some((exposure) =>
    exposure.claims.some((claim) => claim.status === ClaimStatus.Open),
  );
};

/**
 * Filters a list of policy bundles to only the ones that contain open claims on their policies.
 */
export const getPolicyBundlesWithOpenClaims = (
  policyBundles: PolicyBundle[],
): PolicyBundle[] =>
  policyBundles.filter((bundle) =>
    bundle.policies.some((policy) => policyHasOpenClaims(policy)),
  );

/**
 * Per the Dwolla docs, a bank routing number that identifies a bank or credit union
 * in the U.S. Note: Validation of the routing number includes: a checksum, the
 * first two digits of the routing number must fall within the range "01" through "12",
 * or "21" through "32", and the string value must consist of nine digits.
 */
export const validateRoutingNumber = (routingNumber: string): boolean => {
  if (routingNumber.length !== 9) return false;

  const isNumber = Number(routingNumber.slice());

  if (isNaN(isNumber) || typeof isNumber !== 'number') return false;

  const firstTwo = Number(routingNumber.slice(0, 2));

  if (
    !((firstTwo >= 1 && firstTwo <= 12) || (firstTwo >= 21 && firstTwo <= 32))
  )
    return false;

  return true;
};

/**
 * Per the Dwolla docs, the account number is validated to check if it
 * is a numeric string of 4-17 digits.
 */
export const validateAccountNumber = (accountNumber: string): boolean => {
  if (accountNumber.length < 4 || accountNumber.length > 17) return false;

  const isNumber = Number(accountNumber.slice());

  if (isNaN(isNumber) || typeof isNumber !== 'number') return false;

  return true;
};
