import React, { useState, useEffect } from 'react';
import format from '../../../utils/format';
import styles from './base-input.module.scss';
import { BaseInputProps } from './BaseInput.types';
import Typography from '../../Typography/Typography';

const BaseInput: React.FunctionComponent<BaseInputProps> = ({
  label,
  element,
  value,
  id,
  errorMsg,
  labelFormat = 'title',
  controlledActive,
}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const el = document.getElementById(id);

    if (!el) return;

    el.addEventListener('focus', () => setActive(true));
    el.addEventListener('blur', () => setActive(false));
  }, [id]);

  const renderLabelText = value || controlledActive || active;

  const labelTextClass = `${styles.labelText} ${
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    !renderLabelText && styles.hideLabelText
  }`;

  const formatLabel = (): string => {
    switch (labelFormat) {
      case 'title':
        return format.toTitleCase(label || '');
      case 'none':
      default:
        return label || '';
    }
  };

  return (
    <>
      {/* This is disabled because eslint does not recognize
      that the passed in element has an ID on it */}

      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label
        className={`${styles.label} ${errorMsg && styles.error}`}
        htmlFor={id}
      >
        {/*   TODO: #605 */}
        <div className={styles.textWrapper}>
          <p className={labelTextClass}>{formatLabel()}</p>
          <Typography variant="error" align="right" format="sentence">
            {errorMsg}
          </Typography>
        </div>
        {element}
      </label>
    </>
  );
};

export default BaseInput;
