import { FC } from 'react';
import { NeonGrassOverlayIconProps } from './NeonGrassOverlayIconProps.types';
import styles from './neon-grass-overlay-icon.module.scss';

const NeonGrassOverlayIcon: FC<NeonGrassOverlayIconProps> = ({ svg }) => {
  return (
    <div className={styles.container}>
      <div className={styles.positioningWrapper}>
        <div className={styles.overlay} />
        <div className={styles.icon}>{svg}</div>
      </div>
    </div>
  );
};

export default NeonGrassOverlayIcon;
