import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavElement from '../../sharedComponents/NavElement/NavElement';
import { useAuth0 } from '@auth0/auth0-react';
import Typography from '../../sharedComponents/Typography/Typography';
import { PayoutAccountAPI } from '../../api';
import { PageProps } from '../Page.types';
import { useActions, useStore } from '../../store/hooks';
import ManagePayoutAccountList from '../../containers/ManagePayoutAccountList/ManagePayoutAccountList';
import styles from './payout-account-mgmt.module.scss';
import { AddPayoutAccountContainer } from '../../containers';
import Footer from '../../components/Footer/Footer';

const PayoutAccountMgmt: FC<PageProps> = ({
  layout: Layout,
  navigation: Navigation,
}) => {
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useActions();

  const {
    payoutAccount: { payoutAccounts, loadedPayoutAccounts },
  } = useStore();

  useEffect(() => {
    const fetchPayoutAccounts = async (): Promise<void> => {
      const accessToken = await getAccessTokenSilently();
      try {
        // eslint-disable-next-line prettier/prettier
        const fetchedAccounts =
          await PayoutAccountAPI.getPayoutAccounts(accessToken);
        dispatch.setPayoutAccounts(fetchedAccounts);
      } catch (e) {
        //TODO: #1835 fix the 'can not perform state update on the unmounted component' error
      } finally {
        dispatch.setLoadedPayoutAccounts();
      }
    };
    if (!loadedPayoutAccounts) {
      void fetchPayoutAccounts();
    }
  }, [payoutAccounts, loadedPayoutAccounts, dispatch, getAccessTokenSilently]);

  let renderedPayoutAccounts: JSX.Element;

  if (payoutAccounts.length === 0) {
    renderedPayoutAccounts = (
      <>
        <Typography variant="body-1">
          {t('sunshine.content.payout_accounts.no_payout_accounts')}
        </Typography>
        <div className={styles.spacingWrapper}>
          <AddPayoutAccountContainer processingRequest={false} />
        </div>
      </>
    );
  } else {
    renderedPayoutAccounts = (
      <>
        <Typography variant="body-1">
          {t('sunshine.content.payout_accounts.explainer')}
        </Typography>
        <div className={styles.spacingWrapper}>
          <ManagePayoutAccountList accounts={payoutAccounts} />
        </div>
      </>
    );
  }

  return (
    <Layout navigation={<Navigation />} footer={<Footer />}>
      <NavElement
        title={t('action.back')}
        navigateTo={-1}
        colorVariant="ocean"
      />
      <div className={styles.heading}>
        <Typography variant="h4">
          {t('sunshine.content.payout_accounts.header')}
        </Typography>
        {loadedPayoutAccounts && renderedPayoutAccounts}
      </div>
    </Layout>
  );
};

export default PayoutAccountMgmt;
