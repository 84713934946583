import { validators, err } from '../utils';
import { type PolicyBundle, isPolicyBundle } from '../utils/types/Entity';
import axios, { AxiosResponse } from 'axios';

const URL = '/api/policy-bundle';

const getPolicyBundles = async (token: string): Promise<PolicyBundle[]> => {
  try {
    const res: AxiosResponse<{ bundles: PolicyBundle[] }> = await axios.get(
      URL,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
    const { bundles } = res.data;

    validators.arrayValidator(bundles, 'bundles', {
      typeguard: isPolicyBundle,
    });

    return bundles;
  } catch (e) {
    throw err.handleError(e, 'error getting bundles');
  }
};

export default { getPolicyBundles };
