import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import { PayoutTiersTableProps } from './PayoutTiersTable.types';
import styles from './payout-tiers-table.module.scss';

const PayoutTiersTable: React.FC<PayoutTiersTableProps> = ({
  tiers,
  currency,
}) => {
  const { t } = useTranslation();
  const createRow = (
    hoursText: string,
    reimbursement: number,
    i: number,
  ): JSX.Element => {
    return (
      <tr key={`row-${i}`}>
        <td>
          <Typography variant="body-2">{hoursText}</Typography>
        </td>
        <td>
          <Typography variant="body-2">
            {t('formatter.currency', {
              val: reimbursement.toFixed(2),
              currency: currency,
            })}
          </Typography>
        </td>
      </tr>
    );
  };

  const renderRows = (): JSX.Element[] => {
    const sortedTiers = tiers
      .slice()
      .sort((a, b) => a.numberOfHours - b.numberOfHours);

    const rows: JSX.Element[] = [];

    const firstTierHours = sortedTiers[0].numberOfHours;
    const firstRow = createRow(
      `${t('label.less_than')} ${t('label.hour', {
        count: firstTierHours,
      })}`,
      0,
      -1,
    );

    rows.push(firstRow);

    sortedTiers.forEach((st, i) => {
      let hoursText = '';

      if (i === sortedTiers.length - 1) {
        hoursText = `${t('label.hour_or_more', {
          count: st.numberOfHours,
        })}`;
      } else {
        const endRangeHour = sortedTiers[i + 1].numberOfHours - 1;

        if (st.numberOfHours === endRangeHour) {
          hoursText = `${t('label.hour', {
            count: st.numberOfHours,
          })}`;
        } else {
          hoursText = `${st.numberOfHours} - ${t('label.hour', {
            count: endRangeHour,
          })}`;
        }
      }

      rows.push(createRow(hoursText, st.payoutAmount, i));
    });

    return rows;
  };

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th>
              <Typography variant="label-3">
                {t('sunshine.label.forecasted_hours')}
              </Typography>
            </th>
            <th>
              <Typography variant="label-3">
                {t('sunshine.label.reimbursement')}
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default PayoutTiersTable;
