/**
 * @function arrayValidator
 * @description Validates that a variable is present, is an array, and satisfies any optional constraints.
 * Does not check that constraints are strictly compatible - throws an error at the first failed validation encountered.
 */

import { ValidationError } from '../error';

export function arrayValidator<T>(
  /** The variable value to validate */
  value: unknown,
  /** The variable name for error message purposes */
  name: string,
  /** Optional parameters to validate against */
  opts?: {
    /** Pass nonEmpty to require array to have at least one item */
    nonEmpty?: boolean;
    /** Error message to display if nonEmpty triggers */
    nonEmptyErrorMsg?: string;
    /** Pass minLength to require array having at least minLength # of items */
    minLength?: number;
    /** Error message to display if minLength triggers */
    minLengthErrorMsg?: string;
    /** Pass maxLength to require array having at most maxLength # of items */
    maxLength?: number;
    /** Error message to display if maxLength triggers */
    maxLengthErrorMsg?: string;
    /**
     * Pass typeguard to require items in the array being of a certain type.
     * It is not possible to typeguard against the array itself, only against the items in the array.
     * For example, passing an array of `User[]` and checking `instanceof User[]` would fail.
     * However, passing an array of `User[]` and checking `instanceof User` would pass.
     * An empty array will pass, if the array must be `nonEmpty`, use the `nonEmpty` flag.
     */
    typeguard?: (v: unknown, ...args: unknown[]) => asserts v is T;
    /** Error message to display if typeguard triggers */
    typeguardErrorMsg?: string;
    /** Pass errorMsg to supply a catch-all error message on failed validation */
    errorMsg?: string;
  },
): asserts value is T[] {
  // Variable must exist
  if (typeof value === 'undefined') {
    throw new ValidationError(value, name, ['value is undefined']);
  }

  // Variable must be an array
  if (!Array.isArray(value)) {
    throw new ValidationError(value, name, [
      `value is not of type Array, received type ${typeof value}`,
    ]);
  }

  // Handle nonEmpty
  if (opts?.nonEmpty && value.length === 0) {
    const nonEmptyError = opts.nonEmptyErrorMsg || 'value is empty';
    throw new ValidationError(value, name, [nonEmptyError]);
  }

  // Handle minLength
  if (opts?.minLength && value.length < Math.max(opts.minLength, 0)) {
    const minLengthError =
      opts.minLengthErrorMsg ||
      `value does not meet minimum length of ${opts.minLength}`;

    throw new ValidationError(value, name, [minLengthError]);
  }

  // Handle maxLength
  if (opts?.maxLength && value.length > opts.maxLength) {
    const maxLengthError =
      opts.maxLengthErrorMsg ||
      `value is longer than maximum length of ${opts.maxLength}`;

    throw new ValidationError(value, name, [maxLengthError]);
  }

  // Handle typeguard (typeguard must check against items in the array, not the array itself (use `nonEmpty` for that instead))
  if (opts?.typeguard) {
    const typeguardErrorMsg =
      opts.typeguardErrorMsg ||
      `value contains elements that do not satisfy ${name} typeguard`;

    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value.forEach((a) => opts.typeguard!(a));
    } catch (e) {
      throw new ValidationError(value, name, [
        `${typeguardErrorMsg}: ${(e as Error).message}; reason ==> ${
          (e as ValidationError)?.errors || 'Unknown'
        }`,
      ]);
    }
  }
}
