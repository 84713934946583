/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC } from 'react';
import { AccordionProps } from './Accordion.types';
import styles from './accordion.module.scss';
import PlusSign from '../assets/svg-tsx/sensible/PlusSign';

const Accordion: FC<AccordionProps> = ({
  title,
  content,
  isExpanded,
  disabled = false,
  colorVariant = 'snow',
  clickHandler,
  selected = false,
}) => {
  return (
    <div
      className={`
        ${styles.container}
        ${styles[colorVariant]}
        ${isExpanded && !disabled && styles.expanded}
        ${disabled && styles.disabled}
        ${selected && styles.selected}
      `}
    >
      <button className={styles.btn} disabled={disabled} onClick={clickHandler}>
        <div className={styles.titleWrapper}>{title}</div>

        <div className={styles.iconWrapper}>
          <PlusSign />
        </div>
      </button>

      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default Accordion;
