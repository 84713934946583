import React, { useState, createContext } from 'react';
import { CurrencyUnit } from '../utils/types/Currency/Currency.types';
import {
  CountryCode,
  CurrencyCountryCodeMap,
} from '../utils/types/CountryCode/CountryCode.types';
interface LocaleDataConfig {
  locale: CountryCode;
}

interface LocaleActionsConfig {
  updateLocale: (value: CurrencyUnit) => void;
}

interface LocaleEnvelope {
  data: LocaleDataConfig;
  actions: LocaleActionsConfig;
}

const DefaultLocaleDataConfig: LocaleDataConfig = {
  locale: CountryCode.US,
};

const DefaultLocaleActionsConfig: LocaleActionsConfig = {
  updateLocale: () => null,
};

const DefaultLocaleEnvelope: LocaleEnvelope = {
  data: DefaultLocaleDataConfig,
  actions: DefaultLocaleActionsConfig,
};

const LocaleContext = createContext<LocaleEnvelope>(DefaultLocaleEnvelope);

const LocaleProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [locale, setLocale] = useState<LocaleDataConfig['locale']>(
    DefaultLocaleDataConfig.locale,
  );
  const updateLocaleFromCurrency = (value: CurrencyUnit): void => {
    const userLocale = CurrencyCountryCodeMap[value];
    if (!userLocale) return;

    setLocale(userLocale);
  };

  const dataEnvelope: LocaleEnvelope = {
    data: {
      locale,
    },
    actions: {
      updateLocale: updateLocaleFromCurrency,
    },
  };
  return (
    <LocaleContext.Provider value={dataEnvelope}>
      {children}
    </LocaleContext.Provider>
  );
};

export { LocaleProvider, LocaleContext };
