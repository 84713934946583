export enum StatusCode {
  OK = 200,
  Created = 201,
  NoContent = 204,
  NoResponse = 102,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  RequestTimeout = 408,
  UnprocessableEntity = 422,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}

enum StatusText {
  OK = 'OK',
  Created = 'Created',
  NoContent = 'No Content',
  NoResponse = 'No Response',
  BadRequest = 'Bad Request',
  Unauthorized = 'Unauthorized',
  Forbidden = 'Forbidden',
  NotFound = 'Not Found',
  RequestTimeout = 'Request Timeout',
  UnprocessableEntity = 'Unprocessable Entity',
  InternalServerError = 'Internal Server Error',
  NotImplemented = 'Not Implemented',
  BadGateway = 'Bad Gateway',
  ServiceUnavailable = 'Service Unavailable',
  GatewayTimeout = 'Gateway Timeout',
}

const CodeToTextMap: Record<StatusCode, StatusText> = {
  [StatusCode.OK]: StatusText.OK,
  [StatusCode.Created]: StatusText.Created,
  [StatusCode.NoContent]: StatusText.NoContent,
  [StatusCode.NoResponse]: StatusText.NoResponse,
  [StatusCode.BadRequest]: StatusText.BadRequest,
  [StatusCode.Unauthorized]: StatusText.Unauthorized,
  [StatusCode.Forbidden]: StatusText.Forbidden,
  [StatusCode.NotFound]: StatusText.NotFound,
  [StatusCode.RequestTimeout]: StatusText.RequestTimeout,
  [StatusCode.UnprocessableEntity]: StatusText.UnprocessableEntity,
  [StatusCode.InternalServerError]: StatusText.InternalServerError,
  [StatusCode.BadGateway]: StatusText.BadGateway,
  [StatusCode.ServiceUnavailable]: StatusText.ServiceUnavailable,
  [StatusCode.GatewayTimeout]: StatusText.GatewayTimeout,
  [StatusCode.NotImplemented]: StatusText.NotImplemented,
};

export const statusTextFromCode = (code: StatusCode): StatusText => {
  return CodeToTextMap[code];
};
