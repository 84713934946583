import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import sensibleDate from '../../utils/types/SensibleDate';
import format from '../../utils/format';
import { PolicyListItemStatus } from '../PolicyListItem/PolicyListItem.types';
import {
  PolicyListItemSubtitleProps,
  statusColorMap,
} from './PolicyListItemSubtitle.types';
import styles from './policy-list-item-subtitle.module.scss';

const PolicyListItemSubtitle: React.FC<PolicyListItemSubtitleProps> = ({
  itemStatus,
  claim,
  currency = 'usd',
  totalPayoutAmount,
}) => {
  const { t, i18n } = useTranslation();
  const formatDate = new Intl.DateTimeFormat(i18n.language, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });

  const CUSTOMER_SERVICE_TEXT = format.toSentenceCaseAndPunctuation(
    t('error.customer_support'),
  );

  const PolicyListItemStatusMap: Record<PolicyListItemStatus, string> = {
    [PolicyListItemStatus.Upcoming]: t('sunshine.label.upcoming'),
    [PolicyListItemStatus.Due]: t('sunshine.label.due'),
    [PolicyListItemStatus.NoPayout]: t('sunshine.label.no_payout'),
    [PolicyListItemStatus.ClaimCanceled]: t('sunshine.label.claim_canceled'),
    [PolicyListItemStatus.PayoutCanceled]: t('sunshine.label.payout_canceled'),
    [PolicyListItemStatus.PayoutFailed]: t('sunshine.label.payout_failed'),
    [PolicyListItemStatus.Processing]: t('sunshine.label.processing'),
    [PolicyListItemStatus.Paid]: t('sunshine.label.paid_on'),
  };

  const statusColors: statusColorMap = {
    [PolicyListItemStatus.Due]: 'ocean',
    [PolicyListItemStatus.Processing]: 'status yellow',
    [PolicyListItemStatus.PayoutFailed]: 'status red',
    [PolicyListItemStatus.Paid]: 'status green',
  };

  const customerServiceTextElement = (
    <Typography
      variant="label-3"
      color="midnight-60"
      className={styles.customerServiceText}
    >
      {CUSTOMER_SERVICE_TEXT}
    </Typography>
  );

  if (
    itemStatus === PolicyListItemStatus.ClaimCanceled ||
    itemStatus === PolicyListItemStatus.PayoutCanceled ||
    itemStatus === PolicyListItemStatus.Upcoming ||
    itemStatus === PolicyListItemStatus.NoPayout
  ) {
    return (
      <>
        <Typography variant="label-3" color="midnight-60">
          {PolicyListItemStatusMap[itemStatus]}
        </Typography>
      </>
    );
  }

  if (!claim) {
    return customerServiceTextElement;
  }

  if (
    itemStatus !== PolicyListItemStatus.PayoutFailed &&
    itemStatus !== PolicyListItemStatus.Paid
  ) {
    return (
      <>
        <Typography
          variant="label-3"
          color={statusColors[itemStatus as keyof typeof statusColors]}
        >
          {t('formatter.currency', {
            val: totalPayoutAmount ?? claim.payout?.amount,
            currency,
          })}{' '}
          {PolicyListItemStatusMap[itemStatus]}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography
        variant="label-3"
        color={statusColors[itemStatus as keyof typeof statusColors]}
      >
        {t('formatter.currency', {
          val: totalPayoutAmount ?? claim.payout?.amount,
          currency,
        })}{' '}
        {itemStatus !== PolicyListItemStatus.Paid &&
          PolicyListItemStatusMap[
            itemStatus as keyof typeof PolicyListItemStatusMap
          ]}
        {itemStatus === PolicyListItemStatus.Paid &&
          claim?.payout?.completedAt &&
          `${
            PolicyListItemStatusMap[
              itemStatus as keyof typeof PolicyListItemStatusMap
            ]
          } ${formatDate.format(
            sensibleDate.parseRFC(claim.payout.completedAt),
          )}`}
      </Typography>

      {itemStatus === PolicyListItemStatus.PayoutFailed &&
        customerServiceTextElement}
    </>
  );
};

export default PolicyListItemSubtitle;
