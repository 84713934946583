import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDarkSensibleLogoLong = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 421 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M104.798 35.5159C104.798 51.6849 90.462 64.7924 72.7771 64.7924C55.0923 64.7924 40.7559 51.6849 40.7559 35.5159C40.7559 19.3468 55.0923 6.23926 72.7771 6.23926C90.462 6.23926 104.798 19.3468 104.798 35.5159Z"
      fill="#6D8EA0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.82487 5.07487L4.82486 37.3457C4.82486 55.1683 19.273 69.6165 37.0957 69.6165C54.9183 69.6165 69.3665 55.1683 69.3665 37.3457C69.3665 19.523 54.9183 5.07487 37.0957 5.07487L4.82487 5.07487ZM37.0957 73.9414C57.3069 73.9414 73.6914 57.5569 73.6914 37.3457C73.6914 17.1344 57.3069 0.749922 37.0957 0.749921L0.499915 0.749917L0.499912 37.3457C0.49991 57.5569 16.8844 73.9414 37.0957 73.9414Z"
      fill="#3D6881"
    />
    <path
      d="M2.33008 37.6612V0.75L42.0626 3.21075L56.9623 10.593L66.4439 17.9752L71.862 37.6612L66.4439 57.3472L51.9957 69.651L34.6128 72.1117L14.7465 66.4463L4.81336 47.2181L2.33008 37.6612Z"
      fill="#3D6881"
    />
    <path
      d="M48.6516 55.75C55.0219 55.75 60.5471 52.4508 60.6121 46.0465C60.6771 41.1947 57.492 38.4778 53.4618 36.8605L48.9766 35.1139C47.2215 34.467 45.9215 33.5613 45.9215 32.0088C45.9215 30.6503 46.8965 29.4858 48.9766 29.4858C50.8617 29.4858 52.6818 30.3915 54.7618 32.1381C55.3469 32.591 55.8019 32.6557 56.2569 32.1381L59.117 28.7096C59.442 28.3214 59.572 27.8039 59.182 27.3511C56.5169 24.1813 52.6818 22.4993 48.5216 22.4993C42.6063 22.4993 37.4061 25.9926 37.4061 32.0735C37.4061 36.4724 40.2662 39.1247 44.3614 40.7419L48.3916 42.3592C50.7317 43.3295 51.7067 44.1058 51.7067 45.6584C51.7067 47.4697 50.2767 48.3106 48.1316 48.3106C45.6615 48.3106 43.4514 47.2109 40.7863 45.2055C40.2662 44.8174 39.6812 44.688 39.1612 45.4643L36.8861 48.6341C36.4311 49.3457 36.3661 50.0573 36.7561 50.5101C39.2912 53.4858 43.3214 55.75 48.6516 55.75Z"
      fill="white"
    />
    <path
      d="M96.7104 37.6368C96.7104 29.6152 91.1202 22.4993 81.4348 22.4993C71.6194 22.4993 65.0541 29.6799 65.0541 38.9953C65.0541 48.5694 72.0744 55.75 82.5398 55.75C87.025 55.75 91.1202 54.4562 93.8503 51.7392C94.4353 51.2217 94.5003 50.7689 94.1753 50.316L91.7702 47.0168C91.4452 46.564 91.1202 46.4993 90.6652 46.7581C88.0651 48.246 85.725 48.6988 83.2549 48.6988C78.0546 48.6988 74.8695 46.4993 73.8945 42.4885H93.2003C95.9954 42.4885 96.7104 40.7419 96.7104 37.6368ZM81.4998 29.874C85.3349 29.874 88.1951 31.9441 88.7151 36.2136H73.7645C74.4795 32.0088 77.5346 29.874 81.4998 29.874Z"
      fill="white"
    />
    <path
      d="M121.326 22.4993C116.581 22.4993 113.526 24.8282 111.706 28.0627L111.446 24.3106C111.446 23.5344 111.056 23.2756 110.471 23.2756H103.71C103.125 23.2756 102.735 23.6637 102.735 24.246V54.0034C102.735 54.5856 103.125 54.9737 103.71 54.9737H110.731C111.316 54.9737 111.706 54.5856 111.706 54.0034V37.5721C111.706 33.1085 114.176 30.715 117.946 30.715C121.781 30.715 123.861 33.1085 123.861 37.5721V54.0034C123.861 54.5856 124.251 54.9737 124.836 54.9737H131.792C132.377 54.9737 132.832 54.5856 132.832 54.0034V35.5667C132.832 27.2864 128.021 22.4993 121.326 22.4993Z"
      fill="white"
    />
    <path
      d="M151.361 55.75C157.731 55.75 163.256 52.4508 163.321 46.0465C163.386 41.1947 160.201 38.4778 156.171 36.8605L151.686 35.1139C149.931 34.467 148.631 33.5613 148.631 32.0088C148.631 30.6503 149.606 29.4858 151.686 29.4858C153.571 29.4858 155.391 30.3915 157.471 32.1381C158.056 32.591 158.511 32.6557 158.966 32.1381L161.826 28.7096C162.151 28.3214 162.281 27.8039 161.891 27.3511C159.226 24.1813 155.391 22.4993 151.231 22.4993C145.316 22.4993 140.115 25.9926 140.115 32.0735C140.115 36.4724 142.976 39.1247 147.071 40.7419L151.101 42.3592C153.441 43.3295 154.416 44.1058 154.416 45.6584C154.416 47.4697 152.986 48.3106 150.841 48.3106C148.371 48.3106 146.161 47.2109 143.496 45.2055C142.976 44.8174 142.391 44.688 141.871 45.4643L139.595 48.6341C139.14 49.3457 139.075 50.0573 139.465 50.5101C142.001 53.4858 146.031 55.75 151.361 55.75Z"
      fill="white"
    />
    <path
      d="M174.321 17.6476C177.181 17.6476 179.261 15.4481 179.261 12.6664C179.261 9.94946 177.181 7.75 174.321 7.75C171.461 7.75 169.316 9.94946 169.316 12.6664C169.316 15.4481 171.461 17.6476 174.321 17.6476ZM170.746 54.9737H177.831C178.416 54.9737 178.806 54.5856 178.806 54.0034V24.246C178.806 23.6637 178.416 23.2756 177.831 23.2756H170.746C170.161 23.2756 169.771 23.6637 169.771 24.246V54.0034C169.771 54.5856 170.161 54.9737 170.746 54.9737Z"
      fill="white"
    />
    <path
      d="M205.507 22.4993C201.022 22.4993 197.772 24.1813 195.627 27.0276V9.6907C195.627 9.10849 195.237 8.72035 194.652 8.72035H187.566C186.981 8.72035 186.591 9.10849 186.591 9.6907V54.0034C186.591 54.5856 187.046 54.9737 187.631 54.9737H194.002C194.587 54.9737 194.977 54.5856 194.977 54.0034L195.107 50.5101C197.317 53.7446 200.697 55.75 205.507 55.75C214.413 55.75 220.588 48.5694 220.588 39.1247C220.588 29.7446 214.413 22.4993 205.507 22.4993ZM203.622 47.599C198.812 47.599 195.497 44.2352 195.497 39.1247C195.497 34.0141 198.812 30.6503 203.622 30.6503C208.302 30.6503 211.812 34.0788 211.812 39.1247C211.812 44.2352 208.302 47.599 203.622 47.599Z"
      fill="white"
    />
    <path
      d="M227.685 54.9737H234.771C235.356 54.9737 235.746 54.5856 235.746 54.0034V9.6907C235.746 9.10849 235.356 8.72035 234.771 8.72035H227.685C227.1 8.72035 226.71 9.10849 226.71 9.6907V54.0034C226.71 54.5856 227.1 54.9737 227.685 54.9737Z"
      fill="white"
    />
    <path
      d="M273.5 37.6368C273.5 29.6152 267.91 22.4993 258.224 22.4993C248.409 22.4993 241.844 29.6799 241.844 38.9953C241.844 48.5694 248.864 55.75 259.329 55.75C263.815 55.75 267.91 54.4562 270.64 51.7392C271.225 51.2217 271.29 50.7689 270.965 50.316L268.56 47.0168C268.235 46.564 267.91 46.4993 267.455 46.7581C264.855 48.246 262.515 48.6988 260.044 48.6988C254.844 48.6988 251.659 46.4993 250.684 42.4885H269.99C272.785 42.4885 273.5 40.7419 273.5 37.6368ZM258.289 29.874C262.125 29.874 264.985 31.9441 265.505 36.2136H250.554C251.269 32.0088 254.324 29.874 258.289 29.874Z"
      fill="white"
    />
    <path
      d="M286.187 55.3043H288.087C288.385 55.3043 288.646 55.1558 288.758 54.8587L293.973 40.0402L299.151 54.8587C299.263 55.1558 299.523 55.3043 299.821 55.3043H301.721C302.019 55.3043 302.28 55.1558 302.392 54.8587L308.389 37.7376C308.538 37.3291 308.389 37.1063 307.979 37.1063H306.266C305.968 37.1063 305.819 37.2548 305.707 37.5519L300.715 52.3704L295.575 37.5519C295.463 37.2548 295.202 37.1063 294.904 37.1063H293.042C292.744 37.1063 292.483 37.2548 292.371 37.5519L287.23 52.3704L282.239 37.5519C282.127 37.2548 281.941 37.1063 281.643 37.1063H279.966C279.556 37.1063 279.407 37.3291 279.556 37.7376L285.517 54.8587C285.628 55.1558 285.889 55.3043 286.187 55.3043Z"
      fill="white"
    />
    <path
      d="M328.466 45.2768C328.466 40.8202 325.337 36.6606 319.936 36.6606C314.497 36.6606 310.772 40.783 310.772 46.131C310.772 51.4419 314.46 55.75 320.42 55.75C322.953 55.75 325.3 54.9701 326.939 53.4102C327.2 53.1503 327.349 52.9646 327.088 52.6303L326.455 51.7761C326.306 51.5904 326.082 51.5904 325.896 51.739C324.48 52.816 322.879 53.4845 320.644 53.4845C316.211 53.4845 313.715 50.7734 313.379 47.1709H327.125C328.206 47.1709 328.466 46.6138 328.466 45.2768ZM319.899 39.0375C323.586 39.0375 325.971 41.5258 326.082 45.0911H313.379C313.715 41.4144 316.285 39.0375 319.899 39.0375Z"
      fill="white"
    />
    <path
      d="M350.413 37.1063H348.811C348.476 37.1063 348.327 37.292 348.327 37.6262L348.29 40.7087C346.949 38.2204 344.527 36.6606 341.249 36.6606C335.922 36.6606 332.272 40.783 332.272 46.2053C332.272 51.6647 335.922 55.75 341.249 55.75C344.527 55.75 346.949 54.2273 348.29 51.7761L348.327 54.7844C348.327 55.1186 348.476 55.3043 348.811 55.3043H350.413C350.748 55.3043 350.897 55.1186 350.897 54.7844V37.6262C350.897 37.292 350.748 37.1063 350.413 37.1063ZM341.622 53.2617C337.71 53.2617 334.879 50.4391 334.879 46.2053C334.879 42.0086 337.71 39.1489 341.622 39.1489C345.533 39.1489 348.253 42.0457 348.253 46.2796C348.253 50.4763 345.533 53.2617 341.622 53.2617Z"
      fill="white"
    />
    <path
      d="M364.084 39.3346C364.419 39.3346 364.605 39.1489 364.605 38.8146V37.6262C364.605 37.292 364.419 37.1063 364.084 37.1063H360.992V32.7981C360.992 32.4639 360.843 32.2782 360.507 32.2782H358.794C358.459 32.2782 358.31 32.4639 358.31 32.7981V37.1063H355.925C355.59 37.1063 355.404 37.292 355.404 37.6262V38.8146C355.404 39.1489 355.59 39.3346 355.925 39.3346H358.31V51.8133C358.31 54.4873 359.651 55.75 362.184 55.75C363.152 55.75 364.531 55.5643 364.531 54.9329V53.8188C364.531 53.4474 364.382 53.2617 363.823 53.336C363.488 53.3731 362.966 53.4102 362.668 53.4102C361.55 53.4102 360.992 52.8903 360.992 51.479V39.3346H364.084Z"
      fill="white"
    />
    <path
      d="M377.787 36.6606C374.77 36.6606 372.87 38.1461 371.827 40.0031V29.2699C371.827 28.9357 371.678 28.75 371.343 28.75H369.629C369.294 28.75 369.145 28.9357 369.145 29.2699V54.7844C369.145 55.1186 369.294 55.3043 369.629 55.3043H371.343C371.678 55.3043 371.827 55.1186 371.827 54.7844V44.3483C371.827 41.1173 374.099 39.1489 377.117 39.1489C380.134 39.1489 381.848 41.1173 381.848 44.3483V54.7844C381.848 55.1186 381.959 55.3043 382.295 55.3043H384.046C384.381 55.3043 384.53 55.1186 384.53 54.7844V44.0884C384.53 39.2603 381.773 36.6606 377.787 36.6606Z"
      fill="white"
    />
    <path
      d="M406.935 45.2768C406.935 40.8202 403.806 36.6606 398.404 36.6606C392.966 36.6606 389.24 40.783 389.24 46.131C389.24 51.4419 392.928 55.75 398.889 55.75C401.422 55.75 403.768 54.9701 405.408 53.4102C405.668 53.1503 405.817 52.9646 405.557 52.6303L404.923 51.7761C404.774 51.5904 404.551 51.5904 404.365 51.739C402.949 52.816 401.347 53.4845 399.112 53.4845C394.679 53.4845 392.183 50.7734 391.848 47.1709H405.594C406.674 47.1709 406.935 46.6138 406.935 45.2768ZM398.367 39.0375C402.055 39.0375 404.439 41.5258 404.551 45.0911H391.848C392.183 41.4144 394.754 39.0375 398.367 39.0375Z"
      fill="white"
    />
    <path
      d="M420.202 37.032C419.867 36.8091 419.271 36.6606 418.526 36.6606C416.253 36.6606 415.024 38.0347 414.316 39.7431L414.242 37.6633C414.242 37.2548 414.093 37.1063 413.72 37.1063H412.156C411.82 37.1063 411.671 37.292 411.671 37.6262V54.7844C411.671 55.1186 411.82 55.3043 412.156 55.3043H413.869C414.204 55.3043 414.353 55.1186 414.353 54.7844V44.0884C414.353 42.0457 415.248 39.1118 418.526 39.1118C418.898 39.1118 419.345 39.1489 419.829 39.2975C420.239 39.4089 420.5 39.3346 420.5 38.9261V37.6633C420.5 37.3291 420.425 37.1805 420.202 37.032Z"
      fill="white"
    />
  </svg>
);

export default SvgDarkSensibleLogoLong;
