import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgEditIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g id="Layer_78" data-name="Layer 78">
      <path d="M54,55.75H17.4l5.26-1.17a1.2,1.2,0,0,0,.61-.34l31.9-31.89A9.56,9.56,0,0,0,41.65,8.83L29.07,21.42h0L9.76,40.73a1.2,1.2,0,0,0-.34.61L6.14,56.1a1.23,1.23,0,0,0-.39.9A1.25,1.25,0,0,0,7,58.25H54a1.25,1.25,0,0,0,0-2.5ZM45.36,28.59c0-.14,0-.27,0-.41a9.53,9.53,0,0,0-10-9.53,7.05,7.05,0,0,1,10,9.94Zm-1.94-18a7.06,7.06,0,0,1,10,10l-3.85,3.85c0-.15,0-.3,0-.45a9.54,9.54,0,0,0-10-9.53ZM11.78,42.24l19-19.06a7.06,7.06,0,0,1,10,10h0l-19,19L8.93,55.07Z" />
    </g>
  </svg>
);

export default SvgEditIcon;
