import React from 'react';
import { CheckboxInputProps } from './CheckboxInput.types';
import BaseCheckboxInput from '../base/BaseCheckboxInput/BaseCheckboxInput';
import Typography from '../Typography/Typography';
import styles from './checkbox-input.module.scss';

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  checkboxList,
  label,
  error,
  selectedValues,
  changeHandler,
}) => {
  const onChangeHandler = (e: React.MouseEvent): void => {
    const { name } = e.currentTarget as HTMLInputElement;

    if (!name) return;

    changeHandler(name);
  };

  const renderCheckboxes = checkboxList.map((cb) => {
    const checked = selectedValues.includes(cb.value);

    return (
      <BaseCheckboxInput
        {...cb}
        key={cb.value}
        checked={checked}
        onChangeHandler={onChangeHandler}
      />
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.labelWrapper}>
        <Typography variant="label-3" align="left">
          {label}
        </Typography>
        {error ? <Typography variant="error">{error}</Typography> : null}
      </div>
      <div className={styles.checkboxWrapper}>{renderCheckboxes}</div>
    </div>
  );
};
export default CheckboxInput;
