import { err, auth } from '../utils';
import { type User, isUser } from '../utils/types/Entity';
import axios, { AxiosResponse } from 'axios';

const URL = '/api/user';

const createUser = async (
  token: string,
  body: {
    name: string;
    phone: string;
    langLocale: string;
  },
): Promise<void> => {
  try {
    const res: AxiosResponse<void> = await axios.post(URL, body, {
      headers: auth.bearerAuthHeader(token),
    });

    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }

    return;
  } catch (e) {
    throw err.handleError(e, 'error creating user');
  }
};

const getUser = async (token: string): Promise<User | null> => {
  try {
    const res: AxiosResponse<{ user: User }> = await axios.get(URL, {
      headers: auth.bearerAuthHeader(token),
    });

    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }

    const { user } = res.data;

    // TODO: #1222 logic should respond to 404 instead of null when proxy is updated (#1221)
    if (user === null) {
      return null;
    }

    isUser(user);

    return user;
  } catch (e) {
    throw err.handleError(e, 'error getting user');
  }
};

const updateUser = async (
  token: string,
  updatedUser: { name?: string; phone?: string; langLocale?: string },
): Promise<User> => {
  try {
    const res: AxiosResponse<{ user: User }> = await axios.patch(
      URL,
      updatedUser,
      {
        headers: auth.bearerAuthHeader(token),
      },
    );

    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }

    const { user } = res.data;

    isUser(user);

    return user;
  } catch (e) {
    //TODO: #1272 #1267 and replace mock with actual Protect return
    // return User mock object
    throw err.handleError(e, 'error updating user details');
  }
};

export default {
  createUser,
  getUser,
  updateUser,
};
