import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgClock = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M14.3088 26.33C7.53152 26.33 2.0061 20.8046 2.0061 14.0273C2.0061 7.25003 7.53152 1.72461 14.3088 1.72461C21.0861 1.72461 26.6115 7.25003 26.6115 14.0273C26.6115 20.8046 21.0861 26.33 14.3088 26.33ZM14.3088 2.58796C8.00636 2.58796 2.86945 7.72487 2.86945 14.0273C2.86945 20.3297 8.00636 25.4666 14.3088 25.4666C20.6112 25.4666 25.7481 20.3297 25.7481 14.0273C25.7481 7.72487 20.6112 2.58796 14.3088 2.58796Z"
      fill="black"
    />
    <path
      d="M2.04932 5.696C1.91981 5.696 1.83348 5.65284 1.74714 5.5665L1.61764 5.437C0.495291 4.31465 0.495291 2.50162 1.61764 1.37927L1.70398 1.29294C2.82633 0.170584 4.63936 0.170584 5.76171 1.29294L5.89121 1.42244C6.06388 1.59511 6.06388 1.85411 5.89121 2.02678L2.35149 5.5665C2.26515 5.65284 2.13565 5.696 2.04932 5.696ZM3.68967 1.3361C3.17167 1.3361 2.65366 1.50877 2.26515 1.89728L2.17882 1.98361C1.44497 2.71746 1.40181 3.88298 2.00615 4.65999L4.94153 1.76778C4.55302 1.4656 4.12135 1.3361 3.68967 1.3361Z"
      fill="black"
    />
    <path
      d="M26.5676 5.696C26.4381 5.696 26.3518 5.65284 26.2654 5.5665L22.7257 2.02678C22.553 1.85411 22.553 1.59511 22.7257 1.42244L22.8552 1.29294C23.9775 0.170584 25.7906 0.170584 26.9129 1.29294L26.9993 1.37927C28.1216 2.50162 28.1216 4.31465 26.9993 5.437L26.8698 5.5665C26.8266 5.65284 26.6971 5.696 26.5676 5.696ZM23.6754 1.76778L26.5676 4.65999C27.1719 3.88298 27.1288 2.71746 26.3949 1.98361L26.3086 1.89728C25.6179 1.16343 24.4524 1.12027 23.6754 1.76778Z"
      fill="black"
    />
    <path
      d="M5.89087 6.04105C5.76137 6.04105 5.67503 5.99788 5.5887 5.91154L3.4735 3.79634C3.30083 3.62368 3.30083 3.36467 3.4735 3.192C3.64617 3.01933 3.90517 3.01933 4.07784 3.192L6.19304 5.3072C6.36571 5.47987 6.36571 5.73888 6.19304 5.91154C6.1067 5.99788 6.02037 6.04105 5.89087 6.04105Z"
      fill="black"
    />
    <path
      d="M22.7264 6.04105C22.5968 6.04105 22.5105 5.99788 22.4242 5.91154C22.2515 5.73888 22.2515 5.47987 22.4242 5.3072L24.5394 3.192C24.712 3.01933 24.9711 3.01933 25.1437 3.192C25.3164 3.36467 25.3164 3.62368 25.1437 3.79634L23.0285 5.91154C22.9422 5.99788 22.8127 6.04105 22.7264 6.04105Z"
      fill="black"
    />
    <path
      d="M14.3082 16.2292C13.0995 16.2292 12.1067 15.2364 12.1067 14.0277C12.1067 12.819 13.0995 11.8262 14.3082 11.8262C15.5169 11.8262 16.5098 12.819 16.5098 14.0277C16.5098 15.2364 15.5169 16.2292 14.3082 16.2292ZM14.3082 12.6895C13.5744 12.6895 12.97 13.2939 12.97 14.0277C12.97 14.7616 13.5744 15.3659 14.3082 15.3659C15.0421 15.3659 15.6464 14.7616 15.6464 14.0277C15.6464 13.2939 15.0421 12.6895 14.3082 12.6895Z"
      fill="black"
    />
    <path
      d="M14.3081 12.6893C14.0491 12.6893 13.8765 12.5166 13.8765 12.2576V7.42288C13.8765 7.16388 14.0491 6.99121 14.3081 6.99121C14.5671 6.99121 14.7398 7.16388 14.7398 7.42288V12.2576C14.7398 12.5166 14.5671 12.6893 14.3081 12.6893Z"
      fill="black"
    />
    <path
      d="M19.5747 14.4591H16.0349C15.7759 14.4591 15.6033 14.2864 15.6033 14.0274C15.6033 13.7684 15.7759 13.5957 16.0349 13.5957H19.5747C19.8337 13.5957 20.0063 13.7684 20.0063 14.0274C20.0063 14.2864 19.8337 14.4591 19.5747 14.4591Z"
      fill="black"
    />
    <path
      d="M14.3081 5.22083C14.0491 5.22083 13.8765 5.04817 13.8765 4.78916V3.92581C13.8765 3.66681 14.0491 3.49414 14.3081 3.49414C14.5671 3.49414 14.7398 3.66681 14.7398 3.92581V4.78916C14.7398 5.005 14.5671 5.22083 14.3081 5.22083Z"
      fill="black"
    />
    <path
      d="M20.8272 7.94035C20.6977 7.94035 20.6113 7.89718 20.525 7.81084C20.3523 7.63817 20.3523 7.37917 20.525 7.2065L21.1294 6.60216C21.302 6.42949 21.561 6.42949 21.7337 6.60216C21.9064 6.77483 21.9064 7.03383 21.7337 7.2065L21.1725 7.81084C21.0862 7.89718 20.9567 7.94035 20.8272 7.94035Z"
      fill="black"
    />
    <path
      d="M24.4525 14.4591H23.5891C23.3301 14.4591 23.1575 14.2864 23.1575 14.0274C23.1575 13.7684 23.3301 13.5957 23.5891 13.5957H24.4525C24.7115 13.5957 24.8842 13.7684 24.8842 14.0274C24.8842 14.2864 24.6683 14.4591 24.4525 14.4591Z"
      fill="black"
    />
    <path
      d="M21.4738 21.6249C21.3443 21.6249 21.2579 21.5817 21.1716 21.4954L20.5672 20.8911C20.3946 20.7184 20.3946 20.4594 20.5672 20.2867C20.7399 20.1141 20.9989 20.1141 21.1716 20.2867L21.7759 20.8911C21.9486 21.0637 21.9486 21.3227 21.7759 21.4954C21.6896 21.5817 21.5601 21.6249 21.4738 21.6249Z"
      fill="black"
    />
    <path
      d="M14.3081 24.6036C14.0491 24.6036 13.8765 24.431 13.8765 24.172V23.3086C13.8765 23.0496 14.0491 22.877 14.3081 22.877C14.5671 22.877 14.7398 23.0496 14.7398 23.3086V24.172C14.7398 24.3878 14.5671 24.6036 14.3081 24.6036Z"
      fill="black"
    />
    <path
      d="M7.14285 21.6249C7.01335 21.6249 6.92702 21.5817 6.84068 21.4954C6.66801 21.3227 6.66801 21.0637 6.84068 20.8911L7.44503 20.2867C7.6177 20.1141 7.8767 20.1141 8.04937 20.2867C8.22204 20.4594 8.22204 20.7184 8.04937 20.8911L7.44503 21.4954C7.35869 21.5817 7.27236 21.6249 7.14285 21.6249Z"
      fill="black"
    />
    <path
      d="M5.0709 14.4591H4.20755C3.94855 14.4591 3.77588 14.2864 3.77588 14.0274C3.77588 13.7684 3.94855 13.5957 4.20755 13.5957H5.0709C5.3299 13.5957 5.50257 13.7684 5.50257 14.0274C5.50257 14.2864 5.28674 14.4591 5.0709 14.4591Z"
      fill="black"
    />
    <path
      d="M7.78919 7.94035C7.65969 7.94035 7.57335 7.89718 7.48702 7.81084L6.88268 7.2065C6.71001 7.03383 6.71001 6.77483 6.88268 6.60216C7.05535 6.42949 7.31435 6.42949 7.48702 6.60216L8.09136 7.2065C8.26403 7.37917 8.26403 7.63817 8.09136 7.81084C8.00503 7.89718 7.87552 7.94035 7.78919 7.94035Z"
      fill="black"
    />
    <path
      d="M6.83963 28.0998H3.73158C3.55891 28.0998 3.42941 28.0135 3.34307 27.884C3.25674 27.7545 3.25674 27.5818 3.34307 27.4523L6.14895 22.7902C6.27845 22.5744 6.53745 22.5312 6.75329 22.6607C6.96913 22.7902 7.01229 23.0492 6.88279 23.2651L4.50859 27.2365H6.62379L8.73899 24.4306C8.86849 24.2579 9.17066 24.2148 9.34333 24.3443C9.516 24.4738 9.55917 24.7759 9.42967 24.9486L7.18496 27.9272C7.09863 28.0567 6.96913 28.0998 6.83963 28.0998Z"
      fill="black"
    />
    <path
      d="M24.8838 28.1001H21.8189C21.6894 28.1001 21.5599 28.0569 21.4736 27.9274L19.2289 24.9489C19.0994 24.7762 19.1425 24.474 19.3152 24.3445C19.4879 24.215 19.79 24.2582 19.9195 24.4308L22.0347 27.2367H24.1499L21.7326 23.2653C21.6031 23.0495 21.6894 22.7905 21.8621 22.661C22.0779 22.5315 22.3369 22.6178 22.4664 22.7905L25.2723 27.4526C25.3586 27.5821 25.3586 27.7547 25.2723 27.8842C25.186 28.0137 25.0133 28.1001 24.8838 28.1001Z"
      fill="black"
    />
  </svg>
);

export default SvgClock;
