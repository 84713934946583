import Typography from '../Typography/Typography';
import { UserAuthButtonProps } from './UserAuthButton.types';
import { SignOut } from '../assets/svg-tsx/sensible';
import styles from './user-auth-button.module.scss';

const UserAuthButton: React.FC<UserAuthButtonProps> = ({
  signInOnClick,
  signOutOnClick,
  isSignedIn,
  signInLabel = 'Sign in',
  signOutLabel = 'Sign out',
  isDisabled = false,
}): JSX.Element => {
  return (
    <button
      className={styles.button}
      disabled={isDisabled}
      onClick={isSignedIn ? signOutOnClick : signInOnClick}
    >
      {isSignedIn && <SignOut />}
      <Typography variant="body-2">
        {isSignedIn ? signOutLabel : signInLabel}
      </Typography>
    </button>
  );
};

export default UserAuthButton;
