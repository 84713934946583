import { FC } from 'react';
import Typography from '../../sharedComponents/Typography/Typography';
import { ButtonProps } from './Button.types';
import styles from './button.module.scss';

// TODO: #2205 delete this component and all of its dependecies before merging
const Button: FC<ButtonProps> = ({
  clickHandling,
  colorVariant = 'ocean',
  disabled = false,
  label,
  loading = false,
  errorMsg,
  formID,
}) => {
  const isSubmit = clickHandling === 'submit';
  const isURL = typeof clickHandling === 'object' && clickHandling.url;
  const isClickHandler = typeof clickHandling === 'function';

  const child = isURL ? (
    <a
      href={clickHandling.url}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore next
      target={clickHandling.newTab ? '_blank' : null}
      rel="noopener noreferrer"
    >
      {label}
    </a>
  ) : (
    label
  );

  const renderChildren = (): JSX.Element => (
    <>
      {child}

      {loading && (
        <div className={styles.loader}>
          <span className={styles.loaderDot} />
          <span className={styles.loaderDot} />
          <span className={styles.loaderDot} />
        </div>
      )}
    </>
  );

  return (
    <div className={styles.container}>
      <button
        className={`${styles.button} ${styles[colorVariant]}`}
        disabled={disabled || loading}
        onClick={isClickHandler ? clickHandling : undefined}
        type={isSubmit ? 'submit' : 'button'}
        form={formID}
      >
        {renderChildren()}
      </button>
      <div className={styles.errorWrapper}>
        <Typography variant="error" align="center">
          {errorMsg}
        </Typography>
      </div>
    </div>
  );
};

export default Button;
