import { useTranslation } from 'react-i18next';
import TextInput from '../../sharedComponents/TextInput/TextInput';
import Typography from '../../sharedComponents/Typography/Typography';
import { validators } from '../../utils';
import { useEffect, useState } from 'react';
import { PAYPAL_ACCOUNT_TYPES } from '../../containers/AddPayPalAccountContainer/AddPayPalAccountContainer.types';
import { AddPayoutAccountPayPalFormProps } from './AddPayoutAccountPayPalForm.types';
import styles from './add-payout-account-paypal-form.module.scss';

const AddPayoutAccountPayPalForm: React.FC<AddPayoutAccountPayPalFormProps> = ({
  accountType,
  inputAccountID,
  setAccountType,
  setInputAccountID,
  onSubmit,
  formID,
  setFormReady,
}) => {
  const { t } = useTranslation();
  const { PHONE, EMAIL } = PAYPAL_ACCOUNT_TYPES;
  const [inputError, setInputError] = useState('');

  const validatePhone = (value: string): boolean => {
    let valid = validators.phoneValidator(value);

    if (!valid) {
      valid = validators.intlPhoneValidator(value);
    }

    return valid;
  };

  const INPUT_VALIDATORS = {
    [PHONE]: validatePhone,
    [EMAIL]: validators.emailValidator,
  };

  useEffect(() => {
    const inputIsValid = !inputError;
    const inputIsComplete = !!inputAccountID.length;

    const ready = inputIsValid && inputIsComplete;

    setFormReady(ready);
  }, [inputError, inputAccountID, setFormReady]);

  const onFocus = (): void => {
    setInputError('');
  };

  const renderInputError = (): string => {
    switch (accountType) {
      case PHONE:
        return t('error.phone_number_invalid');
      case EMAIL:
        return t('error.email_invalid');
      default:
        return t('error.smth_went_wrong');
    }
  };
  const onBlur = (trimmedValue: string): void => {
    const isValid = INPUT_VALIDATORS[accountType](trimmedValue);

    if (trimmedValue.length && !isValid) {
      setInputError(renderInputError);
    } else {
      setInputError('');
    }
  };

  const clickPhone = (): void => {
    if (accountType === PHONE) {
      return;
    }

    setInputAccountID('');
    setAccountType(PHONE);
  };

  const clickEmail = (): void => {
    if (accountType === EMAIL) {
      return;
    }

    setInputAccountID('');
    setAccountType(EMAIL);
  };

  return (
    <div className={styles.container}>
      <div className={styles.accountTypeButtonWrapper}>
        <button
          className={styles[accountType === PHONE ? 'active' : 'inactive']}
          onClick={clickPhone}
        >
          <Typography variant="label-1">{t('label.phone')}</Typography>
        </button>
        <button
          className={styles[accountType === EMAIL ? 'active' : 'inactive']}
          onClick={clickEmail}
        >
          <Typography variant="label-1">{t('label.email')}</Typography>
        </button>
      </div>

      <form id={formID} onSubmit={onSubmit} className={styles.form}>
        <TextInput
          value={inputAccountID}
          label={accountType === PHONE ? t('label.phone') : t('label.email')}
          onFocus={onFocus}
          onBlur={onBlur}
          handleChange={(value): void => {
            setInputAccountID(value);
          }}
          errorMsg={inputError}
        />
      </form>
    </div>
  );
};

export default AddPayoutAccountPayPalForm;
