import { Glyph } from '@visx/glyph';

type GlyphProps = {
  left: number;
  top: number;
};
const SunGlyph = ({ left, top }: GlyphProps) => {
  return (
    <Glyph left={left} top={top}>
      <svg width="64" height="64" viewBox="-15 0 100 100">
        <path
          d="M1.11667 14.9122C3.3684 13.796 5.84712 13.2138 8.36034 13.2109C10.8736 13.208 13.3536 13.7844 15.6079 14.8955C17.8622 16.0065 19.8301 17.6223 21.3587 19.6172C22.8872 21.6121 23.9354 23.9326 24.4217 26.3983C24.908 28.864 24.8194 31.4087 24.1628 33.8346C23.5062 36.2605 22.2992 38.5024 20.6356 40.3862C18.972 42.27 16.8965 43.745 14.5704 44.6966C12.2443 45.6482 9.73017 46.0508 7.22323 45.8731"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.3208 1V6.03125"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.9927 28.6719H30.9614"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.8872 48.2359L24.3301 44.6788"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.8847 9.10785L24.3276 12.6649"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.3208 56.3437V51.3125"
          stroke="#0B1419"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Glyph>
  );
};

export default SunGlyph;
