import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgContentCopy = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    fill="#000000"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      style={{
        fontSize: 'medium',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: 'normal',
        fontStretch: 'normal',
        textIndent: 0,
        textAlign: 'start',
        textDecoration: 'none',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        wordSpacing: 'normal',
        textTransform: 'none',
        direction: 'ltr',
        textAnchor: 'start',
        baselineShift: 'baseline',
        opacity: 1,
        color: '#000000',
        fill: '#000000',
        fillOpacity: 1,
        stroke: 'none',
        strokeWidth: 3.99999881,
        marker: 'none',
        visibility: 'visible',
        display: 'inline',
        overflow: 'visible',
        fontFamily: 'Sans',
      }}
      d="M 36 6 C 32.710595 6 30 8.7105946 30 12 L 30 22 L 24 22 C 20.710595 22 18 24.710595 18 28 L 18 88 C 18 91.289405 20.710595 94 24 94 L 64 94 C 67.289405 94 70 91.289405 70 88 L 70 78 L 76 78 C 79.289405 78 82 75.289405 82 72 L 82 26 A 2.0001994 2.0001994 0 0 0 81.40625 24.59375 L 63.40625 6.59375 A 2.0001994 2.0001994 0 0 0 62 6 L 44 6 A 2.0001994 2.0001994 0 1 0 44 10 L 60 10 L 60 26 A 2.0001994 2.0001994 0 0 0 62 28 L 78 28 L 78 72 C 78 73.142595 77.142595 74 76 74 L 36 74 C 34.857405 74 34 73.142595 34 72 L 34 12 C 34 10.857405 34.857405 10 36 10 A 2.0001994 2.0001994 0 1 0 36 6 z M 64 12.8125 L 75.1875 24 L 64 24 L 64 12.8125 z M 24 26 L 30 26 L 30 72 C 30 75.289405 32.710595 78 36 78 L 66 78 L 66 88 C 66 89.142595 65.142595 90 64 90 L 24 90 C 22.857405 90 22 89.142595 22 88 L 22 28 C 22 26.857405 22.857405 26 24 26 z "
    />
  </svg>
);

export default SvgContentCopy;
