import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import { i18nformatDateRangeLong } from '../../utils/i18n';
import { CoverageDetailsProps } from './CoverageDetails.types';

const CoverageDetails: React.FC<CoverageDetailsProps> = ({
  location,
  start,
  end,
  coverageStartHour,
  coverageEndHour,
  tz,
}) => {
  const { t, i18n } = useTranslation();

  const startHour = t('formatter.date', {
    val: new Date(coverageStartHour),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: tz,
      },
    },
  });
  const endHour = t('formatter.date', {
    val: new Date(coverageEndHour),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: tz,
        timeZoneName: i18n.language.includes('en') ? 'short' : 'longGeneric',
      },
    },
  });
  return (
    <div>
      <Typography variant="body-1">{location}</Typography>
      <Typography variant="body-1">
        {i18nformatDateRangeLong(start, end)}
      </Typography>
      <Typography variant="body-1">
        {startHour} - {endHour}, {t('sunshine.label.every_day')}
      </Typography>
    </div>
  );
};

export default CoverageDetails;
