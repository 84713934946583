/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import { i18nformatDateRangeLong } from '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import { BlankCanvasBlock } from '../../sharedComponents/BlankCanvasBlock/BlankCanvasBlock';
import Typography from '../../sharedComponents/Typography/Typography';
import { entityHelpers } from '../../utils';
import { PolicyStatus, getPolicyStatus } from '../../utils/types/Entity';
import { Link } from 'react-router-dom';
import { PolicyListContainer } from '../../containers';
import IsWeatherGuaranteedFooterComponent from '../IsWeatherGuaranteedFooterComponent/IsWeatherGuaranteedFooterComponent';
import { ClaimStatusWrapperComponentProps } from './ClaimStatusWrapperComponent.types';
import styles from './claim-status-wrapper-component.module.scss';

const ClaimStatusWrapperComponent: React.FC<
  ClaimStatusWrapperComponentProps
> = ({ children, childrenReady = true, policyBundle }): JSX.Element => {
  const { t } = useTranslation();

  const [coverageHasNotStarted, setCoverageHasNotStarted] = useState(false);

  useEffect(() => {
    const _coverageHasNotStarted = policyBundle.policies.every((p) => {
      return getPolicyStatus(p) === PolicyStatus.Upcoming;
    });

    setCoverageHasNotStarted(_coverageHasNotStarted);
  }, [policyBundle]);

  const locationName =
    entityHelpers.getLocationNameFromPolicyBundle(policyBundle);

  const backLink = (
    <Link to="/trips">
      <Typography variant="label-3" color="ocean">
        {'<'} {t('sunshine.action.back_to_trips')}
      </Typography>
    </Link>
  );

  const renderReimbursementContainer = children !== undefined;

  return (
    <>
      {renderReimbursementContainer && (
        <BlankCanvasBlock colorVariant="sand" first>
          <div
            className={
              childrenReady ? styles.expandAnimation : styles.collapseAnimation
            }
          >
            {backLink}
            <Typography variant="h5" className={styles.header}>
              {t('sunshine.content.reimbursement_balance.header')}
            </Typography>

            {children}
          </div>
        </BlankCanvasBlock>
      )}

      <BlankCanvasBlock
        colorVariant="snow"
        first={!renderReimbursementContainer}
      >
        {!renderReimbursementContainer && backLink}

        <Typography variant="h4" className={styles.headerText}>
          {t('sunshine.content.reimbursement_details.header')}
        </Typography>

        {coverageHasNotStarted && (
          <Typography
            variant="body-1"
            className={styles.coverageHasNotStartedText}
          >
            {t('sunshine.content.reimbursement_details.explainer')}
          </Typography>
        )}

        <div className={styles.locationDatesWrapper}>
          <Typography variant="body-2">{locationName}</Typography>
          <Typography variant="body-2">
            {i18nformatDateRangeLong(
              policyBundle.coverageStartDate,
              policyBundle.coverageEndDate,
            )}
          </Typography>
        </div>

        <PolicyListContainer bundle={policyBundle} />
      </BlankCanvasBlock>

      <BlankCanvasBlock colorVariant="snow" last>
        <IsWeatherGuaranteedFooterComponent bundle={policyBundle} />
      </BlankCanvasBlock>
    </>
  );
};

export default ClaimStatusWrapperComponent;
