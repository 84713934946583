import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../../sharedComponents/CheckboxInput/CheckboxInput';
import TextButton from '../../sharedComponents/TextButton/TextButton';
import TextInput from '../../sharedComponents/TextInput/TextInput';
import { assets } from '../../sharedComponents/assets';
import Typography from '../../sharedComponents/Typography/Typography';
import { validators } from '../../utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useActions } from '../../store/hooks';
import { PageProps } from '../Page.types';
import Button from '../../components/Button/Button';
import { AuthAPI, UserAPI } from '../../api';
import styles from './welcome.module.scss';
import Footer from '../../components/Footer/Footer';

interface formInput {
  value: string;
  isValid: boolean;
  error: string;
}

const Welcome: React.FunctionComponent<PageProps> = ({
  layout: Layout,
  navigation: Navigation,
}) => {
  const { t } = useTranslation();
  const [renderCreateUser, setRenderCreateUser] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const navigate = useNavigate();
  const dispatch = useActions();
  const user = useAppSelector((state) => state.user.user);

  const browserLanguageDetector = (): string => {
    const lang = navigator.language;
    if (lang.includes('fr')) {
      return 'fr-FR';
    } else {
      return 'en-US';
    }
  };

  const { user: authUser, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (processingRequest) return;

    if (!user.id) {
      setRenderCreateUser(true);
    }
  }, [user, navigate, authUser, processingRequest]);

  const NAME_KEY = 'name';
  const PHONE_KEY = 'phone';
  const TAP_KEY = 'accepted_tap';

  type textInputsKey = typeof NAME_KEY | typeof PHONE_KEY;

  const [formError, setFormError] = useState('');

  const [inputs, setInputs] = useState<{
    [NAME_KEY]: formInput;
    [PHONE_KEY]: formInput;
    [TAP_KEY]: boolean;
  }>({
    [NAME_KEY]: {
      value: '',
      isValid: false,
      error: '',
    },
    [PHONE_KEY]: {
      value: '',
      isValid: false,
      error: '',
    },
    [TAP_KEY]: false,
  });

  const handleInputsChange = (value: string, key: textInputsKey): void => {
    const updatedInputs = { ...inputs };
    updatedInputs[key].value = value;

    setInputs({ ...updatedInputs });
  };

  const handleCheckTapAcceptance = (): void => {
    setInputs((prev) => {
      return { ...prev, [TAP_KEY]: !prev[TAP_KEY] };
    });
  };

  const handleOnFocus = (key: textInputsKey): void => {
    const updatedInputs = { ...inputs };
    updatedInputs[key].error = '';
    updatedInputs[key].isValid = false;

    setInputs({ ...updatedInputs });
  };

  const handleOnNameInputBlur = (): void => {
    if (inputs.name.value) {
      const updatedInputs = { ...inputs };

      const [firstName, lastName] = inputs.name.value.split(' ');

      if (!firstName || !lastName) {
        updatedInputs.name.error = t('error.first_last_name_required');
      } else {
        updatedInputs.name.isValid = true;
      }

      setInputs({ ...updatedInputs });
    }
  };

  const handleOnPhoneInputBlur = (): void => {
    if (inputs.phone.value) {
      const updatedInputs = { ...inputs };

      const isValid = validators.phoneValidator(inputs.phone.value);

      if (!isValid) {
        updatedInputs.phone.error = t('error.phone_number_invalid');
      } else {
        updatedInputs.phone.isValid = true;
      }

      setInputs({ ...updatedInputs });
    }
  };

  const createUser = async (accessToken: string): Promise<void> => {
    const lang = browserLanguageDetector();

    try {
      await UserAPI.createUser(accessToken, {
        name: inputs.name.value,
        phone: inputs.phone.value,
        langLocale: lang,
      });

      const u = await UserAPI.getUser(accessToken);

      if (!u) {
        throw new Error();
      }

      /**
       * This refreshes the user returned from the useAuth0 hook, and ensures
       * that the `getPolicyBundles` request that the below `dispatch.setUser(u)`
       * will subsequently trigger will be called with an access token which
       * includes the user's protect ID.
       */
      // await getAccessTokenSilently({
      //   cacheMode: 'off',
      // });

      dispatch.setUser(u);
    } catch (e) {
      throw new Error(t('error.adding_to_system'));
    }
  };

  const acceptTermsAndPolicies = async (accessToken: string): Promise<void> => {
    try {
      await AuthAPI.acceptTermsAndPolicies(accessToken);
    } catch (e) {
      throw new Error(t('error.accepting_terms_conditions'));
    }
  };

  const submitForm = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();
    setProcessingRequest(true);

    try {
      const accessToken = await getAccessTokenSilently();

      if (renderCreateUser) {
        await createUser(accessToken);
      }

      await acceptTermsAndPolicies(accessToken);

      /**
       * This refreshes the user returned from the useAuth0 hook. It ensures
       * that the acceptance of t&c and privacy policy is reflect in the logged
       * in user's auth user, enabling them to move on from the Welcome screen.
       */
      await getAccessTokenSilently({
        // cacheMode: 'off',
        ignoreCache: true,
      });
    } catch (e) {
      let msg = t('error.customer_support');

      if (e instanceof Error) {
        msg = `${e.message} ${msg}`;
      }

      setFormError(msg);
      setProcessingRequest(false);
    }
  };

  const CreateUserInputs = (
    <>
      <TextInput
        value={inputs.name.value}
        handleChange={(value): void => handleInputsChange(value, NAME_KEY)}
        onFocus={(): void => handleOnFocus(NAME_KEY)}
        onBlur={handleOnNameInputBlur}
        label={t('label.name')}
        errorMsg={inputs.name.error}
      />
      <TextInput
        value={inputs.phone.value}
        handleChange={(value): void => handleInputsChange(value, PHONE_KEY)}
        onFocus={(): void => handleOnFocus(PHONE_KEY)}
        onBlur={handleOnPhoneInputBlur}
        label={t('label.phone_number')}
        errorMsg={inputs.phone.error}
      />
    </>
  );

  const textInputsValid = renderCreateUser
    ? inputs.name.isValid && inputs.phone.isValid
    : true;

  const buttonDisbaled =
    !inputs[TAP_KEY] || !textInputsValid || processingRequest;

  return (
    <Layout navigation={<Navigation />} footer={<Footer />}>
      <div className={styles.header}>
        <div className={styles.geoSunContainer}>
          <div className={styles.geoSunLayer}>
            {<assets.svg.sensible.GeoSun />}
          </div>
        </div>
        <Typography variant="h7" color="ocean">
          {t('label.welcome_to_sensible')}
        </Typography>
      </div>

      <form onSubmit={(e): void => void submitForm(e)} className={styles.form}>
        {renderCreateUser ? (
          CreateUserInputs
        ) : (
          <Typography variant="body-1" className={styles.formMessage}>
            {t('label.accept_terms_conditions')}
          </Typography>
        )}

        <Typography
          variant="body-4"
          className={styles.termsAndPoliciesText}
          format="none"
        >
          {t('content.terms_conditions.disclaimer')}
        </Typography>

        <div className={styles.links}>
          <TextButton
            label={t('action.terms_and_conditions')}
            clickHandling={{
              url: t('content.url.guarantee_terms_conditions'),
              newTab: true,
            }}
          />
          <TextButton
            label={t('action.privacy_policy')}
            clickHandling={{
              url: t('content.url.privacy_policy'),
              newTab: true,
            }}
          />
        </div>

        <CheckboxInput
          label=""
          error=""
          checkboxList={[
            {
              label: t('action.agree_terms_conditions'),
              value: TAP_KEY,
            },
          ]}
          selectedValues={[inputs[TAP_KEY] ? TAP_KEY : '']}
          changeHandler={handleCheckTapAcceptance}
        />
        <Button
          clickHandling="submit"
          label={t('action.submit')}
          disabled={buttonDisbaled}
          errorMsg=""
        />
      </form>
      <Typography variant="error">{formError}</Typography>
    </Layout>
  );
};

export default Welcome;
