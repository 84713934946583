import { FC } from 'react';
import { BoldTextButtonProps } from './BoldTextButton.types';
import Typography from '../Typography/Typography';
import styles from './bold-text-button.module.scss';

const BoldTextButton: FC<BoldTextButtonProps> = ({
  title,
  colorVariant,
  disabled = false,
  clickHandler,
  loading = false,
  errMsg,
}) => {
  return (
    <div className={styles.container}>
      <button
        className={styles.btn}
        onClick={clickHandler}
        disabled={loading || disabled}
      >
        {/* TODO: #2204 remove format from Typography */}
        <Typography variant="label-1" format="sentence" color={colorVariant}>
          {title}
        </Typography>
      </button>
      <div className={styles.error}>
        <Typography variant="error">{errMsg}</Typography>
      </div>
    </div>
  );
};

export default BoldTextButton;
