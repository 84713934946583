import React from 'react';
import { TripListSectionProps } from './TripListSection.types';
import Typography from '../../sharedComponents/Typography/Typography';
import styles from './trip-list-section.module.scss';

const TripListSection: React.FC<TripListSectionProps> = ({
  header,
  children,
}) => {
  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.header}>
        <Typography variant="h5">{header}</Typography>
      </div>
      {children}
    </div>
  );
};

export default TripListSection;
