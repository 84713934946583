import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useActions, useStore } from './store/hooks';
import routes from './router/RouteConfig';
import RouteElement from './router/RouteElement';
import { UserAPI, PolicyBundleAPI, AuthAPI } from './api';
import { ConfigContext, LocaleContext } from './contexts';
import { analytics } from './analytics';
import i18next from 'i18next';
import './i18next/i18n';

const App: React.FC = () => {
  const [appIsReady, setAppIsReady] = useState(false);
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
  const [appError, setAppError] = useState(false);

  const {
    isAuthenticated,
    isLoading: authIsLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const dispatch = useActions();
  const { config } = useContext(ConfigContext);

  const browserLanguageDetector = (): string => {
    const lang = navigator.language;
    if (lang.includes('fr')) {
      return 'fr-FR';
    } else if (lang.includes('sv')) {
      return 'sv-SE';
    } else {
      return 'en-US';
    }
  };

  useEffect(() => {
    if (config && !analyticsLoaded) {
      const { rudderstackKey } = config;
      analytics.load(
        rudderstackKey,
        'https://sensiblewel.dataplane.rudderstack.com',
      );

      setAnalyticsLoaded(true);
    }
  }, [config, analyticsLoaded]);

  const {
    user: { user },
    policyBundles: { policyBundles },
  } = useStore();

  if (user.id) {
    void i18next.changeLanguage(user.langLocale);
  } else {
    const lang = browserLanguageDetector();
    void i18next.changeLanguage(lang);
  }

  //TODO: #1906 temp solution - replace once localization is in place
  const localeEnvelope = useContext(LocaleContext);

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        const token = await getAccessTokenSilently();

        const _user = await UserAPI.getUser(token);

        /**
         * This refreshes the user returned from the useAuth0 hook. It ensures
         * that, even for users who just signed up, all subsequent calls will
         * use an access token that includes necessary user data, like protect ID.
         */
        // await getAccessTokenSilently({
        //   cacheMode: 'off',
        // });

        if (_user) {
          void AuthAPI.verifyUserEmail(token, _user.langLocale);
          dispatch.setUser(_user);
        } else {
          void AuthAPI.verifyUserEmail(token, browserLanguageDetector());
          setAppIsReady(true);
        }
      } catch (e) {
        setAppError(true);
        setAppIsReady(true);
      }
    };

    if (!authIsLoading && !user.id && isAuthenticated) {
      void getUser();
    }
  }, [authIsLoading, isAuthenticated, user, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (user.id) {
      getAccessTokenSilently()
        .then((token) => PolicyBundleAPI.getPolicyBundles(token))
        .then((_policyBundles) => {
          dispatch.setPolicyBundles(_policyBundles);
        })
        .catch(() => {
          dispatch.setPolicyBundles([]);
        })
        .finally(() => {
          dispatch.setPolicyBundlesReady();
          setAppIsReady(true);
        });
    }
  }, [user.id, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    //TODO: #1906 temp solution - replace once localization is in place
    if (policyBundles.length > 0) {
      const bundleCurrency = policyBundles[0].currency;
      localeEnvelope.actions.updateLocale(bundleCurrency);
    }
  }, [localeEnvelope.actions, policyBundles]);

  // TODO: Handler appError;
  // TODO: #1298 - add loader/spinner
  if (authIsLoading || (isAuthenticated && !appIsReady) || appError) {
    return <></>;
  }

  const renderWildcardRoute = (): React.ReactElement => {
    if (isAuthenticated) {
      return <Route path="*" element={<Navigate to="/trips" />} />;
    }
    void loginWithRedirect();

    return <></>;
  };

  return (
    <Routes>
      <>
        {routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<RouteElement route={route} />}
            />
          );
        })}
        {renderWildcardRoute()}
      </>
    </Routes>
  );
};

export default App;
