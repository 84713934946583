import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '../../sharedComponents/Typography/Typography';
import { i18nformatDateRangeLong } from '../../utils/i18n';
import { useActions } from '../../store/hooks';
import { TripListComponentProps } from './TripListComponent.types';
import styles from './trip-list-component.module.scss';

const TripListComponent: React.FunctionComponent<TripListComponentProps> = ({
  // logoUri,
  location,
  partnerName,
  start,
  end,
  slug,
  id,
}) => {
  const dispatch = useActions();
  const clickHandler = (): void => {
    dispatch.setSelectedPolicyBundle(id);
  };

  const renderPartnerName = (name: string): JSX.Element => {
    return (
      <Typography variant="body-2" truncate>
        {name}
      </Typography>
    );
  };
  return (
    <Link to={`/trips/${slug}`}>
      <button className={styles.container} onClick={clickHandler}>
        {/* TODO: #1184 replace with proper logo render */}
        <div className={styles.header}>{renderPartnerName(partnerName)}</div>
        <div className={styles.detailsPositionWrapper}>
          <Typography variant="body-2" truncate>
            {location}
          </Typography>
          <Typography variant="body-2">
            {i18nformatDateRangeLong(start, end)}
          </Typography>
        </div>
      </button>
    </Link>
  );
};

export default TripListComponent;
