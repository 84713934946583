import { StatusCode } from '../types/StatusCode/StatusCode.types';

const isErrorType = (e: unknown): e is Error => {
  return e instanceof Error;
};

export interface RequestError extends Error {
  status: number;
  statusText: string;
}

const isRequestError = (e: unknown): e is RequestError => {
  const r = e as RequestError;

  // TODO: #1537
  /* istanbul ignore next */
  return !!(r?.status && r?.statusText);
};

type axiosError = {
  config: unknown;
  code?: string;
  isAxiosError: boolean;
  response: {
    data: {
      message: string;
      code: number;
    };
    status: StatusCode;
    statusText: string;
  };
  request: unknown;
  toJSON: () => object;
};

const isErrorFromRequest = (e: unknown): e is axiosError => {
  const a = e as axiosError;

  if (a?.isAxiosError) return true;

  return false;
};

const configureStatus = (
  e: axiosError,
): { status: StatusCode; statusText: string } => {
  const s = {
    status: e.response.status,
    statusText: '',
  };

  switch (e.response.status) {
    case StatusCode.OK: {
      s.statusText = 'OK';
      break;
    }
    case StatusCode.Created: {
      s.statusText = 'Created';
      break;
    }
    case StatusCode.NoContent: {
      s.statusText = 'No Content';
      break;
    }
    case StatusCode.NoResponse: {
      s.statusText = 'No Response';
      break;
    }
    case StatusCode.BadRequest: {
      s.statusText = 'Bad Request';
      break;
    }
    case StatusCode.Unauthorized: {
      s.statusText = 'Unauthorized';
      break;
    }
    case StatusCode.Forbidden: {
      s.statusText = 'Forbidden';
      break;
    }
    case StatusCode.NotFound: {
      s.statusText = 'Not Found';
      break;
    }
    case StatusCode.RequestTimeout: {
      s.statusText = 'Request Timeout';
      break;
    }
    case StatusCode.UnprocessableEntity: {
      s.statusText = 'Unprocessable Entity';
      break;
    }
    case StatusCode.InternalServerError: {
      s.statusText = 'Internal Server Error';
      break;
    }
    case StatusCode.BadGateway: {
      s.statusText = 'Bad Gateway';
      break;
    }
    case StatusCode.ServiceUnavailable: {
      s.statusText = 'Service Unavailable';
      break;
    }
    case StatusCode.GatewayTimeout: {
      s.statusText = 'Gateway Timeout';
      break;
    }
    default: {
      s.status = StatusCode.InternalServerError;
      s.statusText = 'Internal Server Error';
      break;
    }
  }

  return s;
};

const handleRequestError = (
  e: axiosError,
  msg?: string,
): { status: StatusCode; statusText: string; message: string } => {
  let message = '';

  if (e.response?.data.message) {
    if (msg) {
      message = `${e.response.data.message}: ${msg}`;
    } else {
      message = e.response.data.message;
    }
  } else if (msg) {
    message = msg;
  } else {
    message = 'unknown error occurred';
  }

  if (e.response) {
    const c = configureStatus(e);
    return {
      ...c,
      message,
    };
  } else if (e.request) {
    return {
      status: StatusCode.NoResponse,
      statusText: 'No Response',
      message,
    };
  } else {
    return {
      status: StatusCode.InternalServerError,
      statusText: 'Internal Server Error',
      message,
    };
  }
};

const handleError = (e: unknown, msg?: string): Error | RequestError => {
  if (isErrorFromRequest(e)) {
    const r = handleRequestError(e, msg);

    return {
      ...r,
      name: 'Request Error',
    };
  } else if (isErrorType(e) || isRequestError(e)) {
    return e;
  } else if (typeof e === 'string') {
    if (msg) {
      return new Error(`${msg}: ${e}`);
    }

    return new Error(e);
  }

  return new Error(`${msg || 'unknown error'}`);
};

const createErrorBody = (
  msg: string,
  e?: Error | RequestError,
): { message: string } => {
  if (e) {
    if (isRequestError(e)) {
      return { message: `${e.statusText}: ${msg}` };
    }

    return { message: `${e.message}: ${msg}` };
  }

  return { message: msg };
};

const logError = (msg: string, e: unknown): void => {
  const er = handleError(e);

  if (isRequestError(er)) {
    console.error(`request error: ${msg}`);
    console.error(`name: ${er.name}`);
    console.error(`status: ${er.status}`);
    console.error(`statusText: ${er.statusText}`);
    console.error(`message: ${er.message}`);
  } else {
    console.error(`error: ${msg}`);
    console.error(`name: ${er.name}`);
    console.error(`message: ${er.message}`);
  }
};

export default {
  handleError,
  createErrorBody,
  logError,
};
