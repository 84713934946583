import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgErrorMark = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={14.5} cy={14.5} r={14.5} fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 13.8267L19.3267 9L21 10.6894L16.1894 15.5L21 20.3267L19.3267 22L14.5 17.1894L9.68936 22L8 20.3267L12.8267 15.5L8 10.6894L9.68936 9L14.5 13.8267Z"
      fill="#D40025"
    />
  </svg>
);

export default SvgErrorMark;
