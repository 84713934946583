import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { type PayoutAccount } from '../../utils/types/Entity';

interface PayoutAccountState {
  loadedPayoutAccounts: boolean;
  payoutAccounts: PayoutAccount[];
}

const payoutAccountInitialState: PayoutAccountState = {
  loadedPayoutAccounts: false,
  payoutAccounts: [],
};

const payoutAccountSlice = createSlice({
  name: 'payout',
  initialState: payoutAccountInitialState,
  reducers: {
    setLoadedPayoutAccounts: (state: PayoutAccountState) => {
      state.loadedPayoutAccounts = true;
    },
    setPayoutAccounts: (
      state: PayoutAccountState,
      action: PayloadAction<PayoutAccount[]>,
    ) => {
      state.payoutAccounts = action.payload;
    },
    clearPayoutAccounts: (state: PayoutAccountState) => {
      state.payoutAccounts = [];
    },
    addPayoutAccount: (
      state: PayoutAccountState,
      action: PayloadAction<PayoutAccount>,
    ) => {
      let payoutAccounts = [...state.payoutAccounts];

      const newAccount = action.payload;

      /**
       * A user can only have one default payout account. When a new payout account
       * is added, all existing payout accounts should have isDefault set to false
       **/
      if (newAccount.isDefault) {
        payoutAccounts = state.payoutAccounts.map((account) => {
          return { ...account, isDefault: false };
        });
      }

      payoutAccounts.push(newAccount);

      state.payoutAccounts = payoutAccounts;
    },
    updateDefaultAccountStatus: (
      state: PayoutAccountState,
      action: PayloadAction<{
        payoutAccount: PayoutAccount;
        isDefault: boolean;
      }>,
    ) => {
      const { payoutAccount, isDefault } = action.payload;
      state.payoutAccounts = state.payoutAccounts.map((account) => {
        if (account.id === payoutAccount.id) {
          return { ...account, isDefault };
        }
        return { ...account, isDefault: false };
      });
    },
    removePayoutAccount: (
      state: PayoutAccountState,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.payoutAccounts = state.payoutAccounts.filter(
        (account) => account.id !== action.payload.id,
      );
    },
  },
});

export const payoutAccountActions = payoutAccountSlice.actions;

export const payoutAccountReducer = payoutAccountSlice.reducer;
