import { useTranslation } from 'react-i18next';
import IconTextButton from '../../sharedComponents/IconTextButton/IconTextButton';
import { assets } from '../../sharedComponents/assets';
import Typography from '../../sharedComponents/Typography/Typography';
import ViewYourGuaranteeDetails from '../ViewYourGuaranteeDetails/ViewYourGuaranteeDetails';
import { IsWeatherGuaranteedFooterComponentProps } from './IsWeatherGuaranteedFooterComponent.types';
import styles from './is-weather-guaranteed-footer-component.module.scss';

const IsWeatherGuaranteedFooterComponent: React.FC<
  IsWeatherGuaranteedFooterComponentProps
> = ({ bundle }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Typography variant="h5" className={styles.headerText}>
        {t('sunshine.label.trip_weather_guaranteed')}
      </Typography>
      <ViewYourGuaranteeDetails bundle={bundle} />
      <br />
      <IconTextButton
        label={t('sunshine.label.terms_conditions_no_surprises')}
        icon={assets.svg.buttonIcons.Warranty}
        format={'none'}
        href={t('content.url.guarantee_terms_conditions')}
      />
    </div>
  );
};

export default IsWeatherGuaranteedFooterComponent;
