import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import TextButton from '../../sharedComponents/TextButton/TextButton';
import Typography from '../../sharedComponents/Typography/Typography';
import { AddPayoutAccountBaseModuleProps } from './AddPayoutAccountBaseModule.types';
import Button from '../../components/Button/Button';
import styles from './add-payout-account-base-module.module.scss';

const AddPayoutAccountBaseModule: React.FC<AddPayoutAccountBaseModuleProps> = ({
  step,
  provider,
  exit,
  edit,
  save,
  formReady,
  formID,
  processingRequest,
  errorMsg,
  resetErrorMsg,
  children,
  disableSteps,
}): JSX.Element => {
  const { t } = useTranslation();
  const buttonConfiguration = {
    0: {
      label: t('action.next'),
      disabled: !formReady,
      clickHandling: 'submit' as const,
      formID,
    },
    1: {
      label: t('action.save_account'),
      disabled: processingRequest,
      clickHandling: save,
    },
  }[step];

  useEffect(() => {
    if (step === 0) {
      resetErrorMsg();
    }
  }, [step, resetErrorMsg]);

  return (
    <div>
      <Typography variant="body-2" className={styles.header}>
        {step === 0
          ? t('sunshine.label.enter_provider_info', { provider })
          : t('sunshine.label.confirm_provider_info', { provider })}
      </Typography>

      <div className={styles.contentWrapper}>{children}</div>

      <TextButton
        label={`${
          step === 0
            ? t('sunshine.action.add_different_account')
            : t('action.edit')
        }`}
        clickHandling={step === 0 ? exit : edit}
        inline
      />

      <div className={styles.buttonWrapper}>
        {!disableSteps && (
          <Button
            colorVariant="ocean"
            label={buttonConfiguration.label}
            clickHandling={buttonConfiguration.clickHandling}
            errorMsg={errorMsg}
            disabled={buttonConfiguration.disabled}
            formID={formID}
          />
        )}
      </div>

      <Typography
        variant="body-3"
        align="left"
        className={styles.willBeSavedText}
      >
        {t('sunshine.label.account_info_disclaimer')}
      </Typography>
    </div>
  );
};

export default AddPayoutAccountBaseModule;
